import actions from './actions'

export const initialState = {
  image: null,
  loading: false,
  ResendInvoiceModal: false,
  InvoiceActivity: false,
  RetryPaymentModal: false,
  RefundInvoiceModal: false,
  displayInvoiceDrawer: false,
  selectedData: null,
  getpaiddata: null,
  getnotpaiddata: null,
  getrefunddata: null,
  invoiceId: null,
  search: '',
  searchpaidinv: null,
  searchnotpaidinv: null,
  searchrefundinv: null,
  paymentStatusloading: false,
  displayChangePaymentStatus: false,
  transactionid: '',
}
export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_RESEND_INVOICE_MODAL:
      return { ...state, selectedData: action.payload, ResendInvoiceModal: true }
    case actions.HIDE_RESEND_INVOICE_MODAL:
      return { ...state, ResendInvoiceModal: false }
    case actions.SHOW_INVOICE_ACTIVITY_MODAL:
      return { ...state, selectedData: action.payload, InvoiceActivity: true }
    case actions.HIDE_INVOICE_ACTIVITY_MODAL:
      return { ...state, InvoiceActivity: false }
    case actions.SHOW_RETRY_PAYMENT_MODAL:
      return { ...state, selectedData: action.payload, RetryPaymentModal: true }
    case actions.HIDE_RETRY_PAYMENT_MODAL:
      return { ...state, RetryPaymentModal: false }
    case actions.SHOW_REFUND_INVOICE_MODAL:
      return { ...state, selectedData: action.payload, RefundInvoiceModal: true }
    case actions.HIDE_REFUND_INVOICE_MODAL:
      return { ...state, RefundInvoiceModal: false, selectedData: null }
    case actions.HIDE_CHANGE_PAYMENT_STATUS:
      return { ...state, displayChangePaymentStatus: false }
    default:
      return state
  }
}
