import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// import StoreInfoAction from 'redux/storeinfo/actions'
import { Button, Form, Input, Modal, notification } from 'antd'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const ConfirmPassword = ({
  visible,
  title,
  onSubmit,
  onCancel,
  loading,

  intl: { formatMessage },
}) => {
  const [form] = Form.useForm()

  const [passwordInfo, setpasswordInfo] = useState(null)
  const [confirmPasswordInfo, setconfirmPasswordInfo] = useState(null)
  const [passwordOk, setpasswordOk] = useState(true)

  const passwordField = form.getFieldValue('password')
  const confirmPasswordField = form.getFieldValue('confirm_password')

  const handlePasswordChange = values => {
    setpasswordInfo(values)
  }
  const handleConfirmPassword = values => {
    setconfirmPasswordInfo(values)
  }

  const checkResetPassword = values => {
    if (values?.password !== values?.confirm_password) {
      const invalidPassword = 'Password Doesnot Match'
      notification.error({
        message: invalidPassword,
      })
      setpasswordOk(true)
    } else {
      onSubmit(values)
    }
  }

  useEffect(() => {
    if (passwordField === '' && confirmPasswordField === '') {
      setpasswordOk(true)
    }

    if (passwordInfo == null && confirmPasswordInfo == null) {
      form.setFieldsValue({
        password: '',
        confirm_password: '',
      })
    }

    // if (storeinfo?.selectedData?.openResetPassword === true) {
    //   form.setFieldsValue({
    //     password: '',
    //     confirm_password: '',
    //   })
    // dispatch({
    //   type: StoreInfoAction.SHOW_RESET_PASSWORD,
    //   payload: {
    //     openResetPassword: false,
    //   },
    // })
    // }

    if (confirmPasswordInfo?.length === passwordInfo?.length) {
      if (passwordInfo !== confirmPasswordInfo) {
        const invalidPassword = 'Password Doesnot Match'
        notification.error({
          message: invalidPassword,
        })
        setpasswordOk(true)
      } else {
        setpasswordOk(false)
      }
    } // eslint-disable-next-line
  }, [passwordInfo, confirmPasswordInfo, form])

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" requiredMark="optional" onFinish={checkResetPassword}>
        <Form.Item
          name="password"
          label={formatMessage({ id: 'form.label.password' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.NewPassword' })}`}
            maxLength={32}
            onChange={e => handlePasswordChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label={formatMessage({ id: 'form.label.confirm_password' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'form.error.confirm_password.required' }),
            },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.confirm_password.account' })}`}
            maxLength={32}
            onChange={e => handleConfirmPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            disabled={passwordOk}
          >
            {formatMessage({ id: 'action.reset' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(ConfirmPassword))
