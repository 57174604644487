import React from 'react'
import { injectIntl, useIntl } from 'react-intl'
import { Button, Modal } from 'antd'

const RemoveModal = ({ visible, title, description, onSubmit, onCancel, loading }) => {
  const { formatMessage } = useIntl()

  const formatedtitle = formatMessage({ id: 'text.resendInvoice' })
  const titleData = `${formatedtitle} -
    ${title}`

  return (
    <Modal title={titleData} visible={visible} onCancel={onCancel} footer={null}>
      <div className="font-size-16">{description}</div>
      <div className="text-right">
        <Button size="medium" type="secondary" className="mr-3" secondary onClick={onCancel}>
          {formatMessage({ id: 'action.no' })}
        </Button>
        <Button size="medium" type="primary" success onClick={onSubmit} loading={loading}>
          {formatMessage({ id: 'action.yes' })}
        </Button>
      </div>
    </Modal>
  )
}

export default injectIntl(RemoveModal)
