import actions from './actions'

export const initialState = {
  data: null,
  activeList: null,
  inventorylist: null,
  inventoryId: null,
  inventoryData: null,
  search: null,
  selectedData: null,
  creating: false,
  updating: false,
  adding: false,
  displayAddInventory: false,
  displayEditnventory: false,
  displayquantitydrawer: false,
}

export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_INVENTORY:
      return { ...state, displayAddInventory: true }
    case actions.HIDE_INVENTORY:
      return { ...state, displayAddInventory: false }
    case actions.EDIT_SHOW_INVENTORY:
      return { ...state, selectedData: action.payload.inventory, displayEditnventory: true }
    case actions.HIDE_SHOW_INVENTORY:
      return { ...state, selectedData: null, displayEditnventory: false }
    case actions.SHOW_STOCK:
      return { ...state, selectedData: action.payload.inventory, displayquantitydrawer: true }
    case actions.HIDE_STOCK:
      return { ...state, selectedData: null, displayquantitydrawer: false }
    default:
      return state
  }
}
