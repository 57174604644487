import apiClient from 'services/axios'

const BASE_URL = 'supportuser'
const BASE_URL2 = 'user'

export async function load(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changeEmail(payload) {
  return apiClient
    .post(`${BASE_URL}/email/change`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/login`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function recover(payload) {
  return apiClient
    .post(`${BASE_URL}/recover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function reset(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function resetPassword(payload) {
  return apiClient
    .post(`${BASE_URL2}/password/changepassword`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function logout() {
  return apiClient
    .delete(`${BASE_URL}/logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
