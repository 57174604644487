import React from 'react'
import { Tag } from 'antd'
import { parseType } from 'utils/parser'
import { cardTypes } from 'utils/data'

const GiftCardDetailHeader = ({ cardType, giftCardNumber, externalCard, recipientName }) => {
  return (
    <>
      <h5 className="font-weight-bold mb-4">
        <p>{recipientName}</p>
        <span className="mr-2">{giftCardNumber}</span>
        <Tag color={cardType === 'digital' ? 'orange' : 'blue'}>
          {parseType(cardType, cardTypes)}
        </Tag>
        {externalCard && (
          <div className="mt-2">
            <span className="mr-1">{externalCard.gift_card_number}</span>
            <Tag color="#b51241">{externalCard.platform}</Tag>
          </div>
        )}
      </h5>
    </>
  )
}

export default GiftCardDetailHeader
