import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import leads from './leads/reducers'
import country from './country/reducers'
import storeinfo from './storeinfo/reducers'
import invoice from './invoice/reducers'
import hardware from './hardware/reducers'
import inventory from './inventory/reducers'
import businesstype from './businesstype/reducers'
import apilog from './apilog/reducers'
import migration from './migration/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    staff,
    leads,
    country,
    storeinfo,
    invoice,
    hardware,
    inventory,
    businesstype,
    apilog,
    migration,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
