import actions from './actions'

const initialState = {
  data1: null,
  data2: null,
  data3: null,
  notesinfo: null,
  filterdata: null,
  selectedData: null,
  newlyAdded: null,
  search: null,
  displayLeadNotesDrawer: false,
  displayLeadAddDrawer: false,
  creating: false,
  displayLeadBookdemoDrawer: false,
  displayLeadUpdateDrawer: false,
  updating: false,
  displayLeadRemoveModal: false,
  removing: false,
}

export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }
    case actions.EDIT_DATA:
      return {
        ...state,
        displayLeadUpdateDrawer: false,
        data: state.data.map(o => {
          if (action.payload.data._id === o._id) {
            return action.payload.data
          }
          return o
        }),
      }
    case actions.REMOVE_DATA:
      return {
        ...state,
        displayLeadRemoveModal: false,
      }
    case actions.SHOW_CREATE:
      return { ...state, displayLeadAddDrawer: true }

    case actions.HIDE_CREATE:
      return { ...state, displayLeadAddDrawer: false }
    case actions.SHOW_DEMO:
      return { ...state, displayLeadBookdemoDrawer: true }
    case actions.HIDE_DEMO:
      return { ...state, displayLeadBookdemoDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload, displayLeadUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayLeadUpdateDrawer: false }
    case actions.SHOW_NOTES:
      return { ...state, selectedData: action.payload.notes, displayLeadNotesDrawer: true }
    case actions.HIDE_NOTES:
      return { ...state, selectedData: null, displayLeadNotesDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.leads, displayLeadRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayLeadRemoveModal: false }
    case actions.ADDNOTES:
      return {
        ...state,

        notesinfo: [...state.notesinfo, action.payload.notesinfo],
        updating: false,
      }
    default:
      return state
  }
}
