import { all, call, put, takeLatest } from 'redux-saga/effects'
import loadApilogs from 'api/apilogs'
import actions from './actions'

export function* LOAD_PAYMENT_LOG() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: true,
    },
  })
  const { response } = yield call(loadApilogs)
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: false,
    },
  })
  if (response) {
    const { paymentLogs } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: paymentLogs,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_PAYMENT_LOG, LOAD_PAYMENT_LOG)])
}
