import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreinfoAction from 'redux/storeinfo/actions'
import moment from 'moment-timezone'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Tooltip, Form, Input, Button } from 'antd'
import Loading from 'components/app/common/Loading'
import style from './style.module.scss'

const mapStateToProps = ({ storeinfo, dispatch, settings }) => ({
  storeinfo,
  dispatch,
  settings,
})

const scrollToRef = ref => window.scrollTo(500, ref.current.offsetTop)

const Notes = ({ dispatch, storeinfo, intl: { formatMessage } }) => {
  const path = window.location.pathname
  const userId = path.replace('/manage/store-info/', '')
  const [form] = Form.useForm()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    if (userId) {
      dispatch({
        type: StoreinfoAction.GETNOTES,
        payload: userId,
      })
    }
  }, [dispatch, userId])

  const inputRef = useRef(null)

  const onFinish = values => {
    dispatch({
      type: StoreinfoAction.SAVENOTE,
      payload: {
        userId,
        ...values,
      },
    })
    form.resetFields()

    inputRef.current.focus()
  }

  const notesinfo = storeinfo?.notesinfo

  if (!notesinfo) return <Loading />

  const groups = {}

  // eslint-disable-next-line
  notesinfo.forEach(function(notesdata) {
    const date = notesdata?.created_at.split('T')[0]
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(notesdata)
    return groups
  }, {})

  const groupArrays = Object.keys(groups).map(created_at => {
    return {
      created_at,
      listed_data: groups[created_at],
    }
  })

  const length = groupArrays?.length || 0

  return (
    <div>
      <div className="card" hidden={length === 0}>
        <div className="card-body">
          <div
            className="notes-section height-600 d-flex flex-column justify-content-center"
            style={{ height: '30rem !important' }}
          >
            <PerfectScrollbar
              onScrollY={container => console.log(`scrolled to: ${container.scrollTop}.`)}
            >
              <div className={style.contentWrapper} ref={myRef}>
                {groupArrays?.map(item => (
                  <div key={item._id}>
                    <div className="text-gray-4 font-size-14 text-center text-capitalize">
                      {moment.tz(item?.created_at, 'America/Toronto').format(' MMM D, YYYY')}
                    </div>
                    {item.listed_data.map(item1 => (
                      <div
                        className={`${style.message} ${style.answer} ${style.answermarginbottom}`}
                      >
                        <div className={style.messageContent}>
                          <div className="text-gray-4 text-capitalize" style={{ fontSize: '13px' }}>
                            {item1?.supportuser?.name}
                            <Tooltip
                              title={moment
                                .tz(item1?.created_at, 'America/Toronto')
                                .format('MMM D [@] hh:mm A')}
                              placement="topLeft"
                            >
                              &nbsp;{' '}
                              <span title="">
                                {moment
                                  .tz(item1?.created_at, 'America/Toronto')
                                  .format('MMM D [@] hh:mm A')}
                              </span>
                            </Tooltip>
                          </div>
                          <div>{item1?.message}</div>
                        </div>

                        <div className={`${style.messageAvatar} kit__utils__avatar`}>
                          <img src="resources/images/avatars/avatar-2.png" alt="You" />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
      <div className="card" hidden={length !== 0}>
        <div className="card-body">
          <div
            className="height-600 d-flex flex-column justify-content-end"
            style={{ height: '30rem !important' }}
          >
            <div className="row" style={{ marginBottom: '20px' }}>
              <div className="col-md-12">
                <div className="d-flex flex-wrap m-1">
                  <div className="flex-grow-1">
                    <div
                      className="text-center font-weight-normal font-size-15 text-gray-5"
                      style={{ padding: '0 0 150px 0' }}
                    >
                      {formatMessage({ id: 'text.nonotestoshow' })}
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card" style={{ marginTop: '5px' }}>
        <div className="card-body">
          <Form layout="vertical" onFinish={onFinish} form={form} hideRequiredMark>
            <Form.Item
              name="message"
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <div className="input-group mb-3" style={{ flexWrap: 'nowrap' }}>
                <Input
                  type="text"
                  placeholder={formatMessage({ id: 'form.placeholder.message' })}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  ref={inputRef}
                  size="large"
                  required
                />
                <div className="input-group-append">
                  <Button
                    className="btn btn-primary"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    onClick={executeScroll}
                    loading={storeinfo?.updating}
                  >
                    {formatMessage({ id: 'text.notes.button_message' })}
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Notes))
