import actions from './actions'

const initialState = {
  businessLogsData: null,
  businessApiResponse: null,
  imageApiResponse: null,
  cardsApiResponse: null,
  customerApiResponse: null,
  selectedData: null,
  selectedMigrationData: null,
  storelistdata: null,
  selectedMigrationLog: null,
  pageLoader: false,
  displayMigrationDrawer: false,
  displayUpdateDrawer: false,
  creating: false,
  displayRemoveModal: false,
  removing: false,
  updating: false,
  setApiInProgress: false,
  migrationListLogs: null,
}

export default function migrationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case actions.REMOVE_MIGRATED_BUSINESS:
      return {
        ...state,
        displayRemoveModal: false,
      }
    case actions.SHOW_MIGRATION:
      return { ...state, displayMigrationDrawer: true }
    case actions.HIDE_MIGRATION:
      return { ...state, displayMigrationDrawer: false }
    case actions.SHOW_MIGRATION_RES_DRAWER:
      return {
        ...state,
        selectedMigrationLog: action.payload.migrationlogs,
        updating: true,
        displayUpdateDrawer: true,
      }
    case actions.HIDE_MIGRATION_RES_DRAWER:
      return { ...state, updating: false, displayUpdateDrawer: false }
    case actions.SHOW_MIGRATED_BUSINESS_REMOVE:
      return { ...state, selectedData: action.payload.migration, displayRemoveModal: true }
    case actions.HIDE_MIGRATED_BUSINESS_REMOVE:
      return { ...state, displayRemoveModal: false }
    default:
      return state
  }
}
