import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StoreInfoAction from 'redux/storeinfo/actions'
import Loading from 'components/app/common/Loading'
import Transaction from './Transaction'
import GiftCardDetailHeader from '../GiftCardDetailHeader'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const TransactionHistory = ({ dispatch, storeinfo }) => {
  useEffect(() => {
    dispatch({
      type: StoreInfoAction.LOAD_TRANSACTIONS,
      payload: {
        giftCardId: storeinfo.selectedData?.giftCardId,
        business: storeinfo.selectedData?.business,
        location: storeinfo.selectedData?.location,
      },
    })
  }, [dispatch, storeinfo.selectedData])

  if (!storeinfo.transactions) return <Loading />

  return (
    <div>
      <GiftCardDetailHeader
        cardType={storeinfo.selectedData?.card_type}
        giftCardNumber={storeinfo.selectedData?.gift_card_number}
        externalCard={storeinfo.selectedData?.external_card}
        recipientName={storeinfo.selectedData?.recipient?.name}
      />
      {storeinfo.transactions?.map(transaction => (
        <Transaction
          key={transaction._id}
          transaction={transaction}
          transactionTypes={storeinfo.transactionTypes}
          currency={storeinfo?.businessdata?.currency}
        />
      ))}
    </div>
  )
}

export default connect(mapStateToProps)(TransactionHistory)
