import React from 'react'
import { connect } from 'react-redux'
import { useIntl, injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { Row, Col } from 'antd'
import BamboraDetails from '../BamboraDetails'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const Bambora = ({ dispatch, storeinfo, bambora }) => {
  const { formatMessage } = useIntl()

  const showEditBamboraModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayEditBambora: true,
      },
    })
  }

  const hideEditBamboraModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayEditBambora: false,
      },
    })
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Row>
            <Col span={12}>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <span className="text-gray-6 font-size-16 font-weight-bold">
                    {`${bambora.three_digit} - xxxxx${bambora.five_twelve_digit}`}
                  </span>
                  <br />
                  <span className="text-gray-6 font-size-16 font-weight-bold">
                    {`${bambora.recipient_name} -  ${bambora.customer_code}`}
                  </span>
                </div>
              </div>
            </Col>
            <Col span={10}>
              <Row align="middle" justify="center">
                <Col className="mb-2" push={6}>
                  <button type="button" className="btn btn-primary" onClick={showEditBamboraModal}>
                    <i className="fe fe-plus mr-1" aria-hidden="true" />
                    {formatMessage({ id: 'action.editbambora' })}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <BamboraDetails
        payment_method="bambora"
        visible={storeinfo?.displayEditBambora}
        onCancel={hideEditBamboraModal}
        loading={storeinfo?.addingCard}
      />
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(Bambora))
