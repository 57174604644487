import actions from './actions'

const initialState = {
  data: null,
  businessInfo: null,
  TaxList: null,
  cardList: null,
  invoiceData: null,
  search: null,
  selectedBusinessName: '',
  selectedData: null,
  creating: false,
  displayPurchaseOrder: false,
  displayAddNewOrderDrawer: false,
  displayAddCardModal: false,
  displayTrackingModal: false,
}

export default function hardwareReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.VIEW_PURCHASE_ORDER:
      return { ...state, selectedData: action.payload, displayPurchaseOrder: true }
    case actions.HIDE_PURCHASE_ORDER:
      return { ...state, displayPurchaseOrder: false }
    case actions.SHOW_CREATE:
      return { ...state, displayAddNewOrderDrawer: true }
    case actions.HIDE_CREATE:
      return {
        ...state,
        businessInfo: null,
        selectedBusinessName: '',
        displayAddNewOrderDrawer: false,
      }
    case actions.SHOW_MODAL:
      return { ...state, selectedData: action.payload, displayAddCardModal: true }
    case actions.HIDE_MODAL:
      return { ...state, selectedData: null, displayAddCardModal: false }
    case actions.SHOW_TRACKING_MODAL:
      return { ...state, selectedData: action.payload, displayTrackingModal: true }
    case actions.HIDE_TRACKING_MODAL:
      return { ...state, selectedData: null, displayTrackingModal: false }
    default:
      return state
  }
}
