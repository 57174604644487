import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Divider, Modal, Tabs } from 'antd'
import CardTitle from '../CardTitle'
import ImageGallery from '../ImageGallery'
import ImageUpload from '../ImageUpload'

const { TabPane } = Tabs

const ChooseImage = ({
  visible,
  title,
  gallery,
  library,
  onImageUpload,
  uploading,
  onSubmit,
  onCancel,
  intl: { formatMessage },
}) => {
  const [selectedImage, setSelectedImage] = useState()

  const onImageSelect = image => {
    setSelectedImage(image)
  }

  return (
    <Modal title={null} visible={visible} onCancel={onCancel} footer={null} width={800}>
      <CardTitle title={title || formatMessage({ id: 'text.chooseImage' })} />
      <Tabs>
        <TabPane tab={formatMessage({ id: 'tab.yourLibrary' })} key="1">
          <ImageGallery images={library} selectedImage={selectedImage} onSelect={onImageSelect} />
        </TabPane>
        <TabPane tab={formatMessage({ id: 'tab.giftEasyGallery' })} key="2">
          <ImageGallery images={gallery} selectedImage={selectedImage} onSelect={onImageSelect} />
        </TabPane>
        <TabPane tab={formatMessage({ id: 'tab.upload' })} key="3">
          <ImageUpload onImageUpload={onImageUpload} uploading={uploading} />
        </TabPane>
      </Tabs>
      <Divider />
      <div className="text-right mt-4">
        <Button className="mr-3" size="large" onClick={onCancel}>
          {formatMessage({ id: 'action.cancel' })}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() => onSubmit(selectedImage)}
          disabled={!selectedImage}
        >
          {formatMessage({ id: 'action.select' })}
        </Button>
      </div>
    </Modal>
  )
}

export default injectIntl(ChooseImage)
