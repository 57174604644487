import apiClient from 'services/axios'

const BASE_URL = 'business'
const BASE_URL1 = 'migrationlog'

export async function importAll(payload) {
  const store_id = payload?.store_id
  return apiClient
    .get(`${BASE_URL}/importAll/${store_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeBusiness(id, payload) {
  return apiClient
    .delete(`${BASE_URL}/${id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadMigrationlogs(payload) {
  return apiClient
    .get(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
