import React from 'react'
import { injectIntl } from 'react-intl'
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Loading from '../Loading'

const { Dragger } = Upload

const ImageUpload = ({ onImageUpload, uploading, intl: { formatMessage } }) => {
  const props = {
    name: 'image',
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const formData = new FormData()
      formData.append('image', file)
      onImageUpload(formData)
      return false
    },
  }

  if (uploading)
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: 420 }}
      >
        <Loading />
        <p>{formatMessage({ id: 'progress.uploading' })}</p>
      </div>
    )

  return (
    <div style={{ height: 420 }}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{formatMessage({ id: 'helper.dragDropImage' })}</p>
      </Dragger>
    </div>
  )
}

export default injectIntl(ImageUpload)
