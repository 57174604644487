import React from 'react'
import { injectIntl } from 'react-intl'
import { getFormattedDateTime, parseType } from 'utils/parser'
import { paymentMethods } from 'utils/data'

const Transaction = ({
  transaction,
  currency,
  transactionTypes,
  intl: { formatMessage, formatNumber },
}) => {
  const openReceipt = url => {
    window.open(url)
  }

  return (
    <div className="card">
      <div className="card-body p-2">
        <div className="d-flex flex-wrap m-1">
          <h5 className="flex-grow-1 m-1">
            <div className="font-weight-bold">
              <span className="mr-2">
                {parseType(transaction.transaction_type, transactionTypes)} -
              </span>
              <span
                className={`${
                  transaction.transaction_type === 'redeem' ? 'text-danger' : 'text-success'
                }`}
              >
                {formatNumber((transaction.total_amount || transaction.amount?.price) / 100, {
                  style: 'currency',
                  currency,
                })}
              </span>
            </div>
            <div className="font-size-14 font-weight-bold text-secondary mt-1">
              On {getFormattedDateTime(transaction.transaction_at || transaction.created_at, true)}
            </div>
          </h5>
          <div className="m-1">
            {transaction.staff != null && transaction.location != null
              ? formatMessage(
                  { id: 'text.byUser' },
                  {
                    name: transaction.staff.name,
                    location: transaction.location.city,
                  },
                )
              : formatMessage({ id: 'text.onlineStore' })}
          </div>
        </div>
        {transaction.coupon && (
          <div className="d-flex m-2">
            {transaction?.discount_amount / 100 > 0 && (
              <>
                <div className="flex-grow-1">
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.discountApplied' })}
                  </div>
                  {transaction?.coupon?.type === 'percent' && (
                    <>
                      {console.log('transaction?.coupon inside', transaction?.coupon)}
                      <div className="font-size-16 font-weight-bold text-primary">
                        {formatNumber(+(transaction?.coupon?.value / 100))}% of {'  '}
                        {formatNumber(+(transaction.discount_amount / 100), {
                          style: 'currency',
                          currency,
                        })}{' '}
                        ({transaction.coupon.code})
                      </div>
                    </>
                  )}
                  {transaction?.coupon?.type === 'dollar' && (
                    <>
                      <div className="font-size-16 font-weight-bold text-primary">
                        {formatNumber(-(transaction.discount_amount / 100), {
                          style: 'currency',
                          currency,
                        })}{' '}
                        ({transaction.coupon.code})
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {transaction?.promotion_amount / 100 > 0 && (
              <>
                {transaction?.coupon?.type !== undefined && (
                  <>
                    <div className="flex-grow-1">
                      <div className="font-size-12 text-gray-5">
                        {formatMessage({ id: 'text.promoApplied' })}
                      </div>
                      {transaction?.coupon?.type === 'percent' && (
                        <>
                          <div className="font-size-16 font-weight-bold text-success">
                            {formatNumber(+(transaction?.coupon?.value / 100))}% of {'  '}
                            {formatNumber(+(transaction.promotion_amount / 100), {
                              style: 'currency',
                              currency,
                            })}{' '}
                            ({transaction.coupon.code})
                          </div>
                        </>
                      )}
                      {transaction?.coupon?.type === 'dollar' && (
                        <>
                          <div className="font-size-16 font-weight-bold text-success">
                            {formatNumber(+(transaction.promotion_amount / 100), {
                              style: 'currency',
                              currency,
                            })}{' '}
                            ({transaction.coupon.code})
                          </div>
                        </>
                      )}
                      Added additionally in Gift Card Balance
                    </div>
                  </>
                )}
                {transaction?.coupon?.type === undefined && (
                  <>
                    <div className="flex-grow-1">
                      <div className="font-size-12 text-gray-5">
                        {formatMessage({ id: 'text.promoApplied' })}
                      </div>
                      {transaction?.coupon?.type === 'percent' && (
                        <>
                          <div className="font-size-16 font-weight-bold text-success">
                            {formatNumber(+(transaction?.coupon?.value / 100))}% of{' '}
                            {formatNumber(+(transaction.promotion_amount / 100), {
                              style: 'currency',
                              currency,
                            })}{' '}
                            ({transaction.coupon.code})
                          </div>
                        </>
                      )}
                      {transaction?.coupon?.type === 'dollar' && (
                        <>
                          <div className="font-size-16 font-weight-bold text-success">
                            {formatNumber(+(transaction.promotion_amount / 100), {
                              style: 'currency',
                              currency,
                            })}{' '}
                            ({transaction.coupon.code})
                          </div>
                        </>
                      )}
                      Added additionally in Gift Card Balance
                    </div>
                  </>
                )}
              </>
            )}

            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.paidAmount' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {formatNumber(transaction.paid_amount / 100, {
                  style: 'currency',
                  currency,
                })}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex m-2">
          <div className="flex-grow-1">
            <div className="font-size-12 text-gray-5">
              {formatMessage({ id: 'text.authNumber' })}
            </div>
            <div className="font-size-16 font-weight-bold">{transaction._id}</div>
          </div>
          <div
            className="flex-grow-1 text-right"
            hidden={!transaction.payment_details?.payment_method}
          >
            <div className="font-size-12 text-gray-5">
              {formatMessage({ id: 'text.paymentMethod' })}
            </div>
            <div className="font-size-16 font-weight-bold">
              {parseType(transaction.payment_details?.payment_method, paymentMethods)}
            </div>
          </div>
        </div>
        {transaction.receipt_url && (
          <div className="text-right m-2">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => openReceipt(transaction.receipt_url)}
            >
              <i className="fe fe-file-text mr-2" />
              {formatMessage({ id: 'action.viewReceipt' })}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default injectIntl(Transaction)
