import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { importAll, removeBusiness, loadMigrationlogs } from 'api/migration'
import actions from './actions'

export function* LOAD_MIGRATION_RES_LOG() {
  // const business_id = payload?.payload?.business_id

  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: true,
    },
  })

  const { response } = yield call(loadMigrationlogs)

  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: false,
    },
  })
  if (response) {
    const { migrationLogs } = response.data

    yield put({
      type: actions.SET_STATE,
      payload: {
        data: migrationLogs,
      },
    })
  }
}

export function* GET_IMPORT_BUSINESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
      setApiInProgress: true,
    },
  })
  const { response } = yield call(importAll, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      setApiInProgress: true,
    },
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayMigrationDrawer: false,
        setApiInProgress: false,
      },
    })
    yield call(LOAD_MIGRATION_RES_LOG)
  } else {
    // If response is undefined
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayMigrationDrawer: false,
        setApiInProgress: false,
      },
    })
    yield call(LOAD_MIGRATION_RES_LOG)
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { id } = payload
  const { response } = yield call(removeBusiness, id)

  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.REMOVE_MIGRATED_BUSINESS,
      payload: {
        id,
      },
    })
    yield call(LOAD_MIGRATION_RES_LOG)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_IMPORT_BUSINESS, GET_IMPORT_BUSINESS),
    takeLatest(actions.LOAD_MIGRATION_RES_LOG, LOAD_MIGRATION_RES_LOG),
    takeEvery(actions.REMOVE, REMOVE),
  ])
}
