import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
// import StoreInfoAction from 'redux/storeinfo/actions'
import BamboraPayment from 'components/app/common/BamboraPayment'
import AddCardModal from '../AddCardModal'

const { TabPane } = Tabs

const PaymentModalTab = () => {
  const { formatMessage } = useIntl()

  const storeinfo = useSelector(state => state.storeinfo)

  //   const dispatch = useDispatch()

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'card') {
    tabUrlVal = '1'
  } else if (tabname === 'bambora') {
    tabUrlVal = '2'
  }
  const cardInfoLength = storeinfo?.subscriptiondata?.cards.length

  return (
    <Tabs defaultActiveKey={tabUrlVal}>
      {cardInfoLength !== 0 ? (
        <TabPane
          tab={
            <Link to="?tab=card">
              <span>{formatMessage({ id: 'text.billing.tab.addnewcard' })}</span>
            </Link>
          }
          key={1}
        >
          <AddCardModal />
        </TabPane>
      ) : (
        <>
          <TabPane
            tab={
              <Link to="?tab=card">
                <span>{formatMessage({ id: 'text.billing.tab.addnewcard' })}</span>
              </Link>
            }
            key={1}
          >
            <AddCardModal />
          </TabPane>
          <TabPane
            tab={
              <Link to="?tab=bambora">
                <span>{formatMessage({ id: 'text.billing.tab.addbambora' })}</span>
              </Link>
            }
            key={2}
          >
            <BamboraPayment payment_method="bambora" />
          </TabPane>
        </>
      )}
    </Tabs>
  )
}

export default PaymentModalTab
