import React, { useState } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const NumberInput = ({ value, onChange, precision, max, ...otherProps }) => {
  const [number, setNumber] = useState(null)

  const onNumberChange = changedValue => {
    let newNumber
    const dec = changedValue.indexOf('.')
    const tooLong = changedValue.length > dec + 3
    const invalidNum =
      Number.isNaN(parseFloat(changedValue)) || parseFloat(changedValue).toFixed(precision) > max
    if ((dec >= 0 && tooLong) || invalidNum) {
      newNumber = changedValue.replace('.', '')
    } else {
      newNumber = changedValue
    }
    if (Number.isNaN(number) || Number.isNaN(newNumber)) {
      setNumber(null)
      onChange(null)
      return
    }
    setNumber(newNumber)
    onChange(newNumber)
  }

  return (
    <Input
      type="number"
      value={value || number}
      onChange={e => onNumberChange(e.target.value)}
      {...otherProps}
    />
  )
}

NumberInput.propTypes = {
  precision: PropTypes.number,
  max: PropTypes.number,
}

NumberInput.defaultProps = {
  precision: 2,
  max: 10000,
}

export default NumberInput
