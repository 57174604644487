import apiClient from 'services/axios'

const BASE_URL = 'supportuser'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function update(UseridSel, updatedData) {
  return apiClient
    .put(`${BASE_URL}/${UseridSel}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function remove(staffId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${staffId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function resellerlist() {
  return apiClient
    .get(`${BASE_URL}/resellerlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function rolelist() {
  return apiClient
    .get(`${BASE_URL}/userlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
