import React from 'react'
import { useIntl } from 'react-intl'
import { Button, DatePicker, Form } from 'antd'
import moment from 'moment'

const ChangeBillingDate = ({ onSubmit, loading, data }) => {
  const { formatMessage } = useIntl()
  function disabledDate(current) {
    return current < moment().startOf('day')
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            initialValues={{
              next_billing_at: moment(data?.nextBillingDateInfo),
            }}
            onFinish={onSubmit}
          >
            <Form.Item name="next_billing_at" label={formatMessage({ id: 'form.label.date' })}>
              <DatePicker
                style={{ width: '35%' }}
                size="large"
                format="MMM D, YYYY"
                disabledDate={disabledDate}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item className="text-right">
              <Button size="large" type="primary" htmlType="submit" loading={loading}>
                {formatMessage({ id: 'action.change' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ChangeBillingDate
