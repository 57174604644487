import apiClient from 'services/axios'

const BASE_URL1 = 'business'
const BASE_URL2 = 'location'
const BASE_URL3 = 'setting'
const BASE_URL4 = 'device'
const BASE_URL5 = 'subscription/plan-inventory'
const BASE_URL6 = 'invoice'
const BASE_URL7 = 'subscription'
const BASE_URL8 = 'gift-card'
const BASE_URL9 = 'user'

export async function createbusiness(payload) {
  return apiClient
    .post(`${BASE_URL9}/registerUser`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addnotes(userId, payload) {
  delete payload.userId
  return apiClient
    .post(`${BASE_URL1}/${userId}/note`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getnotes(business_id, payload) {
  return apiClient
    .get(`${BASE_URL1}/${business_id}/notes`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function giftcardlist(payload) {
  return apiClient
    .get(`${BASE_URL8}/${payload}/list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadTransactions(payload) {
  return apiClient
    .get(
      `${BASE_URL8}/${payload?.giftCardId}/transaction?business=${payload?.business}&location=${payload.location}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resendwelcomeemail(payload) {
  return apiClient
    .post(`${BASE_URL1}/${payload}/resend-welcome-email`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadall(payload) {
  return apiClient
    .get(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadStore(business_id) {
  return apiClient
    .get(`${BASE_URL1}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function locationlist(business_id) {
  return apiClient
    .get(`${BASE_URL2}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function devicelist(business_id) {
  return apiClient
    .get(`${BASE_URL4}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadgiftcardsettings(business_id) {
  return apiClient
    .get(`${BASE_URL3}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function updatestore(business_id, payload) {
  return apiClient
    .put(`${BASE_URL1}/${business_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function uploadimage(business_id, payload) {
  return apiClient
    .post(`${BASE_URL3}/${business_id}/upload-image`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function update(business_id, payload) {
  return apiClient
    .put(`${BASE_URL3}/${business_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getplan(business_id) {
  return apiClient
    .get(`${BASE_URL5}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadinvoice(business_id) {
  return apiClient
    .get(`${BASE_URL6}/${business_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function viewinvoice(invoiceid) {
  return apiClient
    .get(`${BASE_URL6}/view/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function resendinvoice(invoiceid) {
  return apiClient
    .get(`${BASE_URL6}/resend/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function retrypayment(invoiceid) {
  return apiClient
    .get(`${BASE_URL6}/retry/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function refundinvoice(invoiceIdVal, amountVal) {
  return apiClient
    .post(`${BASE_URL6}/refund/${invoiceIdVal}`, amountVal)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function viewinvoiceactivity(invoiceid) {
  return apiClient
    .get(`${BASE_URL6}/view/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function addCard(payload, business_id) {
  return apiClient
    .post(`${BASE_URL7}/${business_id}/addcard`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function removecard(business_id, id) {
  return apiClient
    .delete(`${BASE_URL7}/${business_id}/card/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changeDefaultCard(business_id, id) {
  return apiClient
    .put(`${BASE_URL7}/${business_id}/card/${id}/default`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changebillingdate(business_id, payload) {
  return apiClient
    .put(`${BASE_URL7}/${business_id}/next-billing-date`, payload?.next_billing_at)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function addgiftcard(business_id, payload) {
  delete payload.business
  return apiClient
    .put(`${BASE_URL7}/${business_id}/add-gift-card`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function senddigitalcardemail(payload) {
  return apiClient
    .get(`${BASE_URL8}/${payload}/resend-all`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
