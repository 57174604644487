const actions = {
  SET_STATE: 'invoice/SET_STATE',
  GET_PAID: 'invoice/GET_PAID',
  GET_NOTPAID: 'invoice/GET_NOTPAID',
  GET_REFUND: 'invoice/GET_REFUND',
  VIEW_INVOICES: 'storeinfo/VIEW_INVOICES',
  RESEND_INVOICE: 'invoice/RESEND_INVOICE',
  SHOW_RESEND_INVOICE_MODAL: 'invoice/SHOW_RESEND_INVOICE_MODAL',
  HIDE_RESEND_INVOICE_MODAL: 'invoice/HIDE_RESEND_INVOICE_MODAL',
  SHOW_INVOICE_ACTIVITY_MODAL: 'invoice/SHOW_INVOICE_ACTIVITY_MODAL',
  HIDE_INVOICE_ACTIVITY_MODAL: 'invoice/HIDE_INVOICE_ACTIVITY_MODAL',
  SHOW_RETRY_PAYMENT_MODAL: 'invoice/SHOW_RETRY_PAYMENT_MODAL',
  HIDE_RETRY_PAYMENT_MODAL: 'invoice/HIDE_RETRY_PAYMENT_MODAL',
  RETRY_PAYMENT: 'invoice/RETRY_PAYMENT',
  LOAD_INVOICE_ACTIVITY: 'invoice/LOAD_INVOICE_ACTIVITY',
  SHOW_REFUND_INVOICE_MODAL: 'invoice/SHOW_REFUND_INVOICE_MODAL',
  HIDE_REFUND_INVOICE_MODAL: 'invoice/HIDE_REFUND_INVOICE_MODAL',
  REFUND_INVOICE: 'invoice/REFUND_INVOICE',
  DOWNLOAD_INVOICE: 'invoice/DOWNLOAD_INVOICE',
  CHANGE_PAYMENT_STATUS: 'invoice/CHANGE_PAYMENT_STATUS',
  SHOW_CHANGE_PAYMENT_STATUS: 'invoice/SHOW_CHANGE_PAYMENT_STATUS',
  HIDE_CHANGE_PAYMENT_STATUS: 'invoice/HIDE_CHANGE_PAYMENT_STATUS',
}

export default actions
