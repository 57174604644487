import React from 'react'
import ColorBox from '../ColorBox'

const ColorBoxContainer = ({ value, onChange, colors }) => {
  return (
    <div className="d-flex flex-row flex-wrap">
      {colors?.map(o => (
        <ColorBox key={o.color} color={o.color} selected={o.color === value} onSelect={onChange} />
      ))}
    </div>
  )
}

export default ColorBoxContainer
