import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { Form, Button, Input, Checkbox, Modal } from 'antd'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const BamboraDetails = ({
  dispatch,
  storeinfo,
  payment_method,
  visible,
  onCancel,
  loading,
  intl: { formatMessage },
}) => {
  const [form] = Form.useForm()

  const bamboraData = storeinfo?.subscriptiondata?.bambora

  useEffect(() => {
    if (bamboraData) {
      form.setFieldsValue({
        bambora: {
          three_digit: bamboraData?.three_digit,
          five_digit: bamboraData?.five_digit,
          five_twelve_digit: bamboraData?.five_twelve_digit,
          recipient_name: bamboraData?.recipient_name,
          customer_code: bamboraData?.customer_code,
        },
      })
    } // eslint-disable-next-line
  }, [form, bamboraData])

  const onFinish = values => {
    const formValues = { ...values }
    dispatch({
      type: StoreInfoAction.ADD_CARD,
      payload: formValues,
    })
  }
  return (
    <>
      <Modal
        width={640}
        title={formatMessage({ id: 'text.editbambora' })}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
      >
        <div className="card">
          <div className="card-body">
            <Form
              form={form}
              layout="vertical"
              requiredMark="optional"
              initialValues={{
                payment_method: 'bambora',
                is_default: true,
              }}
              onFinish={onFinish}
            >
              <h5 className="mb-4">
                <strong>{formatMessage({ id: 'text.paymentAccountInfo' })}</strong>
              </h5>
              <Form.Item name="payment_method" hidden>
                <Input maxLength={100} />
              </Form.Item>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    name={[payment_method, 'three_digit']}
                    label={formatMessage({ id: 'form.label.threeDigitFin' })}
                    rules={[
                      { required: true, message: formatMessage({ id: 'form.error.required' }) },
                    ]}
                  >
                    <Input size="large" maxLength={3} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name={[payment_method, 'five_digit']}
                    label={formatMessage({ id: 'form.label.fiveDigitFin' })}
                    rules={[
                      { required: true, message: formatMessage({ id: 'form.error.required' }) },
                    ]}
                  >
                    <Input size="large" maxLength={5} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name={[payment_method, 'five_twelve_digit']}
                    label={formatMessage({ id: 'form.label.tweleDigitFin' })}
                    rules={[
                      { required: true, message: formatMessage({ id: 'form.error.required' }) },
                    ]}
                  >
                    <Input size="large" maxLength={12} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name={[payment_method, 'recipient_name']}
                    label={formatMessage({ id: 'form.label.recipientName' })}
                    rules={[
                      { required: true, message: formatMessage({ id: 'form.error.required' }) },
                    ]}
                  >
                    <Input size="large" maxLength={100} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name={[payment_method, 'customer_code']}
                    label={formatMessage({ id: 'form.label.customerCode' })}
                    rules={[
                      { required: true, message: formatMessage({ id: 'form.error.required' }) },
                    ]}
                  >
                    <Input size="large" maxLength={100} />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-6">
                <Form.Item name="is_default" valuePropName="checked" hidden>
                  <Checkbox>
                    {formatMessage({ id: 'form.check.makeDefaultPaymentMethod' })}
                  </Checkbox>
                </Form.Item>
              </div>

              {/* <h5 className="mt-2 mb-2">
              <strong>{formatMessage({ id: 'text.status' })}</strong>
            </h5>
            <Form.Item
              name="is_active"
              label={formatMessage({ id: 'form.label.active' })}
              valuePropName="checked"
              requiredMark={false}
            >
              <Switch
                checkedChildren={formatMessage({ id: 'action.yes' })}
                unCheckedChildren={formatMessage({ id: 'action.no' })}
              />
            </Form.Item> */}

              <Form.Item className="text-right mt-3">
                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                  {formatMessage({ id: 'action.update' })}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(BamboraDetails))
