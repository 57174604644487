export const cardTypes = {
  digital: 'Digital Card',
  physical: 'Physical Card',
}

export const paymentMethods = {
  cash: 'Cash',
  card: 'Card',
  converge: 'Card',
  clover: 'Card',
}

export const staffRoles = [
  {
    key: 'support admin',
    name: 'Support Admin',
    // hidden: true,
  },
  {
    key: 'support agent',
    name: 'Support Agent',
  },
  {
    key: 'reseller admin',
    name: 'Reseller Admin',
  },
  {
    key: 'reseller agent',
    name: 'Reseller Agent',
  },
  {
    key: 'sales admin',
    name: 'Sales Admin',
  },
  {
    key: 'sales agent',
    name: 'Sales Agent',
  },
]

export const LeadTypes = [
  {
    key: 'Bakery',
    name: 'Bakery',
  },
  {
    key: 'Bar',
    name: 'Bar',
  },
  {
    key: 'Cafe',
    name: 'Cafe',
  },
  {
    key: 'Coffee Shop',
    name: 'Coffee Shop',
  },
  {
    key: 'Food Truck',
    name: 'Food Truck',
  },
  {
    key: 'Restaurant',
    name: 'Restaurant',
  },
  {
    key: 'Quick Service Restaurant',
    name: 'Quick Service Restaurant',
  },
  {
    key: 'Fine Dining Restaurant',
    name: 'Fine Dining Restaurant',
  },
  {
    key: 'Service',
    name: 'Service',
  },
  {
    key: 'Retail',
    name: 'Retail',
  },
  {
    key: 'Other',
    name: 'Other',
  },
]

export const dataCount = [
  {
    key: '0',
    value: '0',
  },
  {
    key: '100',
    value: '100',
  },
  {
    key: '200',
    value: '200',
  },
  {
    key: '300',
    value: '300',
  },
  {
    key: '400',
    value: '400',
  },
  {
    key: '500',
    value: '500',
  },
  {
    key: '600',
    value: '600',
  },
  {
    key: '700',
    value: '700',
  },
  {
    key: '800',
    value: '800',
  },
  {
    key: '900',
    value: '900',
  },
  {
    key: '1000',
    value: '1000',
  },
  {
    key: '1100',
    value: '1100',
  },
  {
    key: '1200',
    value: '1200',
  },
  {
    key: '1300',
    value: '1300',
  },
  {
    key: '1400',
    value: '1400',
  },
  {
    key: '1500',
    value: '1500',
  },
  {
    key: '1600',
    value: '1600',
  },
  {
    key: '1700',
    value: '1700',
  },
  {
    key: '1800',
    value: '1800',
  },
  {
    key: '1900',
    value: '1900',
  },
  {
    key: '2000',
    value: '2000',
  },
  {
    key: '2100',
    value: '2100',
  },
  {
    key: '2200',
    value: '2200',
  },
  {
    key: '2300',
    value: '2300',
  },
  {
    key: '2400',
    value: '2400',
  },
  {
    key: '2500',
    value: '2500',
  },
  {
    key: '2600',
    value: '2600',
  },
  {
    key: '2700',
    value: '2700',
  },
  {
    key: '2800',
    value: '2800',
  },
  {
    key: '2900',
    value: '2900',
  },
  {
    key: '3000',
    value: '3000',
  },
  {
    key: '3100',
    value: '3100',
  },
  {
    key: '3200',
    value: '3200',
  },
  {
    key: '3300',
    value: '3300',
  },
  {
    key: '3400',
    value: '3400',
  },
  {
    key: '3500',
    value: '3500',
  },
  {
    key: '3600',
    value: '3600',
  },
  {
    key: '3700',
    value: '3700',
  },
  {
    key: '3800',
    value: '3800',
  },
  {
    key: '3900',
    value: '3900',
  },
  {
    key: '4000',
    value: '4000',
  },
  {
    key: '4100',
    value: '4100',
  },
  {
    key: '4200',
    value: '4200',
  },
  {
    key: '4300',
    value: '4300',
  },
  {
    key: '4400',
    value: '4400',
  },
  {
    key: '4500',
    value: '4500',
  },
  {
    key: '4600',
    value: '4600',
  },
  {
    key: '4700',
    value: '4700',
  },
  {
    key: '4800',
    value: '4800',
  },
  {
    key: '4900',
    value: '4900',
  },
  {
    key: '5000',
    value: '5000',
  },
]

export const LeadSource = [
  {
    key: 'website',
    name: 'Website',
    hidden: true,
  },
  {
    key: 'google ad',
    name: 'Google Ad',
    hidden: true,
  },
  {
    key: 'facebook ad',
    name: 'Facebook Ad',
    hidden: true,
  },
  {
    key: 'reseller',
    name: 'Reseller',
    hidden: true,
  },
  {
    key: 'employee referral',
    name: 'Employee Referral',
  },
  {
    key: 'customer referral',
    name: 'Customer Referral',
  },
  {
    key: 'sales line',
    name: 'Sales Line',
  },
  {
    key: 'trade show',
    name: 'Trade Show',
  },
]

export const signupSource = {
  Portal: 'Portal',
  Clover: 'Clover Device',
  Poynt: 'Poynt Device',
  'Clover Web': 'Clover Web',
}
