import { createSelector } from 'reselect'

export const selectGiftCards = state => state?.storeinfo
export const selectStoreInfoReducer = state => state.storeinfo
export const selectActiveStores = state => state.storeinfo
export const selectUserInfo = state => state.user
export const selectInvoiceReducerInfo = state => state.invoice
export const selectCurrency = createSelector(
  [selectStoreInfoReducer],
  business => business?.currency,
)

export const selectGiftCardData = createSelector([selectGiftCards], giftCard => {
  if (giftCard.search && giftCard.giftCardListData) {
    const search = giftCard.search.toLowerCase()
    return giftCard.giftCardListData.filter(
      o =>
        o?.gift_card_number.includes(search) ||
        o.external_card?.gift_card_number.includes(search) ||
        o.recipient?.name?.toLowerCase().includes(search),
    )
  }
  return giftCard.giftCardListData
})

export const selectActiveStoreListData = createSelector([selectActiveStores], storeinfo => {
  if (storeinfo.searchactive && storeinfo.storelist) {
    const activestoresearch = storeinfo.searchactive.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(activestoresearch) ||
        o.location?.city?.toLowerCase()?.includes(activestoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(activestoresearch) ||
        o.user?.email?.toLowerCase()?.includes(activestoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectOnboardingStores = state => state.storeinfo

export const selectOnboardingStoreListData = createSelector([selectOnboardingStores], storeinfo => {
  if (storeinfo.searchonboarding && storeinfo.storelist) {
    const onboardingstoresearch = storeinfo.searchonboarding.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(onboardingstoresearch) ||
        o.location?.city?.toLowerCase()?.includes(onboardingstoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(onboardingstoresearch) ||
        o.user?.email?.toLowerCase()?.includes(onboardingstoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectInActiveStores = state => state.storeinfo

export const selectInctiveStoreListData = createSelector([selectInActiveStores], storeinfo => {
  if (storeinfo.searchinactive && storeinfo.storelist) {
    const inactivestoresearch = storeinfo.searchinactive.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(inactivestoresearch) ||
        o.location?.city?.toLowerCase()?.includes(inactivestoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(inactivestoresearch) ||
        o.user?.email?.toLowerCase()?.includes(inactivestoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectDemoStores = state => state.storeinfo

export const selectDemoStoreListData = createSelector([selectDemoStores], storeinfo => {
  if (storeinfo.searchdemo && storeinfo.storelist) {
    const demostoresearch = storeinfo.searchdemo.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(demostoresearch) ||
        o.location?.city?.toLowerCase()?.includes(demostoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(demostoresearch) ||
        o.user?.email?.toLowerCase()?.includes(demostoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectLocationInfo = state => state.storeinfo

export const selectLocationData = createSelector([selectLocationInfo], locdata => {
  if (locdata.searchlocation && locdata.locationlist) {
    const search = locdata.searchlocation.toLowerCase()
    return locdata?.locationlist?.filter(
      o => o.name?.toLowerCase()?.includes(search) || o.city?.toLowerCase()?.includes(search),
    )
  }
  return locdata.locationlist
})

export const selectDevicesInfo = state => state.storeinfo

export const selectDeviceData = createSelector([selectDevicesInfo], devdata => {
  if (devdata.searchlocation && devdata.deviceslist) {
    const search = devdata.searchlocation.toLowerCase()
    return devdata?.deviceslist?.filter(
      o => o.name?.toLowerCase()?.includes(search) || o.city?.toLowerCase()?.includes(search),
    )
  }
  return devdata.deviceslist
})

export const selectInvoiceInfo = state => state.storeinfo

export const selectInvoicedata = createSelector([selectInvoiceInfo], invoiceinform => {
  if (invoiceinform.search && invoiceinform.invoiceinfo) {
    return invoiceinform.invoiceinfo.filter(
      o =>
        o.invoice_number.includes(invoiceinform.search) ||
        o.formatted_invoice_number.includes(invoiceinform.search),
    )
  }
  return invoiceinform.invoiceinfo
})
