import React from 'react'
import { connect } from 'react-redux'
import { useIntl, injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { Col, Row } from 'antd'
import CardTitle from 'components/app/common/CardTitle'
import Bambora from '../Bambora'
import AddBamboraModal from '../AddBamboraModal'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const ChangeBambora = ({ dispatch, storeinfo, bambora }) => {
  const { formatMessage } = useIntl()

  const showChangeAddCardModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayChangeAddCardModal: true,
      },
    })
  }

  const hideChangeAddCardModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayChangeAddCardModal: false,
      },
    })
  }

  return (
    <div>
      <Row>
        <Col flex={1}>
          <CardTitle title={formatMessage({ id: 'text.savedBambora' })} />
        </Col>

        <Col flex={4}>
          <Row align="middle" justify="center">
            <Col className="mb-2" push={5}>
              <button type="button" className="btn btn-primary" onClick={showChangeAddCardModal}>
                <i className="fe fe-plus mr-1" aria-hidden="true" />
                {formatMessage({ id: 'action.changepaymentmethodtocard' })}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Bambora bambora={bambora} />

      <AddBamboraModal
        visible={storeinfo?.displayChangeAddCardModal}
        onCancel={hideChangeAddCardModal}
      />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ChangeBambora))
