import React from 'react'
import { connect } from 'react-redux'
import { useIntl, injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { Col, Row } from 'antd'
import CardTitle from 'components/app/common/CardTitle'
import Card from '../Card'
// import Bambora from '../Bambora'
import AddBamboraModal from '../AddBamboraModal'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const ChangeCard = ({
  dispatch,
  storeinfo,
  cards,
  changeDefaultCard,
  changingDefaultCard,
  removeCard,
  removingCard,
}) => {
  const { formatMessage } = useIntl()

  const showAddBamboraModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayAddBamboraModal: true,
      },
    })
  }

  const hideAddBamboraModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayAddBamboraModal: false,
      },
    })
  }

  return (
    <div>
      <Row>
        <Col flex={1}>
          <CardTitle title={formatMessage({ id: 'text.savedCards' })} />
        </Col>

        <Col flex={4}>
          <Row align="middle" justify="center">
            <Col className="mb-2" push={6}>
              <button type="button" className="btn btn-primary" onClick={showAddBamboraModal}>
                <i className="fe fe-plus mr-1" aria-hidden="true" />
                {formatMessage({ id: 'action.changepaymentmethodtobambora' })}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      {cards?.map(card => (
        <Card
          key={card?._id}
          card={card}
          changeDefault={changeDefaultCard}
          changingDefaultCard={changingDefaultCard}
          removeCard={removeCard}
          removingCard={removingCard}
        />
      ))}

      <AddBamboraModal visible={storeinfo?.displayAddBamboraModal} onCancel={hideAddBamboraModal} />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ChangeCard))
