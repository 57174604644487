import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useIntl, injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { getFormattedDate } from 'utils/parser'
import moment from 'moment'
import { Drawer } from 'antd'
import CardTitle from '../../../common/CardTitle'
import CustomCardTitle from '../../../common/CustomCardTitle'
// import AddCardModal from './AddCardModal'
import NextBillingDateModal from './NextBillingDateModal'
import AddGiftCardModal from './AddGiftCard'
import ChangeCard from './ChangeCard'
import ChangeBambora from './ChangeBambora'
import style from './style.module.scss'
import PaymentModalTab from './PaymentModalTab'

const mapStateToProps = ({ dispatch, storeinfo, user }) => ({
  dispatch,
  storeinfo,
  user,
})

const Plan = ({ dispatch, user, storeinfo, intl: { formatMessage } }) => {
  const { formatNumber } = useIntl()
  const settings = useSelector(state => state.settings)
  const userloginedPermission = user?.data?.supportuser?.permission

  const path = window.location.pathname

  const userId = path.replace('/manage/store-info/', '')

  console.log('storeinfo', storeinfo)

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_PLAN_INVENTORY,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const showAddCardModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayAddCardModal: true,
      },
    })
  }

  const hideAddCardModal = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayAddCardModal: false,
      },
    })
  }

  const showChangeCard = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayChangeCardDrawer: true,
      },
    })
  }

  const hideChangeCard = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayChangeCardDrawer: false,
      },
    })
  }

  // const onAddCardSubmit = values => {
  //   const formValues = { ...values }
  //   const { converge } = formValues
  //   const { expiry } = converge
  //   if (expiry) {
  //     const splitExpiry = expiry.split('/')
  //     converge.expiry_month = splitExpiry[0].trim()
  //     converge.expiry_year = splitExpiry[1].trim()
  //     delete converge.expiry
  //   }
  //   dispatch({
  //     type: StoreInfoAction.ADD_CARD,
  //     payload: formValues,
  //   })
  // }

  const changeDefaultCard = id => {
    dispatch({
      type: StoreInfoAction.CHANGE_DEFAULT_CARD,
      payload: {
        id,
      },
    })
  }

  const removeCard = id => {
    dispatch({
      type: StoreInfoAction.REMOVE_CARD,
      payload: {
        id,
      },
    })
  }

  const digital_cards_available = storeinfo?.planinventorydata?.digital_cards?.available

  const digital_cards_issued = storeinfo?.planinventorydata?.digital_cards?.issued

  const physical_cards_available = storeinfo?.planinventorydata?.physical_cards?.available

  const physical_cards_issued = storeinfo?.planinventorydata?.physical_cards?.issued

  const digital_cards_percentage =
    (digital_cards_available / (digital_cards_available + digital_cards_issued)) * 100 || 0

  const getBgColor_digital_cards = () => {
    if (digital_cards_percentage === 0) return ''
    if (digital_cards_percentage < 10) return style.bgDanger
    if (digital_cards_percentage < 50) return style.bgWarning
    return style.bgSuccess
  }

  const getTextColor_digital_cards = () => {
    if (digital_cards_percentage === 0) return ''
    if (digital_cards_percentage < 10) return style.textDanger
    if (digital_cards_percentage < 50) return style.textWarning
    return style.textSuccess
  }

  const physical_cards_percentage =
    (physical_cards_available / (physical_cards_available + physical_cards_issued)) * 100 || 0

  const getBgColor_physical_cards = () => {
    if (physical_cards_percentage === 0) return ''
    if (physical_cards_percentage < 10) return style.bgDanger
    if (physical_cards_percentage < 50) return style.bgWarning
    return style.bgSuccess
  }

  const getTextColor_physical_cards = () => {
    if (physical_cards_percentage === 0) return ''
    if (physical_cards_percentage < 10) return style.textDanger
    if (physical_cards_percentage < 50) return style.textWarning
    return style.textSuccess
  }

  const cost = storeinfo?.subscriptiondata?.subscription_plan?.cost
  const activatedDate = storeinfo?.subscriptiondata?.subscription_plan?.activated_at || null
  const nextBillingDate = storeinfo?.subscriptiondata?.subscription_plan?.next_billing_at || null

  const showChangeNextBillingDate = () => {
    dispatch({
      type: StoreInfoAction.SHOW_NEXT_BILLING_DATE_MODAL,
      payload: {
        nextBillingDateInfo: nextBillingDate,
      },
    })
  }

  const hideNextBillingDate = () => {
    dispatch({
      type: StoreInfoAction.HIDE_NEXT_BILLING_DATE_MODAL,
    })
  }

  const onchangeNextBillingDate = values => {
    dispatch({
      type: StoreInfoAction.CHANGE_NEXT_BILLING_DATE,
      payload: {
        next_billing_at: values,
        business: userId,
      },
    })
  }

  const showGiftCard = () => {
    dispatch({
      type: StoreInfoAction.SHOW_GIFT_CARD_MODAL,
    })
  }

  const hideGiftCard = () => {
    dispatch({
      type: StoreInfoAction.HIDE_GIFT_CARD_MODAL,
    })
  }

  const addGiftCardInfo = values => {
    dispatch({
      type: StoreInfoAction.ADD_GIFT_CARD_COUNT,
      payload: {
        digital_card: Number(values?.digital_card),
        physical_card: Number(values?.physical_card),
        business: userId,
      },
    })
  }

  const CardsArray = storeinfo?.subscriptiondata?.cards
  const cardInfo = CardsArray?.length || 0
  const subscriptionArray = storeinfo?.subscriptiondata
  const physicalcardcount = storeinfo?.businessdata?.free_physical_card_count

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div
              className="card-body"
              style={{ height: '260px' }}
              hidden={subscriptionArray === null}
            >
              <CardTitle
                title={formatMessage({ id: 'text.plan' })}
                action={
                  subscriptionArray?.payment_method === 'bambora' ? (
                    ' '
                  ) : (
                    <>
                      {cardInfo !== 0 ? (
                        <button
                          hidden={userloginedPermission.write_subscription === false}
                          type="button"
                          className="btn btn-light"
                          onClick={showAddCardModal}
                        >
                          <i className="fe fe-plus mr-1" aria-hidden="true" />
                          {formatMessage({ id: 'action.addNewCard' })}
                        </button>
                      ) : (
                        <button
                          hidden={userloginedPermission.write_subscription === false}
                          type="button"
                          className="btn btn-light"
                          onClick={showAddCardModal}
                        >
                          <i className="fe fe-plus mr-1" aria-hidden="true" />
                          {formatMessage({ id: 'action.addPayment' })}
                        </button>
                      )}
                    </>
                  )
                }
              />
              <div className="row">
                <div className="col-4">
                  <div className="mt-2 mb-2 text-gray-6 font-size-14">
                    {formatMessage({ id: 'text.currentPlan' })}:
                  </div>
                </div>
                <div className="col-8">
                  <div className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold">
                    {formatNumber(cost / 100, {
                      style: 'currency',
                      currency: 'CAD',
                    })}
                    /{formatMessage({ id: 'text.month' })}
                  </div>
                </div>
                <div className="col-4">
                  <div className="mt-2 mb-2 text-gray-6 font-size-14">
                    {formatMessage({ id: 'text.activeSince' })}:
                  </div>
                </div>
                <div className="col-8">
                  <div
                    className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold"
                    hidden={activatedDate === null}
                  >
                    {getFormattedDate(activatedDate)}
                  </div>
                  <div
                    className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold"
                    hidden={activatedDate !== null}
                  >
                    -
                  </div>
                </div>
                <div className="col-4">
                  <div className="mt-2 mb-2 text-gray-6 font-size-14">
                    {formatMessage({ id: 'text.nextBillingDate' })}:
                  </div>
                </div>
                <div className="col-8">
                  <div
                    className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold"
                    hidden={nextBillingDate === null}
                  >
                    {/* {getFormattedDate(nextBillingDate)} */}
                    {moment.utc(nextBillingDate).format('MMM D, YYYY')}
                    <button
                      type="button"
                      className="btn btn-link btn-min-padding"
                      onClick={showChangeNextBillingDate}
                      hidden={userloginedPermission.write_subscription === false}
                    >
                      ({formatMessage({ id: 'action.change' })})
                    </button>
                  </div>
                  <div
                    className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold"
                    hidden={nextBillingDate !== null}
                  >
                    -
                  </div>
                </div>

                <div className="col-4">
                  <div className="mt-2 mb-2 text-gray-6 font-size-14">
                    {formatMessage({ id: 'text.paymentMethod' })}:
                  </div>
                </div>
                <div className="col-8">
                  <div className="mt-2 mb-2">
                    <div className="row px-3">
                      {cardInfo === 0 && storeinfo?.subscriptiondata?.payment_method !== 'bambora' && (
                        <>
                          <div className="text-gray-6 font-size-16 font-weight-bold">-</div>
                        </>
                      )}
                      {storeinfo?.subscriptiondata?.payment_method === 'bambora' ? (
                        <div className="mt-2 mb-2 text-gray-6 font-size-16 font-weight-bold">
                          {formatMessage({ id: 'text.bambora' })}
                        </div>
                      ) : (
                        storeinfo?.subscriptiondata?.cards?.map(cardinfo => (
                          <>
                            {cardInfo !== 0 && (
                              <>
                                <div
                                  className="text-gray-6 font-size-16 font-weight-bold"
                                  key={cardinfo?.id}
                                  name={cardinfo?.first_name}
                                  hidden={cardinfo?.is_default !== true}
                                >
                                  Card ({cardinfo?.type} xxxx{cardinfo?.last4})
                                </div>
                              </>
                            )}
                          </>
                        ))
                      )}

                      {storeinfo?.subscriptiondata?.payment_method === 'Card' ||
                      storeinfo?.subscriptiondata?.payment_method === 'bambora' ||
                      storeinfo?.subscriptiondata?.cards.length > 0 ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-link btn-min-padding"
                            onClick={showChangeCard}
                            hidden={userloginedPermission.write_subscription === false}
                          >
                            ({formatMessage({ id: 'action.change' })})
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card-body "
              style={{ height: '260px' }}
              hidden={subscriptionArray !== null}
            >
              <CardTitle title={formatMessage({ id: 'text.plan' })} />
              <div className="row">
                <div className="col-12 ">
                  <div
                    className="mt-2 mb-2 text-gray-6 font-size-14 text-center"
                    style={{ padding: '60px', fontWeight: '600', fontSize: 'x-large' }}
                  >
                    No plan subscribed yet
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Drawer
            title={formatMessage({ id: 'text.addNewPayment' })}
            placement="right"
            destroyOnClose
            visible={storeinfo?.displayAddCardModal}
            onClose={hideAddCardModal}
            width={settings?.isMobileView ? '100%' : '44%'}
          >
            <PaymentModalTab />
            {/* <AddCardModal onSubmit={onAddCardSubmit} loading={storeinfo?.addingCard} /> */}
          </Drawer>

          <Drawer
            title={formatMessage({ id: 'text.ChangeNextBillingDate' })}
            placement="right"
            destroyOnClose
            visible={storeinfo?.displayChangeNextBillingDateModal}
            onClose={hideNextBillingDate}
            width={settings?.isMobileView ? '100%' : '44%'}
          >
            <NextBillingDateModal
              onSubmit={onchangeNextBillingDate}
              loading={storeinfo?.changingBillingDate}
              data={storeinfo?.selectedData}
            />
          </Drawer>
          <Drawer
            title={formatMessage({ id: 'text.AssignGiftCard' })}
            placement="right"
            destroyOnClose
            visible={storeinfo?.displayAddGiftCardModal}
            onClose={hideGiftCard}
            width={settings?.isMobileView ? '100%' : '44%'}
          >
            <AddGiftCardModal onSubmit={addGiftCardInfo} loading={storeinfo?.addingGiftCard} />
          </Drawer>

          <Drawer
            title={formatMessage({ id: 'text.changeCard' })}
            placement="right"
            destroyOnClose
            visible={storeinfo?.displayChangeCardDrawer}
            onClose={hideChangeCard}
            width={settings?.isMobileView ? '100%' : '44%'}
          >
            {storeinfo?.subscriptiondata?.payment_method === 'bambora' ? (
              <ChangeBambora bambora={storeinfo?.subscriptiondata?.bambora} />
            ) : (
              <ChangeCard
                cards={storeinfo?.subscriptiondata?.cards}
                changeDefaultCard={changeDefaultCard}
                changingDefaultCard={storeinfo?.changingDefaultCard}
                removeCard={removeCard}
                removingCard={storeinfo?.removingCard}
              />
            )}
          </Drawer>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body" style={{ height: '260px' }}>
              <CustomCardTitle
                title={formatMessage({ id: 'text.giftCardInventory' })}
                data={physicalcardcount}
                action={
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={showGiftCard}
                    hidden={userloginedPermission.write_subscription === false}
                  >
                    <i className="fe fe-plus mr-1" aria-hidden="true" />
                    {formatMessage({ id: 'action.giftCard' })}
                  </button>
                }
              />
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="p-3 border rounded">
                    <div className="text-gray-6 font-size-18 font-weight-bold text-center">
                      {formatMessage({ id: 'text.digitalcards' })}
                    </div>
                    <hr />
                    <div className="d-flex mb-1">
                      <div className="text-dark font-weight-bold mr-auto">
                        {formatMessage({ id: 'text.available' })}
                      </div>
                      <div className="text-dark font-weight-bold">
                        {formatMessage({ id: 'text.issued' })}
                      </div>
                    </div>
                    <div className="d-flex mb-2 text-gray-6 font-size-24 font-weight-bold">
                      <div className={`${getTextColor_digital_cards()} mr-auto`}>
                        {formatNumber(digital_cards_available)}
                      </div>
                      <div className="font-size-24">{formatNumber(digital_cards_issued)}</div>
                    </div>
                    <div className="progress">
                      <div
                        className={`progress-bar ${getBgColor_digital_cards()}`}
                        style={{
                          width: `${digital_cards_percentage}%`,
                        }}
                        role="progressbar"
                        aria-valuenow={digital_cards_available}
                        aria-valuemin={0}
                        aria-valuemax={digital_cards_available}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-3 border rounded">
                    <div className="text-gray-6 font-size-18 font-weight-bold text-center">
                      {formatMessage({ id: 'text.physicalcards' })}
                    </div>
                    <hr />
                    <div className="d-flex mb-1">
                      <div className="text-dark font-weight-bold mr-auto">
                        {formatMessage({ id: 'text.available' })}
                      </div>
                      <div className="text-dark font-weight-bold">
                        {formatMessage({ id: 'text.issued' })}
                      </div>
                    </div>
                    <div className="d-flex mb-2 text-gray-6 font-size-24 font-weight-bold">
                      <div className={`${getTextColor_physical_cards()} mr-auto`}>
                        {formatNumber(physical_cards_available)}
                      </div>
                      <div className="font-size-24">{formatNumber(physical_cards_issued)}</div>
                    </div>
                    <div className="progress">
                      <div
                        className={`progress-bar ${getBgColor_physical_cards()}`}
                        style={{
                          width: `${physical_cards_percentage}%`,
                        }}
                        role="progressbar"
                        aria-valuenow={physical_cards_issued}
                        aria-valuemin={0}
                        aria-valuemax={physical_cards_available}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Plan))
