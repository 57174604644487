import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Form, notification, message } from 'antd'
import StoreInfoAction from 'redux/storeinfo/actions'
import Loading from 'components/app/common/Loading'
import ColorBoxContainer from 'components/app/common/ColorBoxContainer'
import BackgroundImage from 'components/app/setting/giftcard/BackgroundImage'
import CardTitle from 'components/app/common/CardTitle'
import GiftCardPreview from 'components/app/setting/giftcard/GiftCardPreview'

const mapStateToProps = ({ dispatch, storeinfo, user }) => ({
  dispatch,
  storeinfo,
  user,
})

const GiftCardSettingsFunc = ({ dispatch, storeinfo, user, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const userloginedPermission = user?.data?.supportuser?.permission

  const userId = path.replace('/manage/store-info/', '')

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_GIFT_CARD_SETTINGS,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const [form] = Form.useForm()
  const [textColor, setTextColor] = useState()
  const [backgroundColor, setBackgroundColor] = useState()
  const [backgroundColors, setBackgroundColors] = useState([])
  const [icon, setIcon] = useState()
  const [stripImage, setStripImage] = useState()
  const [gallery, setGallery] = useState([])
  const [library, setLibrary] = useState([])

  const textColorVal = storeinfo?.textColors

  useEffect(() => {
    if (storeinfo.giftcardata && storeinfo.textColors && storeinfo.defaultImages) {
      const { gift_card } = storeinfo.giftcardata
      form.setFieldsValue({
        strip_image: storeinfo?.giftcardata?.gift_card?.strip_image_url,
      })
      const newTextColor = textColorVal.find(
        o => o.color === storeinfo?.giftcardata?.gift_card?.text_color,
      )

      if (newTextColor) {
        setBackgroundColors(newTextColor?.background_colors)
      }
      const { text_color, background_color, icon_url, strip_image_url } = gift_card
      setTextColor(text_color)
      setBackgroundColor(background_color)
      setIcon(icon_url)
      setStripImage(strip_image_url)
      setGallery(storeinfo.defaultImages)
      setLibrary(gift_card.custom_images)
    }
  }, [form, storeinfo.giftcardata, storeinfo.textColors, storeinfo.defaultImages, textColorVal])

  const onValuesChange = changedValues => {
    const { text_color, background_color, strip_image } = changedValues
    if (text_color) {
      setTextColor(text_color)
      const bgColors = storeinfo.textColors.find(o => o.color === text_color).background_colors
      setBackgroundColors(bgColors)
      const newBgColor = bgColors[0].color
      form.setFieldsValue({
        background_color: newBgColor,
      })
      setBackgroundColor(newBgColor)
    }
    if (background_color) setBackgroundColor(background_color)
    if (strip_image?.image_url) setStripImage(strip_image?.image_url)
  }

  const onModalVisibilityChange = value => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayChooseImageModal: value,
      },
    })
  }

  const onImageUpload = payload => {
    dispatch({
      type: StoreInfoAction.UPLOAD_IMAGE,
      payload,
    })
  }

  const onFinish = values => {
    const { text_color, background_color, strip_image } = values
    const payload = { text_color, background_color }
    if (strip_image?.image) payload.strip_image = strip_image.image
    dispatch({
      type: StoreInfoAction.UPDATE,
      payload: {
        gift_card: payload,
        userId,
      },
    })
  }

  const downloadPhysicalCards = () => {
    // if (!businessSubscription || setting.physicalGiftCard?.available === 0 || 0) {
    if (storeinfo?.physicalGiftCard?.available === 0 || 0) {
      message.error(formatMessage({ id: 'error.giftCard.export' }))
    } else {
      const business = storeinfo?.businessdata.code
      const location = storeinfo?.locationdata.code
      const { accessToken } = user
      window.open(
        `${process.env.REACT_APP_API_URL}/gift-card/export/physical?business=${business}&location=${location}&access_token=${accessToken}`,
      )
    }
  }

  // const exportphysicalcard = type => {
  //   notification[type]({
  //     message: 'Export Physical Cards',
  //     description: 'Download gift card option is only available from the merchant portal.',
  //   })
  // }

  const importexternalgiftcards = type => {
    notification[type]({
      message: 'Import External Gift Cards',
      description: 'Import gift card option is only available from the merchant portal.',
    })
  }

  return (
    <div>
      <div className="row">
        <div className="col-12" hidden={storeinfo?.giftcardata}>
          <Loading />
        </div>
        <div className="col-12" hidden={!storeinfo.giftcardata}>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <CardTitle title={formatMessage({ id: 'text.giftCardDesign' })} />
                  <Form
                    className="mt-4"
                    form={form}
                    layout="vertical"
                    onValuesChange={onValuesChange}
                    onFinish={onFinish}
                    hideRequiredMark
                  >
                    <Form.Item
                      name="text_color"
                      label={formatMessage({ id: 'form.label.textColor' })}
                    >
                      <ColorBoxContainer colors={textColorVal || []} />
                    </Form.Item>
                    <Form.Item
                      name="background_color"
                      label={formatMessage({ id: 'form.label.backgroundColor' })}
                    >
                      <ColorBoxContainer colors={backgroundColors} />
                    </Form.Item>
                    <Form.Item name="strip_image" label={formatMessage({ id: 'form.label.image' })}>
                      <BackgroundImage
                        gallery={gallery}
                        library={library}
                        displayChooseImageModal={storeinfo.displayChooseImageModal}
                        onModalVisibilityChange={onModalVisibilityChange}
                        onImageUpload={onImageUpload}
                        uploading={storeinfo.uploading}
                      />
                    </Form.Item>
                    <Form.Item className="text-right">
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        hidden={userloginedPermission?.write_store_info === false}
                      >
                        {formatMessage({ id: 'action.save' })}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <GiftCardPreview
                business={storeinfo?.data1}
                textColor={textColor}
                backgroundColor={backgroundColor}
                icon={icon}
                stripImage={stripImage}
              />
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4">
                    <strong>{formatMessage({ id: 'text.exportPhysicalCards' })}</strong>
                  </h4>
                  <div className="font-size-16 text-secondary">
                    {formatMessage(
                      { id: 'text.physicalCardDetails' },
                      {
                        totalCount: storeinfo?.physicalGiftCard?.total || 0,
                        unusedCount: storeinfo?.physicalGiftCard?.available || 0,
                      },
                    )}
                  </div>
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-light mt-2 mb-2"
                      onClick={downloadPhysicalCards}
                      // onClick={() => exportphysicalcard('info')}
                    >
                      <i className="fe fe-download mr-1" aria-hidden="true" />
                      {formatMessage({ id: 'action.download' })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4">
                    <strong>{formatMessage({ id: 'text.importExternalCards.title' })}</strong>
                  </h4>
                  <div className="font-size-16 text-secondary">
                    {formatMessage({ id: 'text.importExternalCards.description' })}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link mt-2 mb-2 p-0"
                    onClick={() => importexternalgiftcards('info')}
                  >
                    <i className="fe fe-download mr-1" aria-hidden="true" />
                    {formatMessage({ id: 'action.downloadTemplate' })}
                  </button>
                  <div className="text-right">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light mt-2 mb-2"
                        onClick={() => importexternalgiftcards('info')}
                      >
                        <i className="fe fe-upload mr-1" aria-hidden="true" />
                        {formatMessage({ id: 'action.upload' })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(GiftCardSettingsFunc))
