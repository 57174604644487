import React from 'react'
import { injectIntl } from 'react-intl'

const Stat = ({ bgColor, title, amount, currency, intl: { formatNumber } }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="text-gray-6 font-weight-bold">
          <div className="font-size-16">{title}</div>
          <div className="font-size-28" style={{ color: bgColor }}>
            {formatNumber((amount || 0) / 100, {
              style: 'currency',
              currency,
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Stat)
