import actions from './actions'

export const initialState = {
  giftcardsalesdata: null,
  giftcardredeemdata: null,
  newleadsdata: null,
  agentnewleadsdata: null,
  revenuedata: null,
  signupdata: null,
  resellernameList: null,
  image: null,
  searchactive: '',
  searchonboarding: '',
  search: null,
  searchlocation: '',
  storelist: null,
  planinventorydata: '',
  subscriptiondata: null,
  agentSignupPeriod: 'month',
  agentSignupSource: '',
  agentSignupReseller_agent: '',
  giftCardSalesBusiness: '',
  giftCardSalesPeriod: 'day',
  giftCardSalesCardtype: '',
  giftCardRedeemBusiness: '',
  giftCardRedeemPeriod: 'day',
  giftCardRedeemCardtype: '',
  newLeadsPeriod: 'day',
  newLeadsLeadSource: '',
  signupPeriod: 'month',
  signupSource: '',
  signupChannel: '',
  revenuePeriod: 'month',
  revenueSource: '',
  data: '',
  data1: '',
  data2: '',
  data3: '',
  data4: '',
  data5: '',
  data6: '',
  data7: '',
  data8: '',
  data9: '',
  data10: '',
  data11: '',
  data12: '',
  data13: '',
  data14: '',
  data15: '',
  data16: '',
  data17: '',
  data18: '',
  data19: '',
  data20: '',
  data21: '',
  data22: '',
  data23: '',
  data24: '',
  data25: '',
  data26: '',
  invoiceinfo: '',
  viewinvoiceinfo: '',
  locationlist: '',
  deviceslist: '',
  text_color_data: '',
  giftCardListData: null,
  openResetPassword: null,
  giftCardIssuedData: null,
  giftCardRedeemedData: null,
  businessnamelist: null,
  gift_cards: null,
  selectedData: null,
  PeriodValue: null,
  businessId: null,
  InvoiceAmount: null,
  selectedinvoiceNumber: null,
  viewinvoiceactivity: null,
  invoiceId: null,
  textColors: null,
  defaultImages: null,
  physicalGiftCard: null,
  importTemplateUrl: null,
  nextBillingDateInfo: null,
  transactions: null,
  transactionTypes: null,
  currency: null,
  loading: false,
  updating: false,
  resetting: false,
  removing: false,
  sending: false,
  addingGiftCard: false,
  displayChooseImageModal: false,
  displayInvoiceDrawer: false,
  displayAddCardModal: false,
  ResendInvoiceModal: false,
  RetryPaymentModal: false,
  RefundInvoiceModal: false,
  InvoiceActivity: false,
  uploading: false,
  removingCard: false,
  addingCard: false,
  addingBambora: false,
  changingBillingDate: false,
  displayChangeCardDrawer: false,
  changingDefaultCard: false,
  displayChangeNextBillingDateModal: false,
  displayAddGiftCardModal: false,
  displayTransactionHistoryDrawer: false,
  displaySendDigitalCard: false,
  displayResetPasswordModal: false,
  displayRestaurantAddDrawer: false,
  storeRegistering: false,
  newlyAdded: null,
  displayAddBamboraModal: false,
  displayChangeAddCardModal: false,
  displayEditBambora: false,
}

export default function storeinfoReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_STORE_INFO:
      return {
        ...state,
        ...action.payload,
        updating: false,
      }

    case actions.UPDATE_IMAGE:
      return {
        ...state,
        giftcardata: {
          ...state.giftcardata,
          gift_card: {
            ...state.giftcardata.gift_card,
            strip_image: action.payload.image.image,
            strip_image_url: action.payload.image.image_url,
            custom_images: [...state.giftcardata.gift_card.custom_images, action.payload.image],
          },
        },
        displayChooseImageModal: false,
      }
    case actions.SHOW_RESEND_INVOICE_MODAL:
      return { ...state, selectedData: action.payload, ResendInvoiceModal: true }
    case actions.HIDE_RESEND_INVOICE_MODAL:
      return { ...state, ResendInvoiceModal: false }
    case actions.SHOW_INVOICE_ACTIVITY_MODAL:
      return { ...state, selectedData: action.payload, InvoiceActivity: true }
    case actions.HIDE_INVOICE_ACTIVITY_MODAL:
      return { ...state, InvoiceActivity: false }
    case actions.SHOW_RETRY_PAYMENT_MODAL:
      return { ...state, selectedData: action.payload, RetryPaymentModal: true }
    case actions.HIDE_RETRY_PAYMENT_MODAL:
      return { ...state, RetryPaymentModal: false }
    case actions.SHOW_REFUND_INVOICE_MODAL:
      return { ...state, selectedData: action.payload, RefundInvoiceModal: true }
    case actions.HIDE_REFUND_INVOICE_MODAL:
      return { ...state, RefundInvoiceModal: false }

    case actions.SHOW_NEXT_BILLING_DATE_MODAL:
      return { ...state, selectedData: action.payload, displayChangeNextBillingDateModal: true }
    case actions.HIDE_NEXT_BILLING_DATE_MODAL:
      return { ...state, selectedData: null, displayChangeNextBillingDateModal: false }

    case actions.SHOW_GIFT_CARD_MODAL:
      return { ...state, selectedData: action.payload, displayAddGiftCardModal: true }
    case actions.HIDE_GIFT_CARD_MODAL:
      return { ...state, selectedData: null, displayAddGiftCardModal: false }
    case actions.SHOW_SEND_EMAIL:
      return { ...state, selectedData: action.payload, displaySendDigitalCard: true }
    case actions.HIDE_SEND_EMAIL:
      return { ...state, selectedData: null, displaySendDigitalCard: false }

    case actions.SHOW_RESET_PASSWORD:
      return { ...state, selectedData: action.payload, displayResetPasswordModal: true }
    case actions.HIDE_RESET_PASSWORD:
      return { ...state, selectedData: null, displayResetPasswordModal: false }

    case actions.SHOW_RESTAURANTS:
      return { ...state, selectedData: action.payload, displayRestaurantAddDrawer: true }
    case actions.HIDE_RESTAURANTS:
      return { ...state, selectedData: null, displayRestaurantAddDrawer: false }

    default:
      return state
  }
}
