export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboard',
      key: 'dashboardcategory',
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      category: true,
      title: 'Manage',
      key: 'manage',
    },
    {
      title: 'Manage Stores',
      key: 'manage-stores',
      icon: 'fe fe-shopping-bag',
      url: '/manage/stores',
      urls: [
        '/manage/store-info',
        '/manage/stores?tab=active',
        '/manage/stores?tab=inactive',
        '/manage/stores?tab=demo',
      ],
    },
    {
      title: 'Manage Leads',
      key: 'manage-leads',
      icon: 'fe fe-list',
      url: '/manage/leads',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user-check',
      url: '/manage/users',
    },
    {
      title: 'Manage Invoices',
      key: 'manage-invoices',
      icon: 'fe fe-book',
      url: '/manage-invoices',
      extra_urls_five: '/manage-invoices?tab=paid-invoice',
      extra_urls_six: '/manage-invoices?tab=not-paid-invoice',
      extra_urls_seven: '/manage-invoices?tab=refund-invoice',
    },
    {
      title: 'Manage Inventory',
      key: 'manage-inventory',
      icon: 'fe fe-settings',
      url: '/manage/manage-inventory',
    },
    {
      title: 'Hardware Purchase Orders',
      key: 'hardware-purchase',
      icon: 'fe fe-settings',
      url: '/manage/hardware-purchase',
    },
    {
      title: 'Payment Logs',
      key: 'payment-logs',
      icon: 'fe fe-list',
      url: '/manage/payment-logs',
    },
    {
      title: 'Migration',
      key: 'manage-migration',
      icon: 'fe fe-settings',
      url: '/manage/manage-migration',
    },
  ]
}
