const actions = {
  SET_STATE: 'inventory/SET_STATE',

  LOAD_ALL: 'inventory/LOAD_ALL',
  ADD_INVENTORY: 'inventory/ADD_INVENTORY',
  HIDE_INVENTORY: 'inventory/HIDE_INVENTORY',
  ADD_ITEM: 'inventory/ADD_ITEM',
  UPDATE_iTEM: 'inventory/UPDATE_iTEM',
  EDIT_SHOW_INVENTORY: 'inventory/EDIT_SHOW_INVENTORY',
  HIDE_SHOW_INVENTORY: 'inventory/HIDE_SHOW_INVENTORY',
  GET_SINGLE_INVENTORY: 'inventory/GET_SINGLE_INVENTORY',
  SHOW_STOCK: 'inventory/SHOW_STOCK',
  HIDE_STOCK: 'inventory/HIDE_STOCK',
  UPDATE_STOCK: 'inventory/UPDATE_STOCK',
  LOAD_ACTIVE_INVENTORY: 'inventory/LOAD_ACTIVE_INVENTORY',
}

export default actions
