import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getpaid,
  getnotpaid,
  getrefund,
  resendinvoice,
  viewinvoice,
  retrypayment,
  viewinvoiceactivity,
  refundinvoice,
  downloadpdf,
  changepaymentstatus,
} from 'api/invoice'
import actions from './actions'

export function* GET_PAID() {
  const { response } = yield call(getpaid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        getpaiddata: response.data.invoices,
      },
    })
  }
}
export function* GET_NOTPAID() {
  const { response } = yield call(getnotpaid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        getnotpaiddata: response.data.invoices,
      },
    })
  }
}

export function* GET_REFUND() {
  const { response } = yield call(getrefund)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        getrefunddata: response.data.invoices,
      },
    })
  }
}

export function* RESEND_INVOICE(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(resendinvoice, invoiceIdVal)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ResendInvoiceModal: false,
        loading: false,
      },
    })
  }
}

export function* VIEW_INVOICES(payload) {
  const invoiceid = payload.payload.invoice_id
  const { response } = yield call(viewinvoice, invoiceid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        viewinvoiceinfo: response.data.invoice,
        displayInvoiceDrawer: true,
      },
    })
  }
}

export function* RETRY_PAYMENT(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(retrypayment, invoiceIdVal)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        RetryPaymentModal: false,
        loading: false,
      },
    })
  }
  yield call(GET_PAID)
}

export function* DOWNLOAD_INVOICE(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(downloadpdf, invoiceIdVal)
  if (response) {
    console.log('res', response)
  }
}

export function* REFUND_INVOICE(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  const amountVal = payload.payload.amount
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(refundinvoice, invoiceIdVal, amountVal)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        RefundInvoiceModal: false,
        loading: false,
      },
    })
  }
  yield call(GET_REFUND)
}

export function* LOAD_INVOICE_ACTIVITY(payload) {
  const invoiceid = payload?.payload?.invoice_id
  const invoiceNumberData = payload?.payload?.invoiceNumber
  const { response } = yield call(viewinvoiceactivity, invoiceid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        viewinvoiceactivity: response.data.invoice?.activity,
        InvoiceActivity: true,
        selectedinvoiceNumber: invoiceNumberData,
      },
    })
  }
}

export function* CHANGE_PAYMENT_STATUS({ payload }) {
  console.log('saga invoice payload', payload)
  const { invoiceId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      paymentStatusloading: true,
    },
  })
  const { response } = yield call(changepaymentstatus, invoiceId, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      paymentStatusloading: false,
    },
  })
  if (response) {
    console.log('saga response data', response)
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayInvoiceDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_PAID, GET_PAID),
    takeLatest(actions.GET_NOTPAID, GET_NOTPAID),
    takeLatest(actions.GET_REFUND, GET_REFUND),
    takeLatest(actions.RESEND_INVOICE, RESEND_INVOICE),
    takeLatest(actions.VIEW_INVOICES, VIEW_INVOICES),
    takeLatest(actions.RETRY_PAYMENT, RETRY_PAYMENT),
    takeLatest(actions.REFUND_INVOICE, REFUND_INVOICE),
    takeLatest(actions.LOAD_INVOICE_ACTIVITY, LOAD_INVOICE_ACTIVITY),
    takeLatest(actions.DOWNLOAD_INVOICE, DOWNLOAD_INVOICE),
    takeLatest(actions.CHANGE_PAYMENT_STATUS, CHANGE_PAYMENT_STATUS),
  ])
}
