import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadAll,
  loadbusinesslist,
  loadbusinessinfo,
  getcardlist,
  createorder,
  viewInvoice,
  changestatus,
  changestatustracking,
  load_taxes,
} from 'api/hardware'
import { addCard } from 'api/storeinfo'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { hardwareorders } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        orderList: hardwareorders,
      },
    })
  }
}

export function* LOAD_TAXES() {
  const { response } = yield call(load_taxes)
  if (response) {
    const { countries } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        TaxList: countries,
      },
    })
  }
}

export function* VIEW_PURCHASE_ORDER({ payload }) {
  const { response } = yield call(viewInvoice, payload)
  if (response) {
    const { hardwareorder } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoiceData: hardwareorder,
      },
    })
  }
}

export function* GET_CARDS({ payload }) {
  const { response } = yield call(getcardlist, payload)
  if (response) {
    const { cards } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        cardList: cards,
      },
    })
  }
}

export function* CHANGE_STATUS({ payload }) {
  const orderIdValue = payload?.orderId
  const orderStatusValue = payload?.order_status
  const { response } = yield call(changestatus, orderIdValue, orderStatusValue)
  if (response) {
    yield call(LOAD_ALL)
  }
}

export function* CHANGE_STATUS_TRACKING({ payload }) {
  const orderIdValue = payload?.orderId
  const orderStatusValue = payload?.order_status
  const trackingNumberValue = payload?.tracking_number
  const { response } = yield call(
    changestatustracking,
    orderIdValue,
    orderStatusValue,
    trackingNumberValue,
  )
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayTrackingModal: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* LOAD_BUSINESS_LIST() {
  const { response } = yield call(loadbusinesslist)
  if (response) {
    const { businesses } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        businessList: businesses,
      },
    })
  }
}

export function* GET_BUSINESS_iNFO({ payload }) {
  const { response } = yield call(loadbusinessinfo, payload)
  if (response) {
    const { business, setting } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        businessInfo: business,
        settingInfo: setting,
      },
    })
  }
}

export function* CREATE_ORDER({ payload }) {
  const { response } = yield call(createorder, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  if (response) {
    const { hardwareorders } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddNewOrderDrawer: false,
        creating: false,
        hardwareOrdersList: hardwareorders,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* ADD_CARD({ payload, selectedBusinessId }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: true,
    },
  })
  const { response } = yield call(addCard, payload, selectedBusinessId)
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddCardModal: false,
      },
    })
    yield call(GET_CARDS, { payload: selectedBusinessId })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_TAXES, LOAD_TAXES),
    takeLatest(actions.LOAD_BUSINESS_LIST, LOAD_BUSINESS_LIST),
    takeLatest(actions.GET_BUSINESS_iNFO, GET_BUSINESS_iNFO),
    takeLatest(actions.GET_CARDS, GET_CARDS),
    takeLatest(actions.ADD_CARD, ADD_CARD),
    takeLatest(actions.CHANGE_STATUS, CHANGE_STATUS),
    takeLatest(actions.CREATE_ORDER, CREATE_ORDER),
    takeLatest(actions.VIEW_PURCHASE_ORDER, VIEW_PURCHASE_ORDER),
    takeLatest(actions.CHANGE_STATUS_TRACKING, CHANGE_STATUS_TRACKING),
    // takeEvery(actions.LOAD_FILTER_LIST, LOAD_FILTER_LIST),
  ])
}
