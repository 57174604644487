import React from 'react'
import { useIntl } from 'react-intl'
import { getFormattedDate } from 'utils/parser'
import { Button, Popconfirm, Tag } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

const Card = ({ card, changeDefault, changingDefaultCard, removeCard, removingCard }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-grow-1">
              <span className="text-gray-6 font-size-16 font-weight-bold">
                {`${card.first_name} ${card.last_name} ${card.type}-xxxx${card.last4}`}
              </span>
              {card.is_default && (
                <Tag className="ml-2" color="blue">
                  {formatMessage({ id: 'text.default' })}
                </Tag>
              )}
              <div className="font-size-14">
                {formatMessage(
                  { id: 'text.expiresOn' },
                  {
                    month: card.expiry_month,
                    year: card.expiry_year,
                  },
                )}{' '}
                &bull;{' '}
                {formatMessage(
                  { id: 'text.addedOn' },
                  {
                    date: getFormattedDate(card.added_at),
                  },
                )}
              </div>
            </div>
            {!card.is_default && (
              <div className="row">
                <Popconfirm
                  placement="topRight"
                  icon={null}
                  title={formatMessage({ id: 'text.changeDefaultCard.content' })}
                  okText={formatMessage({ id: 'action.yes' })}
                  cancelText={formatMessage({ id: 'action.no' })}
                  onConfirm={() => changeDefault(card._id)}
                  okButtonProps={{ loading: changingDefaultCard }}
                >
                  <Button type="default" size="middle">
                    {formatMessage({ id: 'action.setDefault' })}
                  </Button>
                </Popconfirm>
                <div className="m-2" />
                <Popconfirm
                  placement="topRight"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title={formatMessage({ id: 'text.removeCard.content' })}
                  okText={formatMessage({ id: 'action.yes' })}
                  cancelText={formatMessage({ id: 'action.no' })}
                  onConfirm={() => removeCard(card._id)}
                  okButtonProps={{ loading: removingCard }}
                >
                  <Button type="primary" size="middle" icon={<DeleteOutlined />} danger />
                </Popconfirm>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
