import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Alert, Button, Form, Input, Select, Space, message, Radio, Modal, Switch } from 'antd'
import MaskedInput from 'antd-mask-input'
import CardTitle from 'components/app/common/CardTitle'
import StoreInfoAction from 'redux/storeinfo/actions'
import countryAction from 'redux/country/actions'
import ColorPicker from 'components/app/common/ColorPicker'
import { phoneNumberE164, phoneNumberNational } from 'utils/parser'
import ImagePicker from 'components/app/common/ImagePicker'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import Loading from 'components/app/common/Loading'
import ResetPassword from 'components/app/common/ResetPassword'
import { LeadTypes } from 'utils/data'

const { Option, OptGroup } = Select

const { confirm } = Modal

const mapStateToProps = ({ dispatch, storeinfo, country, user }) => ({
  dispatch,
  storeinfo,
  country,
  user,
})

const StoreSettings = ({ dispatch, storeinfo, country, user, intl: { formatMessage } }) => {
  const userloginedPermission = user?.data?.supportuser?.permission
  const storeUrl = `${process.env.REACT_APP_WEBSITE_URL}/${storeinfo?.businessdata?.slug}/main`
  const path = window.location.pathname

  const [ActiveVal, setActiveVal] = useState(false)
  const [InactiveVal, setInactiveVal] = useState(false)
  const [DemoVal, setDemoVal] = useState(false)
  const [OnboardingVal, setOnboardingVal] = useState(false)

  const OnboardingStatus = [
    { label: 'Onboarding', value: 'Onboarding', disabled: OnboardingVal },
    { label: 'Active', value: 'Active', disabled: ActiveVal },
  ]

  const ActiveStatus = [
    { label: 'Active', value: 'Active', disabled: ActiveVal },
    { label: 'Inactive', value: 'Inactive', disabled: InactiveVal },
    { label: 'Demo', value: 'Demo', disabled: DemoVal },
  ]

  const InactiveStatus = [
    { label: 'Active', value: 'Active', disabled: ActiveVal },
    { label: 'Inactive', value: 'Inactive', disabled: InactiveVal },
    { label: 'Demo', value: 'Demo', disabled: DemoVal },
  ]

  const DemoStatus = [
    { label: 'Active', value: 'Active', disabled: ActiveVal },
    { label: 'Inactive', value: 'Inactive', disabled: InactiveVal },
    { label: 'Demo', value: 'Demo', disabled: DemoVal },
  ]

  const userId = path.replace('/manage/store-info/', '')

  const onUrlCopied = () => {
    message.success(formatMessage({ id: 'message.copyClipboard' }))
  }

  const onViewStoreClick = () => {
    window.open(storeUrl)
  }

  const [form] = Form.useForm()

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_STORE,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: countryAction.LOAD,
    })
  }, [dispatch])

  useEffect(() => {
    if (storeinfo) {
      form.setFieldsValue({
        store_name: storeinfo?.data1,
        address: storeinfo?.data2,
        city: storeinfo?.data3,
        state: storeinfo?.data4,
        countrydata: storeinfo?.data5,
        postal_code: storeinfo?.data6,
        contact_name: storeinfo.data7,
        email: storeinfo?.data8,
        contact_phone_number: phoneNumberNational(storeinfo?.data9),
        contact_alt_phone_number: phoneNumberNational(storeinfo?.data10),
        logo: storeinfo?.data11,
        portal_theme_color: storeinfo?.data12,
        portal_background_image: storeinfo?.data13,
        portal_preferred_language: storeinfo?.data14,
        account_type: storeinfo?.data15,
        is_custom_redeem: storeinfo?.data26,
        type: storeinfo?.data18,
        timezone: storeinfo?.data19,
        is_online_receipt_enabled: storeinfo?.data23,
        is_smart_bundle: storeinfo?.data24,
        is_api_integrated: storeinfo?.data25,
        signup_source: storeinfo?.data20,
      })
      if (storeinfo?.data15 === 'Onboarding') {
        setOnboardingVal(false)
        setActiveVal(false)
        setInactiveVal(true)
        setDemoVal(true)
      } else if (
        storeinfo?.data15 === 'Active' ||
        storeinfo?.data15 === 'Inactive' ||
        storeinfo?.data15 === 'Demo'
      ) {
        setOnboardingVal(true)
        setInactiveVal(false)
        setDemoVal(false)
      }
    } // eslint-disable-next-line
  }, [form, storeinfo])

  const onValuesChange = changedValues => {
    const { state: provinceVal } = changedValues
    if (provinceVal) {
      const countryvalue = country.data
      const countrydata = countryvalue.find(cc =>
        cc.states.some(state => state.name === provinceVal),
      )
      form.setFieldsValue({
        countrydata: countrydata?.name,
      })
    }
  }

  const onFinish = values => {
    const {
      store_name,
      address,
      city,
      state,
      countrydata,
      postal_code,
      contact_name,
      email,
      contact_phone_number,
      contact_alt_phone_number,
      logo,
      portal_background_image,
      portal_theme_color,
      portal_preferred_language,
      account_type,
      is_custom_redeem,
      type,
      timezone,
      is_online_receipt_enabled,
      is_api_integrated,
      is_smart_bundle,
    } = values
    const formData = new FormData()
    formData.append('store_name', store_name)
    formData.append('address', address)
    formData.append('city', city)
    formData.append('state', state)
    formData.append('country', countrydata)
    formData.append('postal_code', postal_code)
    formData.append('contact_name', contact_name)
    formData.append('email', email)
    formData.append('contact_phone_number', phoneNumberE164(contact_phone_number))
    formData.append('contact_alt_phone_number', phoneNumberE164(contact_alt_phone_number))
    if (logo?.image_file) formData.append('logo', logo.image_file)
    if (portal_background_image?.image_file)
      formData.append('portal_background_image', portal_background_image.image_file)
    formData.append('portal_theme_color', portal_theme_color)
    formData.append('portal_preferred_language', portal_preferred_language)
    formData.append('account_type', account_type)
    formData.append('is_custom_redeem', is_custom_redeem)
    formData.append('type', type)
    formData.append('timezone', timezone)
    formData.append('is_online_receipt_enabled', is_online_receipt_enabled)
    formData.append('is_api_integrated', is_api_integrated)
    formData.append('is_smart_bundle', is_smart_bundle)
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        data15: account_type,
        data23: is_online_receipt_enabled,
        data24: is_smart_bundle,
        data25: is_api_integrated,
        data26: is_custom_redeem,
      },
    })
    dispatch({
      type: StoreInfoAction.UPDATE_STORE,
      payload: {
        UseridSel: userId,
        updatedData: formData,
      },
    })
  }

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  const toInputUppercase = e => {
    e.target.value = e.target.value.toUpperCase()
  }

  const showResetPassword = () => {
    dispatch({
      type: StoreInfoAction.SHOW_RESET_PASSWORD,
      payload: {
        openResetPassword: true,
      },
    })
  }

  const hideResetPassword = () => {
    dispatch({
      type: StoreInfoAction.HIDE_RESET_PASSWORD,
    })
  }

  const callresetPassword = values => {
    dispatch({
      type: StoreInfoAction.RESET_PASSWORD,
      payload: {
        new_password: values?.password,
        user_id: user?.data?._id,
        business_id: userId,
      },
    })
  }

  const resendWelcomeEmail = values => {
    dispatch({
      type: StoreInfoAction.RESENDEMAIL,
      payload: values,
    })
  }

  const confirmemail = value => {
    confirm({
      title: 'Are you sure you want to resend activation key?',
      onOk: () => {
        resendWelcomeEmail(value)
      },
      okText: 'Ok',
      cancelText: 'Cancel',
    })
  }

  return (
    <>
      <div>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div className="row">
            <div className="col-12" hidden={storeinfo.data1}>
              <Loading />
            </div>
          </div>
          <div className="row" hidden={!storeinfo.data1}>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mb-2">
                    <h4 className="flex-grow-1">
                      <strong>{formatMessage({ id: 'text.storeDetails' })}</strong>
                    </h4>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        name="store_name"
                        label={formatMessage({ id: 'form.label.storeName' })}
                      >
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.storeName' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="type"
                        label={formatMessage({ id: 'form.label.type' })}
                        rules={[
                          { required: true, message: formatMessage({ id: 'form.error.required' }) },
                        ]}
                      >
                        <Select
                          placeholder={formatMessage({ id: 'form.placeholder.type' })}
                          size="large"
                          showSearch
                          selected
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={userloginedPermission?.write_store_info === false}
                        >
                          {LeadTypes.filter(o => !o.hidden).map(o => {
                            return (
                              <Option key={o.key} value={o.key}>
                                {o.name}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="address" label={formatMessage({ id: 'form.label.address' })}>
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.address' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <Form.Item name="city" label={formatMessage({ id: 'form.label.city' })}>
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.city' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="state" label={formatMessage({ id: 'form.label.province' })}>
                        <Select
                          selected
                          placeholder={formatMessage({ id: 'form.placeholder.province' })}
                          size="large"
                          showSearch
                          disabled={userloginedPermission?.write_store_info === false}
                        >
                          {country.data?.map(co => (
                            <OptGroup key={co._id} value={co._id} label={co.name}>
                              {co.states?.map(co2 => (
                                <Option key={co2._id} value={co2.name}>
                                  {co2.name}
                                </Option>
                              ))}
                            </OptGroup>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <Form.Item
                        name="countrydata"
                        label={formatMessage({ id: 'form.label.country' })}
                      >
                        <Select
                          placeholder={formatMessage({ id: 'form.placeholder.country' })}
                          size="large"
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          notFoundContent={formatMessage({ id: 'form.error.empty.select' })}
                          disabled
                        >
                          {country.data?.map(o => (
                            <Option key={o._id} value={o.name}>
                              {o.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="postal_code"
                        label={formatMessage({ id: 'form.label.postalCode' })}
                      >
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.postalCode' })}
                          size="large"
                          maxLength={8}
                          onInput={toInputUppercase}
                          style={{ textTransform: 'uppercase' }}
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="signup_source"
                        label={formatMessage({ id: 'form.label.signup_source' })}
                      >
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.signup_source' })}
                          size="large"
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="timezone"
                        label={formatMessage({ id: 'form.label.timezone' })}
                      >
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.timezone' })}
                          size="large"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <CardTitle
                    title={formatMessage({ id: 'text.contactDetails' })}
                    action={
                      <>
                        <div>
                          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                          {/* <Button
                            type="primary"
                            htmlType="submit"
                            size="medium"
                            onClick={showResetPassword}
                          >
                            {formatMessage({ id: 'action.resetPassword' })}
                          </Button> */}
                          <a
                            href="javascript:void(0)"
                            onClick={showResetPassword}
                            className="kit__utils__link font-size-14 pull-right"
                          >
                            {formatMessage({ id: 'action.resetPassword' })}
                          </a>
                        </div>
                      </>
                      // <Space>
                      //   <Button type="link" onClick={showResetPassword} danger>
                      //     {formatMessage({ id: 'action.resetPassword' })}
                      //   </Button>
                      // </Space>
                    }
                  />
                  {/* <h4 className="mb-2">
                    <strong>{formatMessage({ id: 'text.contactDetails' })}</strong>
                  </h4> */}
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        name="contact_name"
                        label={formatMessage({ id: 'form.label.contactName' })}
                      >
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.contactName' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <div className="ant-row ant-form-item">
                        <div
                          className="ant-col ant-form-item-label w-100"
                          style={{ position: 'relative' }}
                        >
                          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                          <label
                            htmlFor="email"
                            className="ant-form-item-required ant-form-item-required-mark-optional"
                            title={formatMessage({ id: 'form.label.email' })}
                          >
                            {formatMessage({ id: 'form.label.email' })}
                          </label>
                          <a
                            href="javascript:void(0)"
                            onClick={() => confirmemail(storeinfo?.businessdata?._id)}
                            className="kit__utils__link font-size-14 pull-right"
                          >
                            {formatMessage({ id: 'action.resendActivationKey' })}
                          </a>
                        </div>
                        <Form.Item name="email">
                          <Input
                            placeholder={formatMessage({ id: 'form.placeholder.email' })}
                            size="large"
                            maxLength={32}
                            onInput={toInputLowercase}
                            readOnly
                            disabled
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Form.Item
                        name="contact_phone_number"
                        label={formatMessage({ id: 'form.label.phone' })}
                        rules={[
                          { required: true, message: formatMessage({ id: 'form.error.required' }) },
                        ]}
                      >
                        <MaskedInput
                          mask="(111) 111-1111"
                          placeholder={formatMessage({ id: 'form.placeholder.phone' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="contact_alt_phone_number"
                        label={formatMessage({ id: 'form.label.altPhone' })}
                      >
                        <MaskedInput
                          mask="(111) 111-1111"
                          placeholder={formatMessage({ id: 'form.placeholder.altPhone' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-2">
                    <strong>{formatMessage({ id: 'text.logo' })}</strong>
                  </h4>
                  <div className="mb-4" hidden>
                    <Alert message={formatMessage({ id: 'note.logoSize' })} type="info" showIcon />
                  </div>
                  <div className="text-center">
                    <Form.Item name="logo">
                      <ImagePicker />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <CardTitle
                    title={formatMessage({ id: 'text.giftCardStoreDesign' })}
                    action={
                      <Space>
                        <CopyToClipboard text={storeUrl} onCopy={onUrlCopied}>
                          <button type="button" className="btn btn-light">
                            <i className="fe fe-clipboard mr-1" aria-hidden="true" />
                            {formatMessage({ id: 'action.copyStoreUrl' })}
                          </button>
                        </CopyToClipboard>
                        <button type="button" className="btn btn-light" onClick={onViewStoreClick}>
                          <i className="fe fe-globe mr-1" aria-hidden="true" />
                          {formatMessage({ id: 'action.viewStore' })}
                        </button>
                      </Space>
                    }
                  />
                  <Form.Item
                    name="portal_background_image"
                    label={formatMessage({ id: 'form.label.backgroundImage' })}
                  >
                    <ImagePicker />
                  </Form.Item>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        name="portal_theme_color"
                        label={formatMessage({ id: 'form.label.themeColor' })}
                      >
                        <ColorPicker />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="portal_preferred_language"
                        label={formatMessage({ id: 'form.label.language.preferred' })}
                      >
                        <Select
                          placeholder={formatMessage({ id: 'form.placeholder.language' })}
                          size="large"
                          disabled={userloginedPermission?.write_store_info === false}
                        >
                          <Option value="en">{formatMessage({ id: 'form.select.english' })}</Option>
                          <Option value="fr">{formatMessage({ id: 'form.select.french' })}</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mb-2">
                        <strong>{formatMessage({ id: 'text.ApiIntegration' })}</strong>
                      </h4>
                      <Form.Item
                        name="is_smart_bundle"
                        label={formatMessage({ id: 'text.CPOSIntegrations' })}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={formatMessage({ id: 'action.yes' })}
                          unCheckedChildren={formatMessage({ id: 'action.no' })}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6 mt-4">
                      <Form.Item
                        name="is_api_integrated"
                        label={formatMessage({ id: 'text.Integration' })}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={formatMessage({ id: 'action.yes' })}
                          unCheckedChildren={formatMessage({ id: 'action.no' })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mb-2">
                        <strong>{formatMessage({ id: 'text.Settings' })}</strong>
                      </h4>
                      <Form.Item
                        name="is_online_receipt_enabled"
                        label={formatMessage({ id: 'text.OnlineStoreNotifications' })}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={formatMessage({ id: 'action.yes' })}
                          unCheckedChildren={formatMessage({ id: 'action.no' })}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6 mt-4">
                      <Form.Item
                        name="is_custom_redeem"
                        label={formatMessage({ id: 'form.storedetail.label.customizedredeem' })}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={formatMessage({ id: 'action.Show' })}
                          unCheckedChildren={formatMessage({ id: 'action.Hide' })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      {userloginedPermission?.write_store_info === true && (
                        <div className="col-md-12">
                          {storeinfo?.data15 === 'Onboarding' && (
                            <>
                              <Form.Item
                                name="account_type"
                                label={formatMessage({ id: 'form.storedetail.label.status' })}
                              >
                                <Radio.Group
                                  options={OnboardingStatus}
                                  optionType="button"
                                  buttonStyle="solid"
                                />
                              </Form.Item>
                            </>
                          )}
                          {storeinfo?.data15 === 'Active' && (
                            <>
                              <Form.Item
                                name="account_type"
                                label={formatMessage({ id: 'form.storedetail.label.status' })}
                              >
                                <Radio.Group
                                  options={ActiveStatus}
                                  optionType="button"
                                  buttonStyle="solid"
                                />
                              </Form.Item>
                            </>
                          )}
                          {storeinfo?.data15 === 'Inactive' && (
                            <>
                              <Form.Item
                                name="account_type"
                                label={formatMessage({ id: 'form.storedetail.label.status' })}
                              >
                                <Radio.Group
                                  options={InactiveStatus}
                                  optionType="button"
                                  buttonStyle="solid"
                                />
                              </Form.Item>
                            </>
                          )}
                          {storeinfo?.data15 === 'Demo' && (
                            <>
                              <Form.Item
                                name="account_type"
                                label={formatMessage({ id: 'form.storedetail.label.status' })}
                              >
                                <Radio.Group
                                  options={DemoStatus}
                                  optionType="button"
                                  buttonStyle="solid"
                                />
                              </Form.Item>
                            </>
                          )}
                        </div>
                      )}
                      {userloginedPermission?.write_store_info === false && (
                        <div className="col-md-12">
                          <Form.Item
                            name="account_type"
                            label={formatMessage({ id: 'form.storedetail.label.status' })}
                          >
                            <Input size="large" disabled />
                          </Form.Item>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 mt-5">
                      {userloginedPermission?.write_store_info === true && (
                        <div className="row">
                          <div className="col-md-12 text-right">
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="large"
                              loading={storeinfo.updating}
                            >
                              {formatMessage({ id: 'action.update' })}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*   <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mb-2">
                        <strong>{formatMessage({ id: 'text.Integration' })}</strong>
                      </h4>
                      <Form.Item
                        name="is_api_integrated"
                        // label={formatMessage({ id: 'form.label.openApi' })}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={formatMessage({ id: 'action.yes' })}
                          unCheckedChildren={formatMessage({ id: 'action.no' })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card">
                <div className="card-body">
                  <div className="row">
                    {userloginedPermission?.write_store_info === true && (
                      <div className="col-md-6">
                        {storeinfo?.data15 === 'Onboarding' && (
                          <>
                            <Form.Item
                              name="account_type"
                              label={formatMessage({ id: 'form.storedetail.label.status' })}
                            >
                              <Radio.Group
                                options={OnboardingStatus}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </>
                        )}
                        {storeinfo?.data15 === 'Active' && (
                          <>
                            <Form.Item
                              name="account_type"
                              label={formatMessage({ id: 'form.storedetail.label.status' })}
                            >
                              <Radio.Group
                                options={ActiveStatus}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </>
                        )}
                        {storeinfo?.data15 === 'Inactive' && (
                          <>
                            <Form.Item
                              name="account_type"
                              label={formatMessage({ id: 'form.storedetail.label.status' })}
                            >
                              <Radio.Group
                                options={InactiveStatus}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </>
                        )}
                        {storeinfo?.data15 === 'Demo' && (
                          <>
                            <Form.Item
                              name="account_type"
                              label={formatMessage({ id: 'form.storedetail.label.status' })}
                            >
                              <Radio.Group
                                options={DemoStatus}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </>
                        )}
                      </div>
                    )}
                    {userloginedPermission?.write_store_info === false && (
                      <div className="col-md-4">
                        <Form.Item
                          name="account_type"
                          label={formatMessage({ id: 'form.storedetail.label.status' })}
                        >
                          <Input size="large" disabled />
                        </Form.Item>
                      </div>
                    )}

                    {userloginedPermission?.write_store_info === true && (
                      <div className="col-md-6">
                        {storeinfo?.data15 === 'Onboarding' && (
                          <>
                            <Form.Item
                              name="customized_redeem"
                              label={formatMessage({
                                id: 'form.storedetail.label.customizedredeem',
                              })}
                            >
                              <Radio.Group
                                options={ShowRedeemStatus}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </>
                        )}
                      </div>
                    )}
                    {userloginedPermission?.write_store_info === false && (
                      <div className="col-md-4">
                        <Form.Item
                          name="customized_redeem"
                          label={formatMessage({ id: 'form.storedetail.label.customizedredeem' })}
                        >
                          <Input size="large" disabled />
                        </Form.Item>
                      </div>
                    )}
                  </div>

                  {userloginedPermission?.write_store_info === true && (
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={storeinfo.updating}
                        >
                          {formatMessage({ id: 'action.update' })}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </Form>
      </div>
      <ResetPassword
        visible={storeinfo.displayResetPasswordModal}
        title={`Reset Password - ${storeinfo?.data8}`}
        onSubmit={callresetPassword}
        onCancel={hideResetPassword}
        loading={storeinfo.resetting}
        storeinfoApiRes={storeinfo?.selectedData?.openResetPassword}
      />
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
