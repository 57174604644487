import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Input } from 'antd'
import CreditCardInput from './CreditCardInput'

const CreditCardContainer = ({ paymentMode, value, onChange, intl: { formatMessage } }) => {
  const triggerChange = changedValues => {
    if (onChange) {
      onChange({ ...value, ...changedValues })
    }
  }

  return (
    <div>
      <div className="d-flex flex-row align-self-center">
        <Form.Item requiredMark={false}>
          <CreditCardInput onChange={triggerChange} />
        </Form.Item>
        <div className="m-2" />
        <Form.Item
          style={{ width: '50%' }}
          name={[paymentMode, 'zip_code']}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
          normalize={input => (input || '').toUpperCase()}
        >
          <Input
            type="text"
            size="large"
            placeholder={formatMessage({ id: 'form.placeholder.postalCode' })}
            maxLength={8}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[paymentMode, 'name']}
        style={{ width: '46%' }}
        rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
      >
        <Input
          type="text"
          size="large"
          placeholder={formatMessage({ id: 'form.placeholder.cardName' })}
          maxLength={20}
        />
      </Form.Item>
    </div>
  )
}

export default injectIntl(CreditCardContainer)
