import React from 'react'
import style from './style.module.scss'

const ColorBox = ({ color, selected, onSelect }) => {
  return (
    <button
      type="button"
      className={`${style.colorBox} rounded ${
        selected ? `${style.selected} shadow-lg` : 'border border-1'
      }`}
      style={{ backgroundColor: color }}
      onClick={() => onSelect(color)}
    />
  )
}

export default ColorBox
