import React from 'react'
import { Tag } from 'antd'

const CardTitle = ({ title, data, description, action }) => {
  return (
    <div className="mb-2">
      <h4 className="d-flex justify-content-between">
        {data && (
          <>
            <strong>
              {`${title}`} <Tag color="green">{`Free Physical Cards - ${data}`}</Tag>{' '}
            </strong>
          </>
        )}

        {!data && (
          <>
            <strong>{title}</strong>
          </>
        )}

        {action}
      </h4>
      <p className="font-size-14 text-secondary" hidden={!description}>
        {description}
      </p>
    </div>
  )
}

export default CardTitle
