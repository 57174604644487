import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import leads from './leads/sagas'
import country from './country/sagas'
import storeinfo from './storeinfo/sagas'
import invoice from './invoice/sagas'
import hardware from './hardware/sagas'
import inventory from './inventory/sagas'
import businesstype from './businesstype/sagas'
import apilog from './apilog/saga'
import migration from './migration/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    leads(),
    country(),
    storeinfo(),
    invoice(),
    hardware(),
    inventory(),
    businesstype(),
    apilog(),
    migration(),
  ])
}
