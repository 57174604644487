import React, { useState, useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreinfoAction from 'redux/storeinfo/actions'
import { createStructuredSelector } from 'reselect'
import { selectDeviceData } from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Form, Input, Table, Spin } from 'antd'
import moment from 'moment'
// import { getFormattedDate } from 'utils/parser'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    filtereddevicedata: selectDeviceData,
    settings: selectSettings,
  })

const Devicelist = ({ dispatch, filtereddevicedata, intl: { formatMessage } }) => {
  const onSearch = value => {
    dispatch({
      type: StoreinfoAction.SET_STATE,
      payload: { searchlocation: value },
    })
  }

  const path = window.location.pathname

  const userId = path.replace('/manage/store-info/', '')

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreinfoAction.LOAD_DEVICE_DATA,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * pageSize + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.deviceName' }),
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: formatMessage({ id: 'text.version' }),
      dataIndex: 'app_version',
      key: 'app_version',
    },
    {
      title: formatMessage({ id: 'text.ipAddress' }),
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
    {
      title: formatMessage({ id: 'text.macAddress' }),
      dataIndex: 'mac_address',
      key: 'mac_address',
    },
    {
      title: formatMessage({ id: 'text.activationdate' }),
      dataIndex: 'activated_at',
      key: 'activated_at',
      render: text => moment(text).format('MMM D, YYYY HH:mm:ss A'),
    },
    {
      title: formatMessage({ id: 'text.lastactivity' }),
      dataIndex: 'last_active_at',
      key: 'last_active_at',
      render: text => moment(text).format('MMM D, YYYY HH:mm:ss A'),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.devicelist' }),
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
        <div className="row">
          <div className="col-md-4">
            <Form.Item>
              <Search
                style={{ width: '100%' }}
                placeholder={formatMessage({ id: 'form.placeholder.device.search' })}
                size="large"
                onSearch={onSearch}
                allowClear
              />
            </Form.Item>
          </div>
        </div>
        <div className="kit__utils__table">
          <Suspense fallback={<Spin />}>
            {filtereddevicedata?.length >= 0 ? (
              <Table
                dataSource={filtereddevicedata}
                columns={columns}
                locale={locale}
                rowKey="_id"
                pagination={{
                  current: page,
                  pageSize1: pageSize,
                  total: filtereddevicedata?.length || 0,
                  onChange: (current, newPageSize) => {
                    setPageSize(newPageSize)
                    setPage(pageSize !== newPageSize ? 1 : current)
                  },
                }}
              />
            ) : (
              <Table
                columns={columns}
                locale={locale}
                loading={{
                  indicator: (
                    <div>
                      <Spin />
                    </div>
                  ),
                }}
              />
            )}
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Devicelist))
