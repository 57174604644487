import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Table, Tag, Typography, Drawer, Space } from 'antd'
import StoreInfoAction from 'redux/storeinfo/actions'
import { parseType } from 'utils/parser'
import { createStructuredSelector } from 'reselect'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import { selectStoreInfoReducer, selectGiftCardData } from 'redux/storeinfo/storelistselectors'
import { cardTypes } from 'utils/data'
import Loading from 'components/app/common/Loading'
import Stat from './Stat'
import TransactionHistory from './TransactionHistory'
import DigigtalEmailSend from './DigigtalEmailSend'

const { Search } = Input
const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    storeinfo: selectStoreInfoReducer,
    giftCardData: selectGiftCardData,
    settings: selectSettings,
    user: selectUser,
  })

const GiftCard = ({
  giftCardData,
  user,
  settings,
  storeinfo,
  intl: { formatMessage, formatNumber },
}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const dispatch = useDispatch()
  const path = window.location.pathname
  const userId = path.replace('/manage/store-info/', '')

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_GIFTCARD_LIST,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const onSearch = value => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        search: value,
      },
    })
  }

  const ShowsendEmail = value => {
    dispatch({
      type: StoreInfoAction.SHOW_SEND_EMAIL,
      payload: {
        businessId: value,
        businessName: storeinfo?.data1,
      },
    })
  }

  const HidesendEmail = () => {
    dispatch({
      type: StoreInfoAction.HIDE_SEND_EMAIL,
    })
  }

  const sendEmail = () => {
    dispatch({
      type: StoreInfoAction.SEND_EMAIL,
      payload: {
        businessId: storeinfo?.selectedData?.businessId,
      },
    })
  }

  const showTransactionHistory = giftCardRecord => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        selectedData: {
          giftCardId: giftCardRecord?._id,
          business: storeinfo?.data21,
          location: storeinfo?.data22,
          ...giftCardRecord,
        },
        displayTransactionHistoryDrawer: true,
      },
    })
  }

  const hideTransactionHistory = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayTransactionHistoryDrawer: false,
      },
    })
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * pageSize + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.recipientName' }),
      dataIndex: ['recipient', 'name'],
      key: 'recipient_name',
      render: text => <>{text ? <span>{text}</span> : '-'}</>,
    },
    {
      title: formatMessage({ id: 'text.giftCardNumber' }),
      dataIndex: 'gift_card_number',
      key: 'gift_card_number',
      render: (text, record) => (
        <>
          <span className="mr-1">{text}</span>
          <Tag color={record.card_type === 'digital' ? 'orange' : 'blue'}>
            {parseType(record.card_type, cardTypes)}
          </Tag>
          {record.external_card && (
            <div className="mt-2">
              <span className="mr-1">{record.external_card.gift_card_number}</span>
              <Tag color="#b51241">{record.external_card.platform}</Tag>
            </div>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.giftCardBalance' }),
      key: 'gift_card_balance',
      render: text => (
        <strong>
          {formatNumber(text.balance.price / 100, {
            style: 'currency',
            currency: text.balance.currency,
          })}
        </strong>
      ),
    },
    {
      title: formatMessage({ id: 'text.transactionHistory' }),
      key: 'transaction_history',
      render: text => (
        <button
          type="button"
          className="btn btn-light"
          onClick={() => showTransactionHistory(text)}
        >
          {formatMessage({ id: 'action.viewDetails' })}
        </button>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex mb-3">
        <h4 className="mr-2">
          <strong>{formatMessage({ id: 'text.giftCardList' })}</strong>
        </h4>
        <Text className="font-size-16 font-weight-bold" code>
          {formatMessage({ id: 'text.totalCount' }, { count: giftCardData?.length || 0 })}
        </Text>
      </div>
      <div hidden={giftCardData}>
        <Loading />
      </div>
      <div hidden={!giftCardData}>
        <div className="row">
          <div className="col-md-4">
            <Stat
              bgColor="#27ae60"
              title={formatMessage({ id: 'text.giftIssued' })}
              amount={storeinfo?.giftCardIssuedData?.amount}
              currency={storeinfo?.giftCardIssuedData?.currency}
            />
          </div>
          <div className="col-md-4">
            <Stat
              bgColor="#3498db"
              title={formatMessage({ id: 'text.giftRedeemed' })}
              amount={storeinfo?.giftCardRedeemedData?.amount}
              currency={storeinfo?.giftCardRedeemedData?.currency}
            />
          </div>
          <div className="col-md-4">
            <Stat
              bgColor="#e74c3c"
              title={formatMessage({ id: 'text.giftUnredeemed' })}
              amount={storeinfo?.giftCardUnRedeemedData?.amount}
              currency={storeinfo?.giftCardUnRedeemedData?.currency}
            />
          </div>
        </div>
        <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
          <div className="row">
            <div className="col-md-4">
              <Form.Item>
                <Search
                  style={{ width: '100%' }}
                  placeholder={formatMessage({ id: 'form.placeholder.search.giftCard' })}
                  size="large"
                  onSearch={onSearch}
                  allowClear
                />
              </Form.Item>
            </div>

            {user?.data?.supportuser?.role !== 'support admin'}
            <div className="col-md-8 text-right" style={{ float: 'right' }}>
              <Space>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => ShowsendEmail(userId)}
                >
                  <i className="fe fe-email mr-1" aria-hidden="true" />
                  {formatMessage({ id: 'action.sendDigitalEmail' })}
                </button>
              </Space>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="kit__utils__table">
                <Table
                  locale={{ emptyText: formatMessage({ id: 'empty.giftCard' }) }}
                  columns={columns}
                  dataSource={giftCardData}
                  rowKey="_id"
                  pagination={{
                    current: page,
                    pageSize1: pageSize,
                    total: giftCardData?.length || 0,
                    onChange: (current, newPageSize) => {
                      setPageSize(newPageSize)
                      setPage(pageSize !== newPageSize ? 1 : current)
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={formatMessage({ id: 'text.transactionHistory' })}
        placement="right"
        destroyOnClose
        visible={storeinfo.displayTransactionHistoryDrawer}
        onClose={hideTransactionHistory}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <TransactionHistory />
      </Drawer>

      <DigigtalEmailSend
        visible={storeinfo.displaySendDigitalCard}
        title={storeinfo.selectedData?.businessName}
        description={formatMessage({ id: 'text.sendEmail.description' })}
        onSubmit={sendEmail}
        onCancel={HidesendEmail}
        loading={storeinfo.sending}
      />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(GiftCard))
