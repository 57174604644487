import apiClient from 'services/axios'

const BASE_URL = 'invoice'

export async function getpaid() {
  return apiClient
    .get(`${BASE_URL}/paid`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getnotpaid() {
  return apiClient
    .get(`${BASE_URL}/not-paid`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getrefund() {
  return apiClient
    .get(`${BASE_URL}/refund`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function resendinvoice(invoiceid) {
  return apiClient
    .get(`${BASE_URL}/resend/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function viewinvoice(invoiceid) {
  return apiClient
    .get(`${BASE_URL}/view/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function downloadpdf(invoiceid) {
  return apiClient
    .get(`${BASE_URL}/pdf/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function retrypayment(invoiceid) {
  return apiClient
    .get(`${BASE_URL}/retry/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function refundinvoice(invoiceIdVal, amountVal) {
  return apiClient
    .post(`${BASE_URL}/refund/${invoiceIdVal}`, amountVal)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function viewinvoiceactivity(invoiceid) {
  return apiClient
    .get(`${BASE_URL}/view/${invoiceid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changepaymentstatus(invoiceId, payload) {
  return apiClient
    .post(`${BASE_URL}/chargeBambora/${invoiceId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
