import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { Button, Form, AutoComplete, notification } from 'antd' // Import notification from Ant Design
import { dataCount } from 'utils/data'

const AddGiftCardModal = ({ onSubmit, loading }) => {
  const { formatMessage } = useIntl()
  const digitalCardRef = useRef(null)
  const physicalCardRef = useRef(null)

  const handleDigitalCardSelect = () => {
    digitalCardRef.current.blur() // Blur to close the dropdown
  }

  const handlePhysicalCardSelect = () => {
    physicalCardRef.current.blur() // Blur to close the dropdown
  }

  const handleValidation = async values => {
    if (values.physical_card <= 0 && values.digital_card <= 0) {
      notification.error({
        message: 'Card Count Should not be Zero',
      })
    } else {
      let isCardExitValidation = true
      if (values.digital_card > 5000) {
        isCardExitValidation = false
        notification.error({
          message: 'Card Count Exceeded',
          description: 'Digital Card Count Cannot Exceed 5000.',
        })
      }
      if (values.physical_card > 5000) {
        isCardExitValidation = false
        notification.error({
          message: 'Card Count Exceeded',
          description: ' Physical Card Count Cannot Exceed 5000.',
        })
      }
      if (isCardExitValidation === true) {
        await onSubmit(values)
      }
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            initialValues={{
              physical_card: '0',
              digital_card: '0',
            }}
            onFinish={handleValidation} // Call handleValidation instead of onSubmit directly
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="digital_card"
                  label={formatMessage({ id: 'form.label.digital_card' })}
                >
                  <AutoComplete
                    placeholder={formatMessage({ id: 'form.placeholder.digital_card' })}
                    size="large"
                    options={dataCount.map(o => ({ value: o.value }))}
                    onSelect={handleDigitalCardSelect}
                    ref={digitalCardRef}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="physical_card"
                  label={formatMessage({ id: 'form.label.physical_card' })}
                >
                  <AutoComplete
                    placeholder={formatMessage({ id: 'form.placeholder.physical_card' })}
                    size="large"
                    options={dataCount.map(o => ({ value: o.value }))}
                    onSelect={handlePhysicalCardSelect}
                    ref={physicalCardRef}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button size="large" type="primary" htmlType="submit" loading={loading}>
                    {formatMessage({ id: 'action.add' })}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AddGiftCardModal
