const actions = {
  SET_STATE: 'storeinfo/SET_STATE',
  LOAD_STORE: 'storeinfo/LOAD_STORE',
  UPDATE_STORE: 'storeinfo/UPDATE_STORE',
  UPDATE_STORE_INFO: 'storeinfo/UPDATE_STORE_INFO',
  UPDATE_IMAGE: 'storeinfo/UPDATE_IMAGE',
  UPLOAD_IMAGE: 'storeinfo/UPLOAD_IMAGE',
  CHANGE_NEXT_BILLING_DATE: 'storeinfo/CHANGE_NEXT_BILLING_DATE',
  SHOW_NEXT_BILLING_DATE_MODAL: 'storeinfo/SHOW_NEXT_BILLING_DATE_MODAL',
  HIDE_NEXT_BILLING_DATE_MODAL: 'storeinfo/HIDE_NEXT_BILLING_DATE_MODAL',
  SHOW_GIFT_CARD_MODAL: 'storeinfo/SHOW_GIFT_CARD_MODAL',
  HIDE_GIFT_CARD_MODAL: 'storeinfo/HIDE_GIFT_CARD_MODAL',
  ADD_GIFT_CARD_COUNT: 'storeinfo/ADD_GIFT_CARD_COUNT',
  RESENDEMAIL: 'storeinfo/RESENDEMAIL',
  UPDATE: 'storeinfo/UPDATE',
  LOAD_PLAN_INVENTORY: 'storeinfo/LOAD_PLAN_INVENTORY',
  LOAD_INVOICES: 'storeinfo/LOAD_INVOICES',
  VIEW_INVOICES: 'storeinfo/VIEW_INVOICES',
  RESEND_INVOICE: 'storeinfo/RESEND_INVOICE',
  SHOW_RESEND_INVOICE_MODAL: 'storeinfo/SHOW_RESEND_INVOICE_MODAL',
  HIDE_RESEND_INVOICE_MODAL: 'storeinfo/HIDE_RESEND_INVOICE_MODAL',
  SHOW_INVOICE_ACTIVITY_MODAL: 'storeinfo/SHOW_INVOICE_ACTIVITY_MODAL',
  HIDE_INVOICE_ACTIVITY_MODAL: 'storeinfo/HIDE_INVOICE_ACTIVITY_MODAL',
  SHOW_RETRY_PAYMENT_MODAL: 'storeinfo/SHOW_RETRY_PAYMENT_MODAL',
  HIDE_RETRY_PAYMENT_MODAL: 'storeinfo/HIDE_RETRY_PAYMENT_MODAL',
  RETRY_PAYMENT: 'storeinfo/RETRY_PAYMENT',
  LOAD_INVOICE_ACTIVITY: 'storeinfo/LOAD_INVOICE_ACTIVITY',
  SHOW_REFUND_INVOICE_MODAL: 'storeinfo/SHOW_REFUND_INVOICE_MODAL',
  HIDE_REFUND_INVOICE_MODAL: 'storeinfo/HIDE_REFUND_INVOICE_MODAL',
  REFUND_INVOICE: 'storeinfo/REFUND_INVOICE',
  ADD_CARD: 'storeinfo/ADD_CARD',
  CHANGE_DEFAULT_CARD: 'storeinfo/CHANGE_DEFAULT_CARD',
  REMOVE_CARD: 'storeinfo/REMOVE_CARD',
  LOAD: 'storeinfo/LOAD',
  LOAD_LOCATION_DATA: 'storeinfo/LOAD_LOCATION_DATA',
  LOAD_DEVICE_DATA: 'storeinfo/LOAD_DEVICE_DATA',
  LOAD_GIFT_CARD_SETTINGS: 'storeinfo/LOAD_GIFT_CARD_SETTINGS',
  GET_BUSINESS_NAME: 'storeinfo/GET_BUSINESS_NAME',
  GET_RESELLERLIST: 'storeinfo/GET_RESELLERLIST',
  GET_GIFT_CARD_SALES: 'storeinfo/GET_GIFT_CARD_SALES',
  CALLGIFTCARDSALES: 'storeinfo/CALLGIFTCARDSALES',
  GET_GIFT_CARD_REDEEM: 'storeinfo/GET_GIFT_CARD_REDEEM',
  CALLGIFTCARDREDEEM: 'storeinfo/CALLGIFTCARDREDEEM',
  CALLNEWLEADS: 'storeinfo/CALLNEWLEADS',
  GET_NEW_LEADS: 'storeinfo/GET_NEW_LEADS',
  GET_REVENUE: 'storeinfo/GET_REVENUE',
  CALLREVENUE: 'storeinfo/CALLREVENUE',
  GET_AGENT_NEW_LEADS: 'storeinfo/GET_AGENT_NEW_LEADS',
  CALLAGENTNEWLEADS: 'storeinfo/CALLAGENTNEWLEADS',
  GET_SIGNUP: 'storeinfo/GET_SIGNUP',
  CALL_SIGNUP: 'storeinfo/CALL_SIGNUP',
  GET_AGENT_SIGNUP: 'storeinfo/GET_AGENT_SIGNUP',
  CALL_AGENT_SIGNUP: 'storeinfo/CALL_AGENT_SIGNUP',
  GETNOTES: 'storeinfo/GETNOTES',
  SAVENOTE: 'storeinfo/SAVENOTE',
  LOAD_GIFTCARD_LIST: 'storeinfo/LOAD_GIFTCARD_LIST',
  LOAD_TRANSACTIONS: 'storeinfo/LOAD_TRANSACTIONS',
  SEND_EMAIL: 'storeinfo/SEND_EMAIL',
  SHOW_SEND_EMAIL: 'storeinfo/SHOW_SEND_EMAIL',
  HIDE_SEND_EMAIL: 'storeinfo/HIDE_SEND_EMAIL',
  SHOW_RESET_PASSWORD: 'storeinfo/SHOW_RESET_PASSWORD',
  HIDE_RESET_PASSWORD: 'storeinfo/HIDE_RESET_PASSWORD',
  RESET_PASSWORD: 'storeinfo/RESET_PASSWORD',
  SHOW_RESTAURANTS: 'storeinfo/SHOW_RESTAURANTS',
  HIDE_RESTAURANTS: 'storeinfo/HIDE_RESTAURANTS',
  CREATE_BUSINESS: 'storeinfo/CREATE_BUSINESS',
}

export default actions
