import apiClient from 'services/axios'

const BASE_URL1 = 'lead'
const BASE_URL2 = 'supportuser'

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function filter(payload) {
  return apiClient
    .post(`${BASE_URL1}/filter`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changestatus(userId, payload) {
  delete payload.lead_id
  return apiClient
    .put(`${BASE_URL1}/${userId}/status`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changeowner(userId, payload) {
  delete payload.lead_id
  return apiClient
    .put(`${BASE_URL1}/${userId}/owner`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function addnotes(userId, payload) {
  delete payload.userId
  return apiClient
    .post(`${BASE_URL1}/${userId}/note`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getnotes(lead_id, payload) {
  return apiClient
    .get(`${BASE_URL1}/${lead_id}/notes`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function filterlist() {
  return apiClient
    .get(`${BASE_URL2}/filterlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function update(lead_id, updatedData) {
  return apiClient
    .put(`${BASE_URL1}/${lead_id}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function remove(lead_id, payload) {
  return apiClient
    .delete(`${BASE_URL1}/${lead_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
