import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ChooseImage from '../../../common/ChooseImage'

const mapStateToProps = ({ user }) => ({
  user,
})

const BackgroundImage = ({
  value,
  user,
  onChange,
  gallery,
  library,
  displayChooseImageModal,
  onModalVisibilityChange,
  onImageUpload,
  uploading,
  intl: { formatMessage },
}) => {
  const userloginedPermission = user?.data?.supportuser?.permission

  const showChooseImage = () => {
    onModalVisibilityChange(true)
  }

  const hideChooseImage = () => {
    onModalVisibilityChange(false)
  }

  const onSubmit = image => {
    if (onChange) onChange(image)
    onModalVisibilityChange(false)
  }

  return (
    <>
      <div>
        <img
          className="img-fluid"
          src={value?.image_url || value}
          alt={formatMessage({ id: 'desc.backgroundImage' })}
        />
      </div>
      <button
        type="button"
        className="btn btn-light mt-2 mb-2"
        onClick={showChooseImage}
        hidden={userloginedPermission?.write_store_info === false}
      >
        <i className="fe fe-edit mr-1" aria-hidden="true" />
        {formatMessage({ id: 'action.change' })}
      </button>
      <ChooseImage
        visible={displayChooseImageModal}
        gallery={gallery}
        library={library}
        onImageUpload={onImageUpload}
        uploading={uploading}
        onSubmit={onSubmit}
        onCancel={hideChooseImage}
      />
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(BackgroundImage))
