import React from 'react'
import style from './style.module.scss'

const ImageBox = ({ image, isSelected, onSelect }) => {
  return (
    <button
      type="button"
      className={`${style.imageBox} rounded ${
        isSelected ? `${style.selected} shadow-lg` : 'border border-1'
      }`}
      onClick={() => onSelect(image)}
    >
      <img className="img-fluid" src={image.image_url} alt={image.image} />
    </button>
  )
}

export default ImageBox
