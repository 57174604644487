import apiClient from 'services/axios'

const BASE_URL = 'inventory'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function additem(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function updateitem(inventoryId, updatedData) {
  return apiClient
    .put(`${BASE_URL}/${inventoryId}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getsingleinventory(payload) {
  return apiClient
    .get(`${BASE_URL}/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function updatestock(inventoryId, updatedData) {
  return apiClient
    .post(`${BASE_URL}/${inventoryId}/stock-history`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function activeList() {
  return apiClient
    .get(`${BASE_URL}/list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
