import localeAntd from 'antd/es/locale/en_US'

const messages = {
  // new locale data
  'action.startMigration': 'Migration',
  'action.addMigration': 'Import',
  'text.migrationOptions': 'Options',
  'text.removeBusinessName': 'Remove Business - {name}',
  'text.removeBusiness.description': 'Are you sure you want to remove this business?',
  'action.resendActivationKey': 'Resend Activation Key',
  'text.migrated': 'Migrated',
  'title.manage.leads': 'Manage Leads',
  'title.manage.HardwarePurchase': 'Hardware Purchase Orders',
  'text.HardwareOrderList': 'Orders List',
  'text.leads.serialNo': 'S.No',
  'text.leads.businessname': 'Business Name',
  'text.leads.contactname': 'Contact Name',
  'text.leads.phonenumber': 'Phone Number',
  'text.leads.city': 'City',
  // 'text.leads.leadsource': 'Lead Source',
  'text.leads.leadstatus': 'Status',
  'text.leads.demo': 'Demo',
  'text.leads.leadowner': 'Lead Owner',
  // 'text.leads.demoby': 'Demo By',
  'form.label.date': 'Date',
  'text.AssignGiftCard': 'Add Gift Card',
  'text.leads.lastmodified': 'Last Modified',
  'text.ChangeNextBillingDate': 'Change Next Billing Date',
  'text.leads.action': 'Action',
  'text.LeadList': 'Leads List',
  'text.clearfilters': 'Clear All Filters',
  'form.placeholder.search.leads': 'Search by business name or city',
  'action.add': 'Add',
  'action.addlead': 'Add Lead',
  'action.giftCard': 'Add Gift Card',
  'text.addLead': 'Add Lead',
  'text.updateLead': 'Update Lead',

  'form.label.businessName': 'Business Name',
  'form.placeholder.businessName': 'Enter the business name',
  'form.label.contactName': 'Contact Name',
  'form.label.contactPhone': 'Phone Number',
  'form.label.contactPhone.alt': 'Alt. Phone Number (Optional)',
  'form.placeholder.contactPhone.alt': 'Enter the Alt. Phone Number',
  'form.error.contactPhone.alt.invalid': 'Invalid Alt. Phone Number',
  'form.placeholder.phone': 'Enter the phone number',
  'form.label.emailoptional': 'Email  (Optional)',
  'form.error.email.required': 'Please input the email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 64 characters',
  'form.placeholder.email': 'Enter the email',
  'form.label.city': 'City',
  'form.label.cityoptional': 'City  (Optional)',
  'form.placeholder.city': 'Enter the city',
  'form.label.postalcodeoptional': 'Postal/Zip Code  (Optional)',
  'form.placeholder.postalcode': 'Enter the Postal/Zip Code',
  'form.label.province': 'State/Province',
  'form.label.provinceoptional': 'State/Province  (Optional)',
  'form.placeholder.province': 'Select the state/province',
  'form.label.country': 'Country',
  'form.label.addressoptional': 'Address (Optional)',
  'form.placeholder.country': 'Select the country',
  'form.label.type': 'Business Type',
  'form.placeholder.type': 'Select the Business Type',
  'form.label.lead_source': 'Lead Source',
  'form.placeholder.lead_source': 'Select the Lead Source',
  'text.deleteLeads': 'Delete {name}',
  'text.deleteLeads.description': 'Are you sure you want to delete this Lead?',
  'text.resendInvoice.description': 'Are you sure you want to resend this Invoice?',
  'text.RetryPayment.description': 'Are you sure you want to retry payment for this invoice?',
  'action.import': 'Import Leads',
  'action.bookdemo': 'Book Demo',
  'text.bookdemo': 'Book a Demo',
  'form.label.amount': 'Amount',
  'text.dueDate': 'Due Date',
  'form.label.physical_card': 'Physical Card',
  'form.label.digital_card': 'Digital Card',
  'form.placeholder.physical_card': 'Enter the Physical Card Count',
  'form.placeholder.digital_card': 'Enter the Digital Card Count',

  'form.error.agentId.len': 'Agent ID must be 6 digits',
  'form.label.agentId': 'Agent ID',
  'form.placeholder.agentId': 'Enter the agent id',
  'form.label.freePhysicalCards': 'Did you receive our 10 free physical gift cards?',
  'text.addRestaurants': 'Add Store',
  'text.contactdetails': 'Contact Details',
  'action.addRestaurant': 'Add Store',
  'title.helmet.store': 'Manage Stores',
  'title.manage.stores': 'Manage Stores',
  'text.manage.stores': 'Manage Stores',
  'text.store.tab.active': 'Active',
  'text.store.tab.inactive': 'Inactive',
  'text.store.tab.demo': 'Demo',
  'text.promoApplied': 'Promo Applied',
  'form.label.onlinereceipt': 'Send a receipt to merchant?',
  'text.OnlineStoreNotifications': 'Send a receipt to merchant?',
  'text.OnlineStoreNotification': 'Online Store Notifications',
  'text.CPOSIntegration': 'CPOS Integration',
  'text.CPOSIntegrations': 'Enable Smart Bundle?',
  'text.Integration': 'Enable Open API?',
  'text.ApiIntegration': 'Integration',
  'text.PaymentStatus': 'PaymentStatus',
  'text.Settings': 'Settings',
  'form.label.smartBundle': 'Enable Smart Bundle?',
  'form.label.openApi': 'Enable Open API?',
  'text.resetPassword': 'Reset Password',
  'action.reset': 'Reset',
  'form.label.confirm_password': 'Confirm Password',
  'form.placeholder.Password': 'Password',
  'form.placeholder.NewPassword': 'New Password',
  'form.placeholder.confirm_password.account': 'Confirm Password',
  'form.error.confirm_password.required': 'Please enter confirm password',

  'text.CposIntegration': 'Smart Bundle',
  'text.custommessage.hardwareOrderInfo': 'No hardware orders found',
  'text.addNewPurchaseOrder': 'Add New Purchase Order',
  'action.newOrder': 'New Purchase Order',
  'text.purchaseOrder': 'Purchase Order',
  'text.purchaseorder': 'Purchase Order',
  'text.PODate': 'PO Date',
  'text.total': 'Total',
  'text.unitCost': 'Unit Cost',
  'text.trackingNumber': 'Tracking Number',
  'text.storeName': 'Business Name',
  'text.orderValue': 'Order Value',
  'form.label.postalcode': 'Postal/Zip Code',
  'form.label.reselleragent': 'Reseller Agent',
  'form.check.sameAsBusinessAddress': 'Same as business address',
  'text.shippingAddress': 'Shipping Address',
  'text.Store Details': 'Store Details',
  'text.PO': 'PO',
  'text.paymentmethod': 'Payment Method',
  'text.resellerName': 'Reseller',
  'text.agentName': 'Agent',
  'text.FullFillmentStatus': 'Fullfillment Status',
  'form.placeholder.search.hardware': 'Search by business name or invoice number or status',

  'text.manageQuantity': 'Manage Quantity',
  'form.label.quantityUpdateType': 'Select the Type',
  'form.placeholder.quantityUpdateType': 'Select the Type',
  'form.label.quantity': 'Quantity',
  'form.placeholder.quantity': 'Quantity',
  'form.label.stocked_by': 'Stocked By',
  'form.placeholder.stocked_by': 'Select Stocked By',
  'form.label.stock_location': 'Stock Location',
  'form.placeholder.stock_location': 'Select Stock Location',
  'text.stock_date': 'Stock Date',
  'text.stocked_by': 'Stocked By',
  'text.stock_location': 'Stock Location',
  'text.custommessage.StockInfo': 'Mo records in Stock Update',

  'title.manage.Inventory': 'Manage Inventory',
  'text.addInventory': 'Add Inventory',
  'text.EditInventory': 'Edit Inventory',
  'text.item': 'Item',
  'text.make': 'Make',
  'form.label.make': 'Make',
  'form.placeholder.make': 'Select Make',
  'text.model': 'Model',
  'form.label.model': 'Model',
  'form.placeholder.model': 'Select Model',
  'form.placeholder.search.inventory': 'Search by Item or Make or Model',
  'action.Edit': 'Edit',
  'action.ManageQuantity': 'Manage Quantity',
  'text.InventoryList': 'Inventory List',
  'action.AddInventory': 'Add Inventory',
  'form.label.itemDescription': 'Item Description',
  'form.placeholder.itemDescription': 'Enter the Item Description',
  'form.label.originalprice': 'Original Price',
  'form.label.retail_price': 'Retail Price',
  'form.label.reseller_price': 'Reseller Price',
  'text.ItemInventory': 'Item Info',
  'form.placeholder.getbusinessName': 'Select Business Name from list',

  'text.active.store.sno': 'S.No',
  'text.active.store.store_name': 'Store Name',
  'text.active.store.phone_number': 'Phone Number',
  'text.active.store.email': 'Email',
  'text.active.store.city': 'City',
  'text.active.store.reseller': 'Reseller',
  'text.active.store.agent': 'Agent',
  'text.active.store.billing_type': 'Payment Method',
  'text.active.store.Source': 'Source',
  'text.active.store.resendWelcomeEmail': 'Action',
  'text.active.store.search': 'Search by store name or email or reseller or city',
  'text.PaymentInfo': 'Payment Info',
  'form.label.billing_to': 'Bill To',
  'form.placeholder.billing_to': 'Select Bill To from list',
  'form.label.payment_method': 'Payment Method',
  'form.placeholder.payment_method': 'Select Payment Method from list',
  'form.label.lease_approval_number': 'Lease Approval Number',
  'form.placeholder.lease_approval_number': 'Lease Approval Number',
  'form.label.funding_amount': 'Funding Amount',
  'form.placeholder.ItemName': 'Item Name',
  'form.label.creditcard': 'Credit Card',
  'form.placeholder.creditcard': 'Select Card from list',
  'action.CreateOrder': 'Create Order',
  'text.changeStatus': 'Change Status for ',
  'form.label.trackingNumber': 'Tracking Number',
  'form.placeholder.trackingNumber': 'Tracking Number',
  'form.label.subtotal': 'Sub Total',
  'form.label.total_tax': 'Total Tax',
  'form.label.total_amount': 'Total Amount',

  'title.helmet.storeinfo': 'Store Info',
  'text.storeinfo': 'Store Info',
  'text.storeino.tab.storeinfo': 'Store Info',
  'text.storeino.tab.giftcardsettings': 'Gift Card Settings',
  'text.storeino.tab.deviceinfo': 'Device Info',
  'text.storeino.tab.billing': 'Billing',
  'text.billing.tab.planinventory': 'Plan and Inventory',
  'text.billing.tab.paymenthistory': 'Payment History',
  'text.storeino.tab.locations': 'Locations',
  'text.storeino.tab.notes': 'Notes',
  'text.store.tab.OnBoarding': 'On Boarding',

  'text.storedetail.storedetailinfo': 'Store Details',
  'text.storedetail.contactdetails': 'Contact Details',
  'text.storedetail.businessdetails': 'Business Details',
  'action.storedetail.update': 'Update',
  'text.reselleragent': 'Reseller Agent',
  'text.salesagent': 'Sales Agent',

  'text.deviceName': 'Device Name',
  'text.ipAddress': 'IP Address',
  'text.macAddress': 'MAC Address',
  'text.version': 'Version',
  'text.activationdate': 'Activation Date',
  'text.lastactivity': 'Last Activity Date',
  'form.placeholder.device.search': 'Search by device name',
  'text.custommessage.devicelist': 'No devices to show',

  'text.Leadnotes': 'Add Notes',
  'text.notes.button_message': 'Add Notes ',
  'form.placeholder.notes.message': 'Type your notes here',

  'form.label.company': 'Company Name',
  'form.label.reseller': 'Company Name',
  'form.placeholder.company': 'Enter the company name',
  'form.placeholder.reseller': 'Select the company from list',

  'form.label.no_of_locations': 'No. of business locations',
  'form.placeholder.no_of_locations': 'Enter the no of business locations',

  'text.custommessage.userinfo': 'No users to show',
  'text.custommessage.leadInfo': 'No leads to show',
  'text.space': '{count, plural, =1{1} other{#}}',
  'text.custommessage.onboardingstores': 'No onboarding stores to show',
  'text.custommessage.activestores': 'No active stores to show',
  'text.custommessage.inactivestores': 'No inactive stores to show',
  'text.custommessage.demostores': 'No demo account to show',
  'text.custommessage.locationlist': 'No locations to show',
  'text.custommessage.invoicelist': 'No invoices to show',
  'form.storedetail.label.status': 'Account Status',
  'form.storedetail.label.customizedredeem': 'Display Customized Redeem?',
  'form.invoice.label.paymentStatus': 'Change Payment Status Paid',
  'text.invoice': 'Invoice',
  'text.billTo': 'Bill To',
  'text.paid': 'Paid',
  'text.notpaid': 'Not Paid',
  'text.refund': 'Refund',
  'text.paidDate': 'Paid Date',
  'text.subtotal': 'Subtotal',
  'text.totalDue': 'Total Due',
  'text.quantity': 'Quantity',
  'action.addCard': 'Add New Card',
  'action.addPayment': 'Add Payment',
  'text.addNewPayment': 'Add Payment',
  'text.billing.tab.addnewcard': 'Add New Card',
  'action.addNewCard': 'Add New Card',
  'text.billing.tab.addbambora': 'Add Bambora Details',
  'text.paymentAccountInfo': 'Bambora Payment Account Info',
  'action.HideCard': 'Hide Card',
  'text.changeCard': 'Change Payment Details and Method',
  'form.check.makeDefaultPaymentMethod': 'Make this my default payment method',
  'form.label.cardDetails': 'Card Details',
  'text.addNewCard': 'Add New Card',
  'text.editbambora': 'Edit Bambora Details',
  'text.expiresOn': 'Expires {month}/{year}',
  'text.addedOn': 'Added on {date}',
  'action.setDefault': 'Set Default',
  'text.changeDefaultCard.content': 'Are you sure you want to change the default card?',
  'text.removeCard.content': 'Are you sure you want to remove the card?',
  'text.default': 'Default',
  'text.savedCards': 'Saved Cards',
  'text.savedBambora': 'Saved Bambora Details',

  'action.resendInvoice': 'Resend Invoice',
  'text.resendInvoice': 'Resend Invoice',
  'text.retryPayment': 'Retry Payment',
  'text.refundInvoice': 'Refund Invoice',
  'text.activityLog': 'Activity Log',
  'text.InvoiceActivity': 'Activity Log',
  'text.PaymentStatusChange': 'Change Payment Status',
  'text.noactivitytoshow': 'No Invoice Activity Log to show',
  'text.printInvoice': 'Print Invoice',
  'text.DownloadInvoice': 'Download Invoice',
  'text.businessName': 'Business Name',
  'text.paymentamount': 'Amount',
  'text.paymentCardNumber': 'Card Number',
  'text.errorReason': 'Option',
  'text.errorDate': 'Created Date',
  'text.paymentType': 'Method',
  'text.paymentBusinessName': 'Business Name',
  'text.functionalType': 'Layer',
  'text.paymentLogStatus': 'Status',
  'text.migrationStatus': 'Status',
  'text.migrationfailure': 'failure',
  'text.migrationInProgress': 'inprogress',
  'text.migrationSuccess': 'success',

  'text.store.tab.refund': 'Refund',
  'text.store.tab.paid': 'Paid',
  'text.store.tab.notpaid': 'Not Paid',

  'form.placeholder.location.search': 'Search by location name or city',
  'action.active': 'Active',
  'action.inactive': 'Inactive',

  'form.placeholder.agent_id': 'Agent ID',
  'form.label.agent_id': 'Agent ID',

  'action.chooseImage': 'Choose an Image',

  'text.currentPlan': 'Current Plan',
  'text.activeSince': 'Active Since',
  'text.nextBillingDate': 'Next Billing Date',
  'text.month': 'month',
  'text.bambora': 'Bambora Payment',
  'text.available': 'Available',
  'text.giftCardInventory': 'Gift Card Inventory',
  'text.issued': 'Issued',
  'text.digitalcards': 'Digital Cards',
  'text.physicalcards': 'Physical Cards',
  'text.plan': 'Plan',
  'text.payment history': 'Payment History',
  'text.migrationInfo': 'Migration Response Logs',

  'text.invoiceAmount': 'Invoice Amount',
  'text.invoiceDate': 'Invoice Date',
  'text.invoiceNo': 'Invoice No.',
  'text.paymentDate': 'Payment Date',
  'text.paymentStatus': 'Payment Status',
  'text.nonotestoshow': 'No notes to show',
  'form.label.signup_source': 'Signup Source',
  'form.placeholder.signup_source': 'Signup Source',

  'form.placeholder.search.invoice': 'Search by invoice no',

  // new locale data

  'form.error.required': 'Required',

  'action.addCoupon': 'Add Coupon',
  'action.addCustomImage': 'Add Custom Image',
  'action.addLocation': 'Add Location',
  'action.addUser': 'Add User',
  'action.addLead': 'Add Lead',
  'action.updateLead': 'Update Lead',
  'action.cancel': 'Cancel',
  'action.change': 'Change',
  'action.changeColor': 'Change Color',
  'action.choose': 'Choose',
  'action.confirm': 'Confirm',
  'action.connect': 'Connect',
  'action.copyStoreUrl': 'Copy Store URL',
  'action.customerPortalBgImg': 'Customer Portal Background Image',
  'action.download': 'Download',
  'action.downloadTemplate': 'Download Template',
  'action.edit': 'Edit',
  'action.viewerrorlog': 'View',
  'action.export': 'Export',
  'action.forgotPassword': 'Forgot password?',
  'action.giftCardPromotion': 'Gift Card Promotion',
  'action.gotoSignIn': 'Go to Sign in',
  'action.issue': 'Issue',
  'action.issueGiftCard': 'Issue Gift Card',
  'action.logout': 'Logout',
  'action.next': 'Next',
  'action.manage': 'Manage',
  'action.no': 'No',
  'action.recoverPassword': 'Recover my password',
  'action.redeem': 'Redeem',
  'action.reload': 'Reload',
  'action.delete': 'Delete',
  'action.remove': 'Remove',
  'action.resend': 'Resend',
  'action.resendEmail': 'Resend Email',
  'action.resetPassword': 'Reset password',
  'action.save': 'Save',
  // 'action.update': 'Update',
  'action.create': 'Create',
  'action.search': 'Search',
  'action.select': 'Select',
  'action.send': 'Send',
  'action.signIn': 'Sign in',
  'action.signUp': 'Sign up',
  'action.update': 'Update',
  'action.paymentStatus': 'Paid',
  'action.upload': 'Upload',
  'action.view': 'View',
  'action.viewDetails': 'View Details',
  'action.viewReceipt': 'View Receipt',
  'action.viewStore': 'View Store',
  'action.yes': 'Yes',

  'desc.backgroundImage': 'Background Image',
  'desc.barcode': 'Barcode',
  'desc.icon': 'Icon',

  'empty.image': 'No images.',
  'empty.paymentlogsdata': 'No Logs Found!',

  'form.error.amount.required': 'Please input the amount',
  'form.error.amount.min': 'Must be min. $0.01',
  'form.error.businessAddress.required': 'Please input the business address',
  'form.error.businessAddress.max': 'Business address must be up to 180 characters',
  'form.error.businessName.required': 'Please input the business name',
  'form.error.businessName.max': 'Business name must be up to 64 characters',
  'form.error.code.max': 'Code must be up to 20 characters',
  'form.error.code.min': 'Code must be min 3 characters',
  'form.error.contactPhone.invalid': 'Invalid contact phone number',
  'form.error.coupon.minPurchaseValue':
    'Min. Purchase Value should be higher than the Coupon Value',

  'form.error.empty.select': 'No matches found.',
  'form.error.firstName.required': 'Please input the first name',
  'form.error.firstName.max': 'First name must be up to 64 characters',
  'form.error.invalid': 'Invalid',
  'form.error.lastName.required': 'Please input the last name',
  'form.error.lastName.max': 'Last name must be up to 64 characters',
  'form.error.locationAddress.required': 'Please input the location address',
  'form.error.locationAddress.max': 'Business address must be up to 180 characters',
  'form.error.loginPin.length': 'Must be a 4 digit pin',
  'form.error.password.required': 'Please input the password',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.max': 'Password must be up to 32 characters',
  'form.error.password.match': "Passwords doesn't match",
  'form.error.phone.invalid': 'Invalid phone number',

  'form.error.disallow.specialChars': 'Special characters are not allowed',

  'form.helper.coupon.channel': 'Coupon will be valid only at the selected channels',
  'form.helper.coupon.purchaseType': 'Coupon will be valid only for the selected purchase types',
  'form.helper.recipientEmail': 'Digital card will be sent to this email',
  'form.helper.senderEmail': 'A copy of the digital card will be sent to this email',

  'form.label.user_status': 'Status',
  'form.label.active': 'Active',
  'form.label.address': 'Address',
  'form.label.allowCashSales': 'Allow Cash Sales?',
  'form.label.allowIssueCard': 'Allow to Issue Card?',
  'form.label.allowManageCustomers': 'Allow to Manage Customers?',
  'form.label.allowManageCustomers.all': 'Allow to Manage All Customers?',
  'form.label.allowManageGiftCards': 'Allow to Manage All Gift Cards?',
  'form.label.allowManageUsers': 'Allow to Manage Users?',
  'form.label.allowRedeemCard': 'Allow to Redeem Card?',
  'form.label.allowReloadCard': 'Allow to Reload Card?',
  'form.label.allowRunReport': 'Allow to Run Reports?',
  'form.label.altPhone': 'Alt. Phone Number',
  'form.label.appId': 'App ID',
  'form.label.backgroundColor': 'Background Color',
  'form.label.backgroundImage': 'Background Image',
  'form.label.businessAddress': 'Business Address',

  'form.label.businessType': 'Business Type',
  'form.label.channel': 'Channel',

  'form.label.email.current': 'Current Email',
  'form.label.email.new': 'New Email',
  'form.label.email': 'Email',
  'form.label.from': 'From',
  'form.label.code': 'Code',
  'form.label.coupon.minPurchaseValue': 'Min. Purchase Value',
  'form.label.coupon.value': 'Coupon Value',

  'form.label.cardNumber': 'Card Number',
  'form.label.creditCard': 'Credit Card',
  'form.label.cvv': 'CVV',
  'form.label.description': 'Description',
  'form.label.deliveryDate': 'Delivery Date',
  'form.label.employeeId': 'Employee ID',
  'form.label.expiry': 'Expiry Date',
  'form.label.firstName': 'First Name',
  'form.label.giftCardBalance': 'Gift Card Balance',
  'form.label.giftCardCopy': 'Send a copy of Gift Card?',
  'form.label.giftCardNumber': 'Gift Card Number',
  'form.label.giftCardType': 'Gift Card Type',
  'form.label.image': 'Image',
  'form.label.language': 'Language',
  'form.label.language.preferred': 'Preferred Language',
  'form.label.lastName': 'Last Name',
  'form.label.lastName.optional': 'Last Name (Optional)',
  'form.label.location': 'Location',
  'form.label.locationAddress': 'Location Address',
  'form.label.loginPin': 'Login Pin',
  'form.label.merchantId': 'Merchant ID',
  'form.label.message': 'Message',
  'form.label.message.optional': 'Message (Optional)',
  'form.label.minPurchaseValue': 'Min. Purchase Value',
  'form.label.name': 'Name',
  'form.label.name.optional': 'Name (Optional)',
  'form.label.password': 'Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'form.label.payment': 'Payment',
  'form.label.paymentMethod': 'Payment Method',
  'form.label.phoneoptional': 'Phone Number (Optional)',
  'form.label.phone': 'Phone Number',
  'form.label.phone.optional': 'Phone Number (Optional)',
  'form.label.pin': 'Pin',

  'form.label.purchaseType': 'Purchase Type',
  'form.label.redeemValue': 'Redeem Value',
  'form.label.rePassword': 'Re-Password',
  'form.label.reloadValue': 'Reload Value',
  'form.label.retypeLoginPin': 'Retype Login Pin',
  'form.label.role': 'Role',
  'form.label.storeName': 'Store Name',
  'form.label.postalCode': 'Zip/Postal Code',
  'form.label.textColor': 'Text Color',
  'form.label.themeColor': 'Theme Color',
  'form.label.timezone': 'Timezone',
  'form.label.to': 'To',
  'form.label.transactionType': 'Transaction Type',
  'form.label.user': 'User',
  'form.label.userId': 'User ID',
  'form.label.zipPostalCode': 'Zip/Postal Code',

  'form.placeholder.address': 'Enter the address',
  'form.placeholder.altPhone': 'Enter the alt. phone number',
  'form.placeholder.businessCity': 'City',
  'form.placeholder.businessCountry': 'Country',

  'form.placeholder.businessPostalCode': 'Zip/Postal code',
  'form.placeholder.businessProvince': 'State/Province',
  'form.placeholder.businessType': 'Select the business type',
  'form.placeholder.amount': '0.00',
  'form.placeholder.cardName': 'Name on card',
  'form.placeholder.cardNumber': '4242 4242 4242 4242',

  'form.placeholder.contactName': 'Enter the contact name',
  'form.placeholder.contactPhone': 'Enter the contact phone number',
  'form.placeholder.coupon.code': 'Coupon code',
  'form.placeholder.coupon.description': 'Short description about the coupon',
  'form.placeholder.coupon.value': 'Coupon value',

  'form.placeholder.customerEmail': 'Enter the customer email',
  'form.placeholder.customerName': 'Enter the customer name',
  'form.placeholder.customerPhone': 'Enter the customer phone number',

  'form.placeholder.email.current': 'Enter the current email address',
  'form.placeholder.email.new': 'Enter the new email address',
  'form.placeholder.expirationMonth': 'MM',
  'form.placeholder.expirationYear': 'YY',
  'form.placeholder.firstName': 'Enter the first name',
  'form.placeholder.giftCardBalance': 'Enter the gift card balance',
  'form.placeholder.giftCardNumber': 'xxxxxxxxxxxxxxxx',
  'form.placeholder.language': 'Select the language',
  'form.placeholder.lastName': 'Enter the last name',
  'form.placeholder.location.name': 'Enter the location name',
  'form.placeholder.locations': 'Select the locations',
  'form.placeholder.loginPin': 'Enter the 4-digit login pin',
  'form.placeholder.message': 'Add notes here',
  'form.placeholder.recipientEmail': "Enter the recipient's email",
  'form.placeholder.recipientName': "Enter the recipient's full name",
  'form.placeholder.recipientPhone': "Enter the recipient's phone number",
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.password.account': 'Your account password',
  'form.placeholder.postalCode': 'Enter the zip/postal code',

  'form.placeholder.rePassword': 'Enter the password again',
  'form.placeholder.retypeLoginPin': 'Enter the 4-digit login pin again',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.search': 'Search..',
  'form.placeholder.search.customer': 'Search by name or email',
  'form.placeholder.search.staff': 'Search by name or email',
  'form.placeholder.search.giftCard': 'Search by name or card number..',
  'form.placeholder.senderEmail': "Enter the sender's email",
  'form.placeholder.senderName': "Enter the sender's full name",
  'form.placeholder.senderPhone': "Enter the sender's phone number",
  'form.placeholder.storeName': 'Enter the Business name',
  'form.placeholder.transactionId': 'Enter the transaction id',
  'form.placeholder.streetAddress': 'Street address',
  'form.placeholder.colorCode': 'Enter Color Code',
  'form.placeholder.timezone': 'Select the timezone',
  'form.placeholder.transactionType': 'Select the timezone',
  'form.placeholder.users': 'Select the users for this location',
  'form.label.check_number': 'Check Number',
  'form.placeholder.check_number': 'Enter Void Check Number',

  'form.select.all': 'All',
  'form.select.card': 'Card',
  'form.select.cash': 'Cash',
  'form.select.english': 'English',
  'form.select.french': 'French',
  'form.select.inStore': 'In-Store',
  'form.select.onlineStore': 'Online Store',
  'form.select.purchase': 'Purchase',
  'form.select.redeem': 'Redeem',
  'form.select.reload': 'Reload',
  'action.sendDigitalEmail': 'Resend Digital Gift Card',
  'text.sendEmail': 'Resend Digital Gift Card',
  'text.sendEmail.description':
    'Are you sure, you want to send Email to all Digital Card Recipients?',
  'action.sendEmail': 'Send Email',
  'action.Yes': 'Yes',
  'action.No': 'No',
  'action.Show': 'Show',
  'action.Hide': 'Hide',
  'action.Paid': 'Paid',
  'action.NotPaid': 'NotPaid',

  'helper.dragDropImage': 'Click or drag image to this area to upload',
  'helper.reportsMainLocation': '*Reports are based on Main Location',

  // common links
  'link.termsService': 'Terms of Service',
  'link.privacyPolicy': 'Privacy Policy',

  'message.copyClipboard': 'Copied to clipboard',

  'note.logoSize':
    'Image dimension should be 230px × 87px in JPG or PNG format with the size less than 1MB.',

  'progress.resendingEmail': 'Resending email..',
  'progress.uploading': 'Uploading.. please wait..',
  'progress.verifyingCode': 'Verifying the code..',

  'tab.giftEasyGallery': 'Gift Easy Gallery',
  'tab.upload': 'Upload',
  'tab.yourLibrary': 'Your Library',

  'text.action': 'Action',
  'text.active': 'Active',
  'text.Onboarding': 'On Boarding',
  'text.activity': 'Activity',
  'text.addCoupon': 'Add Coupon',
  'text.addLocation': 'Add Location',
  'text.addUser': 'Add User',
  'text.errorInfo': 'Payment Error Logs',
  'text.address': 'Address',
  'text.applyLocations': 'Apply to Locations',
  'text.assignLocations': 'Assign Locations',
  'text.alreadyHaveAccount': 'Already have an account?',
  'text.and.spaced': ' and ',
  'text.amount': 'Amount',
  'text.authNumber': 'Auth. Number',
  'text.businessProfile.title': 'Business Profile',
  'text.businessProfile.description':
    'Tell us about your business so we can help you set it up to accept payments.',
  'text.byUser': 'By {name} - {location}',
  'text.card': 'Card',
  'text.cash': 'Cash',
  'text.channel': 'Channel',
  'text.changeEmail': 'Change Email',
  'text.chooseImage': 'Choose Image',
  'text.city': 'City',
  'text.clover': 'Clover',
  'text.cloverPaymentInfo': 'Clover Payment Info',
  'text.code': 'Code',
  'text.confirmEmail.title': 'Confirm Email',
  'text.confirmEmail.description':
    "We've sent a 6-digit verification code to {email}. The code expires in 10m, so please enter it soon.",
  'text.confirmPassword': 'Confirm Password',
  'text.contactDetails': 'Contact Details',
  'text.converge': 'Converge',
  'text.convergePaymentInfo': 'Converge Payment Info',
  'text.copyright': 'Copyright © {copyrighttext}. All rights reserved.',
  'text.copyrightInnerpage': 'Copyright © {copyrighttext}.',
  'text.couponDetails': 'Coupon Details',
  'text.couponList': 'Coupon List',
  'text.createAccount': 'Create your account',
  'text.creditCard': 'Credit Card',
  'text.customerEmail': 'Customer Email',
  'text.customerList': 'Customer List',
  'text.customerName': 'Customer Name',
  'text.customerPhone': 'Customer Phone',
  'text.date': 'Date',
  'text.dateTime': 'Date & Time',
  'text.description': 'Description',
  'text.didntReceiveCode': "Didn't receive the code?",
  'text.digitalCard': 'Digital Card',
  'text.discountApplied': 'Discount Applied',
  'text.dontHaveAccount': "Don't have an account?",
  'text.editCustomer': 'Edit Customer',
  'text.email': 'Email',
  'text.expiry': 'Expiry Date',
  'text.exportPhysicalCards': 'Export Physical Cards',
  'text.giftCardBalance': 'Gift Card Balance',
  'text.giftCardDesign': 'Gift Card Design',
  'text.giftCardDetails': 'Gift Card Details',
  'text.giftCardList': 'Gift Card List',
  'text.storeino.tab.giftcardList': 'Gift Card List',
  'text.giftCardNumber': 'Gift Card Number',
  'text.giftCardReport': 'Gift Card Report',
  'text.giftCardStoreDesign': 'Gift Card Store Design',
  'text.giftIssued': 'Gift Issued',
  'text.giftRedeemed': 'Gift Redeemed',
  'text.giftUnredeemed': 'Gift Unredeemed',
  'text.importExternalCards.title': 'Import External Gift Cards',
  'text.importExternalCards.description':
    'Download the excel template from the link below and upload the same for importing the gift cards from other platforms.',
  'text.inactive': 'Inactive',
  'text.demo': 'Demo',
  'text.issueGiftCard': 'Issue Gift Card',
  'text.lastPurchasedAt': 'Last Purchased At',
  'text.location': 'Location',
  'text.locations': 'Locations',
  'text.locationDetails': 'Location Details',
  'text.locationList': 'Location List',
  'text.logo': 'Logo',
  'text.logout.content': 'Are you sure you want to logout?',
  'text.name': 'Name',
  'text.notConnected': 'Not Connected',
  'text.onlineStore': 'Online Store',
  'text.ownerProfile.title': 'Owner Profile',
  'text.ownerProfile.description':
    'Tell us about the owner of the business to start your application.',
  'text.paidAmount': 'Paid Amount',
  'text.payment.clover.change.title': 'Change Account',
  'text.payment.clover.change.description': 'Connect with a different clover merchant account.',
  // 'text.paymentAccountInfo': 'Payment Account Info',
  'text.paymentDetails': 'Payment Details',
  'text.paymentMethod': 'Payment Method',
  'text.permissions': 'Permissions',
  'text.permissions.location': 'Location Permissions',
  'text.physicalCard': 'Physical Card',
  'text.physicalCardDetails':
    'You have purchased {totalCount} physical gift cards and there are {unusedCount} unused cards. Do you want to download the unused cards?',
  'text.postalCode': 'Zip/Postal Code',
  'text.preferences': 'Preferences',
  'text.preview.digitalCard': 'Digital Card Preview',
  'text.purchaseAmount': 'Purchase Amount',
  'text.purchaseAmount.total': 'Total Purchase Amount',
  'text.recipientDetails': 'Recipient Details',
  'text.recipientName': 'Recipient Name',
  'text.redeemedAmount': 'Redeemed Amount',
  'text.redeemGiftCard': 'Redeem Gift Card',
  'text.register.agreement': 'By signing up, you agree to the',
  'text.reloadGiftCard': 'Reload Gift Card',
  'text.removeCoupon': 'Remove {code}',
  'text.removeCoupon.description': 'Are you sure you want to remove this coupon?',
  'text.removeUser': 'Remove {name}',
  'text.removeUser.description': 'Are you sure you want to remove this user?',
  'text.resendGiftCard': 'Resend Gift Card',
  'text.role': 'Role',
  'text.senderDetails': 'Sender Details',
  'text.serialNo': 'S.No.',
  'text.signIn.title': 'Sign in to your account',
  'text.status': 'Status',
  'text.stepper.businessProfile': 'Business Profile',
  'text.stepper.subscription': 'Subscription',
  'text.storeDetails': 'Store Details',
  'text.summary': 'Summary',
  'text.updateCoupon': 'Update Coupon',
  'text.updateLocation': 'Update Location',
  'text.updateUser': 'Update User',
  'text.user': 'User',
  'text.userDetails': 'User Details',
  'text.userList': 'User List',
  'text.paymentErrorLogs': 'Payment Logs',
  'text.paymentLogs': 'Manage Payment Logs',
  'text.migrationlogs': 'Manage Migration',
  'text.migration': 'Manage Migrations',
  'text.users': 'Users',
  'text.transactionHistory': 'Transaction History',
  'text.transactionType': 'Transaction Type',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.value': 'Value',
  'text.updateStoreID': 'Store ID',

  'title.business': 'Business',
  'title.confirmEmail': 'Confirm Email',
  'title.login': 'Login',
  'title.manage.coupon': 'Manage Coupons',
  'title.manage.customer': 'Manage Customers',
  'title.manage.giftCard': 'Manage Gift Cards',
  'title.manage.location': 'Manage Locations',
  'title.manage.user': 'Manage Users',
  'title.recoverPassword': 'Recover Password',
  'title.resetPassword': 'Reset Password',
  'title.register': 'Register',
  'title.settings.giftCard': 'Gift Card Settings',
  'title.settings.payment': 'Payment Settings',
  'title.settings.store': 'Store Settings',
  'title.summary.redeem': 'Redeemed Summary',
  'title.summary.sales': 'Sales Summary',
  'title.manageInvoices': 'Manage Invoices',
  'title.dashboard': 'Dashboard',
  'text.GiftCardSales': 'Gift Card Sales',
  'text.giftCardReedem': 'Gift Card Redeem',
  'text.Newleads': 'New Leads',
  'text.Signups': 'Signups',
  'text.revenue': 'Revenue',
  'form.label.source': 'Source',
  'form.label.Source': 'Source',
  'form.label.reseller_agent': 'Reseller Agent',
  'form.placeholder.reseller_agent': 'Reseller Agent',
  'form.placeholder.storeId': 'Enter store id...',
  'text.custommessage.emptyGiftCardSales': 'No Data in Gift Card Sales',
  'empty.giftCard': 'No Gift Card list to show',

  'form.label.threeDigitFin': '3 Digit Financial Instituition Number',
  'form.label.fiveDigitFin': '5 Digit Financial Instituition Number',
  'form.label.tweleDigitFin': '5-12 Digit Financial Instituition Number',
  'form.label.recipientName': 'Recipien tName',
  'form.label.customerCode': 'Customer Code',

  'text.changepaymentbambora': 'Change Payment Method to Bambora',
  'text.changepaymentcard': 'Change Payment Method to Card',
  'action.changepaymentmethodtobambora': 'Change Payment Method to Bambora',
  'action.changepaymentmethodtocard': 'Change Payment Method to Card',
  'action.editbambora': 'Edit',

  'form.label.business': 'Business',
  'form.placeholder.business': 'Business',
  'form.placeholder.leadSource': 'Lead Source',
  'form.label.leadSource': 'Lead Source',
  'form.placeholder.salesChannel': 'Sales Channel',
  'form.label.salesChannel': 'Sales Channel',
  'form.placeholder.Source': 'Source',
  'form.placeholder.reselleragent': 'Reseller Agent',

  'form.label.period': 'Period',
  'form.placeholder.period': 'Period',

  'form.label.cardtype': 'Card Type',
  'form.placeholder.cardtype': 'Card Type',

  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.business': 'Business',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  unauthorized_access: 'Unauthorized Access!',
  unauthorized_access_desc: "Sorry, you don't have access to this page.",
  go_back_home: 'Go Back to Home',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
