import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  loadall,
  loadStore,
  updatestore,
  locationlist,
  devicelist,
  loadgiftcardsettings,
  uploadimage,
  update,
  getplan,
  loadinvoice,
  viewinvoice,
  resendinvoice,
  retrypayment,
  refundinvoice,
  viewinvoiceactivity,
  addCard,
  removecard,
  changeDefaultCard,
  changebillingdate,
  addgiftcard,
  resendwelcomeemail,
  addnotes,
  getnotes,
  giftcardlist,
  loadTransactions,
  senddigitalcardemail,
  createbusiness,
} from 'api/storeinfo'
import {
  getBusinessName,
  defaultgetgiftcardsales,
  giftcardsales,
  defaultgetgiftcardredeem,
  giftcardredeem,
  defaultgetnewlead,
  newleads,
  defaultgetrevenue,
  revenue,
  defaultgetagentnewlead,
  agentnewlead,
  defaultgetsignup,
  signup,
  defaultgetagentsignup,
  agentsignup,
  getResellerList,
} from 'api/dashboard'
import { resetPassword } from 'api/user'
import actions from './actions'

export function* GET_BUSINESS_NAME() {
  const { response } = yield call(getBusinessName)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        businessnamelist: response.data.businesses,
      },
    })
  }
}

export function* GET_RESELLERLIST() {
  const { response } = yield call(getResellerList)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        resellernameList: response.data.supportusers,
      },
    })
  }
}

export function* GET_GIFT_CARD_SALES() {
  const { response } = yield call(defaultgetgiftcardsales)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftcardsalesdata: response.data.data,
      },
    })
  }
}

export function* GET_GIFT_CARD_REDEEM() {
  const { response } = yield call(defaultgetgiftcardredeem)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftcardredeemdata: response.data.data,
      },
    })
  }
}

export function* GET_NEW_LEADS() {
  const { response } = yield call(defaultgetnewlead)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        newleadsdata: response.data.data,
      },
    })
  }
}

export function* GET_AGENT_NEW_LEADS() {
  const { response } = yield call(defaultgetagentnewlead)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        agentnewleadsdata: response.data.data,
      },
    })
  }
}

export function* GET_REVENUE() {
  const { response } = yield call(defaultgetrevenue)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        revenuedata: response.data.data,
      },
    })
  }
}

export function* GET_SIGNUP() {
  const { response } = yield call(defaultgetsignup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        signupdata: response.data.data,
      },
    })
  }
}

export function* GET_AGENT_SIGNUP() {
  const { response } = yield call(defaultgetagentsignup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        agentsignupdata: response.data.data,
      },
    })
  }
}

export function* CALLGIFTCARDSALES(payload) {
  const { response } = yield call(giftcardsales, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftcardsalesdata: response.data.data,
      },
    })
  }
}

export function* CREATE_BUSINESS({ payload }) {
  const { ...values } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      storeRegistering: true,
    },
  })
  const { response } = yield call(createbusiness, values)
  yield put({
    type: actions.SET_STATE,
    payload: {
      storeRegistering: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        newlyAdded: response.data,
        displayRestaurantAddDrawer: false,
      },
    })

    yield call(LOAD)
  }
}

export function* LOAD_GIFTCARD_LIST({ payload }) {
  const { response } = yield call(giftcardlist, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        gift_cards: response?.data,
        giftCardListData: response?.data?.gift_cards,
        giftCardIssuedData: response?.data?.gift_issued,
        giftCardRedeemedData: response?.data?.gift_redeemed,
        giftCardUnRedeemedData: response?.data?.gift_unredeemed,
      },
    })
  }
}

export function* LOAD_TRANSACTIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      transactions: null,
    },
  })
  const { response } = yield call(loadTransactions, payload)
  if (response) {
    const { transactions, transaction_types } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        transactions,
        transactionTypes: transaction_types,
      },
    })
  }
}

export function* CALLGIFTCARDREDEEM(payload) {
  const { response } = yield call(giftcardredeem, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftcardredeemdata: response.data.data,
      },
    })
  }
}

export function* CALLNEWLEADS(payload) {
  const { response } = yield call(newleads, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        newleadsdata: response.data.data,
      },
    })
  }
}

export function* CALLREVENUE(payload) {
  const { response } = yield call(revenue, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        revenuedata: response.data.data,
      },
    })
  }
}

export function* CALLAGENTNEWLEADS(payload) {
  const { response } = yield call(agentnewlead, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        agentnewleadsdata: response.data.data,
      },
    })
  }
}

export function* CALL_SIGNUP(payload) {
  const { response } = yield call(signup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        signupdata: response.data.data,
      },
    })
  }
}

export function* CALL_AGENT_SIGNUP(payload) {
  const { response } = yield call(agentsignup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        agentsignupdata: response.data.data,
      },
    })
  }
}

export function* LOAD() {
  const { response } = yield call(loadall)
  if (response) {
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        storelist: businesses,
      },
    })
  }
}

export function* LOAD_STORE(payload) {
  const business_id = payload.payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadStore, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { business, setting } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        businessdata: business,
        locationdata: business?.location,
        settingdata: setting,
        data1: business?.name,
        data2: business?.location?.address,
        data3: business?.location?.city,
        data4: business?.location?.state,
        data5: business?.location?.country,
        data6: business?.location?.postal_code,
        data7: business?.location?.contact?.name,
        data8: business?.user?.email,
        data9: business?.location?.contact?.phone_number,
        data10: business?.location?.contact?.alt_phone_number,
        data11: business?.logo_url,
        data12: setting?.portal?.theme_color,
        data13: setting?.portal?.background_image_url,
        data14: setting?.portal?.preferred_language,
        data15: business?.account_type,
        data16: business?.reseller?.company,
        data17: business?.reseller?.name,
        data18: business?.type,
        data19: business?.location?.timezone,
        data20: business?.signup_source,
        data21: business?.code,
        data22: business?.location?.code,
        data23: business?.is_online_receipt_enabled,
        data24: business?.is_smart_bundle,
        data25: business?.is_api_integrated,
        data26: business?.is_custom_redeem,
      },
    })
  }
}

export function* UPDATE_STORE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(updatestore, UseridSel, updatedData)
  const { business, setting } = response.data
  yield put({
    type: actions.UPDATE_STORE_INFO,
    payload: {
      businessdata: business,
      locationdata: business?.location,
      settingdata: setting,
      data1: business?.name,
      data2: business?.location?.address,
      data3: business?.location?.city,
      data4: business?.location?.state,
      data5: business?.location?.country,
      data6: business?.location?.postal_code,
      data7: business?.location?.contact?.name,
      data8: business?.user?.email,
      data9: business?.location?.contact?.phone_number,
      data10: business?.location?.contact?.alt_phone_number,
      data11: business?.logo_url,
      data12: setting?.portal?.theme_color,
      data13: setting?.portal?.background_image_url,
      data14: setting?.portal?.preferred_language,
      data15: business?.account_type,
      data18: business?.type,
      data19: business?.location?.timezone,
      data23: business?.is_online_receipt_enabled,
    },
  })
}

export function* LOAD_LOCATION_DATA(payload) {
  const business_id = payload.payload
  const { response } = yield call(locationlist, business_id)
  if (response) {
    const { locations } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        locationlist: locations,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response } = yield call(resetPassword, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        resetting: false,
        displayResetPasswordModal: false,
      },
    })
  }
}

export function* RESEND_INVOICE(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(resendinvoice, invoiceIdVal)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ResendInvoiceModal: false,
        loading: false,
      },
    })
    const path = window.location.pathname
    const business_id = path.replace('/manage/store-info/', '')
    yield call(LOAD_INVOICES, { payload: business_id })
  }
}

export function* LOAD_INVOICES(payload) {
  const business_id = payload.payload
  const { response } = yield call(loadinvoice, business_id)
  if (response) {
    const { invoices } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoiceinfo: invoices,
      },
    })
  }
}

export function* RETRY_PAYMENT(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(retrypayment, invoiceIdVal)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        RetryPaymentModal: false,
        loading: false,
      },
    })
    const path = window.location.pathname
    const business_id = path.replace('/manage/store-info/', '')
    yield call(LOAD_INVOICES, { payload: business_id })
  }
}

export function* REFUND_INVOICE(payload) {
  const invoiceIdVal = payload.payload.invoiceId
  const amountVal = payload.payload.amount
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(refundinvoice, invoiceIdVal, amountVal)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        RefundInvoiceModal: false,
        loading: false,
      },
    })
    const path = window.location.pathname
    const business_id = path.replace('/manage/store-info/', '')
    yield call(LOAD_INVOICES, { payload: business_id })
  }
}

export function* VIEW_INVOICES(payload) {
  const invoiceid = payload.payload.invoice_id
  const { response } = yield call(viewinvoice, invoiceid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        viewinvoiceinfo: response.data.invoice,
        displayInvoiceDrawer: true,
      },
    })
  }
}

export function* LOAD_INVOICE_ACTIVITY(payload) {
  const invoiceid = payload?.payload?.invoice_id
  const invoiceNumberData = payload?.payload?.invoiceNumber
  const { response } = yield call(viewinvoiceactivity, invoiceid)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        viewinvoiceactivity: response.data.invoice?.activity,
        InvoiceActivity: true,
        selectedinvoiceNumber: invoiceNumberData,
      },
    })
  }
}

export function* LOAD_DEVICE_DATA(payload) {
  const business_id = payload.payload
  const { response } = yield call(devicelist, business_id)
  if (response) {
    const { devices } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        deviceslist: devices,
      },
    })
  }
}

export function* LOAD_GIFT_CARD_SETTINGS(payload) {
  const business_id = payload.payload
  const { response } = yield call(loadgiftcardsettings, business_id)
  if (response) {
    const {
      setting,
      text_colors,
      gift_card_images,
      physical_gift_card,
      import_template_url,
    } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftcardata: setting,
        textColors: text_colors,
        defaultImages: gift_card_images,
        physicalGiftCard: physical_gift_card,
        importTemplateUrl: import_template_url,
      },
    })
  }
}

export function* UPLOAD_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
    },
  })
  const path = window.location.pathname
  const business_id = path.replace('/manage/store-info/', '')
  const { response } = yield call(uploadimage, business_id, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.UPDATE_IMAGE,
      payload: {
        image: response.data.image,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const business_id = payload.userId
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  yield call(update, business_id, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* CHANGE_NEXT_BILLING_DATE({ payload }) {
  const businessid = payload.business
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingBillingDate: true,
    },
  })
  const { response } = yield call(changebillingdate, businessid, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingBillingDate: false,
      displayChangeNextBillingDateModal: false,
    },
  })
  if (response) {
    const path = window.location.pathname
    const business_id = path.replace('/manage/store-info/', '')
    yield call(LOAD_PLAN_INVENTORY, { payload: business_id })
  }
}

export function* ADD_GIFT_CARD_COUNT({ payload }) {
  const businessid = payload.business
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingGiftCard: true,
    },
  })
  const { response } = yield call(addgiftcard, businessid, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingGiftCard: false,
      displayAddGiftCardModal: false,
    },
  })
  if (response) {
    const path = window.location.pathname
    const business_id = path.replace('/manage/store-info/', '')
    yield call(LOAD_PLAN_INVENTORY, { payload: business_id })
  }
}

export function* LOAD_PLAN_INVENTORY(payload) {
  const business_id = payload.payload
  const { response } = yield call(getplan, business_id)
  if (response) {
    const { subscription, inventory } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        planinventorydata: inventory,
        subscriptiondata: subscription,
      },
    })
  }
}

export function* ADD_CARD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: true,
    },
  })
  const path = window.location.pathname
  const business_id = path.replace('/manage/store-info/', '')
  const { response } = yield call(addCard, payload, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddCardModal: false,
        displayChangeCardDrawer: false,
        displayChangeAddCardModal: false,
        displayEditBambora: false,
        displayAddBamboraModal: false,
      },
    })

    yield call(LOAD_PLAN_INVENTORY, { payload: business_id })
  }
}

export function* REMOVE_CARD({ payload: { id } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingCard: true,
    },
  })
  const path = window.location.pathname
  const business_id = path.replace('/manage/store-info/', '')
  const { response } = yield call(removecard, business_id, id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingCard: false,
    },
  })
  if (response) {
    yield call(LOAD_PLAN_INVENTORY, { payload: business_id })
  }
}

export function* CHANGE_DEFAULT_CARD({ payload: { id } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingDefaultCard: true,
    },
  })
  const path = window.location.pathname
  const business_id = path.replace('/manage/store-info/', '')
  const { response } = yield call(changeDefaultCard, business_id, id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingDefaultCard: false,
    },
  })
  if (response) {
    yield call(LOAD_PLAN_INVENTORY, { payload: business_id })
  }
}

export function* RESENDEMAIL({ payload }) {
  const business_id = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: true,
    },
  })
  yield call(resendwelcomeemail, business_id, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: false,
    },
  })
}

export function* GETNOTES(payload) {
  const userId = payload.payload
  const { response } = yield call(getnotes, userId)
  if (response) {
    const { notes } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        notesinfo: notes,
      },
    })
  }
}

export function* SAVENOTE({ payload }) {
  const { userId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(addnotes, userId, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
      },
    })
    yield call(GETNOTES, { payload: userId })
  }
}

export function* SEND_EMAIL({ payload }) {
  const business_id = payload?.businessId
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: true,
    },
  })
  yield call(senddigitalcardemail, business_id, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: false,
      displaySendDigitalCard: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEND_EMAIL, SEND_EMAIL),
    takeLatest(actions.RESENDEMAIL, RESENDEMAIL),
    takeLatest(actions.SAVENOTE, SAVENOTE),
    takeLatest(actions.GETNOTES, GETNOTES),
    takeLatest(actions.LOAD, LOAD),
    takeLatest(actions.LOAD_STORE, LOAD_STORE),
    takeLatest(actions.UPDATE_STORE, UPDATE_STORE),
    takeLatest(actions.LOAD_LOCATION_DATA, LOAD_LOCATION_DATA),
    takeLatest(actions.LOAD_DEVICE_DATA, LOAD_DEVICE_DATA),
    takeLatest(actions.LOAD_GIFT_CARD_SETTINGS, LOAD_GIFT_CARD_SETTINGS),
    takeLatest(actions.UPLOAD_IMAGE, UPLOAD_IMAGE),
    takeLatest(actions.UPDATE, UPDATE),
    takeLatest(actions.LOAD_PLAN_INVENTORY, LOAD_PLAN_INVENTORY),
    takeLatest(actions.LOAD_INVOICES, LOAD_INVOICES),
    takeLatest(actions.VIEW_INVOICES, VIEW_INVOICES),
    takeLatest(actions.RESEND_INVOICE, RESEND_INVOICE),
    takeLatest(actions.RETRY_PAYMENT, RETRY_PAYMENT),
    takeLatest(actions.LOAD_INVOICE_ACTIVITY, LOAD_INVOICE_ACTIVITY),
    takeLatest(actions.REFUND_INVOICE, REFUND_INVOICE),
    takeEvery(actions.ADD_CARD, ADD_CARD),
    takeEvery(actions.REMOVE_CARD, REMOVE_CARD),
    takeEvery(actions.CHANGE_DEFAULT_CARD, CHANGE_DEFAULT_CARD),
    takeEvery(actions.GET_BUSINESS_NAME, GET_BUSINESS_NAME),
    takeEvery(actions.GET_RESELLERLIST, GET_RESELLERLIST),
    takeEvery(actions.GET_GIFT_CARD_SALES, GET_GIFT_CARD_SALES),
    takeEvery(actions.GET_GIFT_CARD_REDEEM, GET_GIFT_CARD_REDEEM),
    takeEvery(actions.CALLGIFTCARDSALES, CALLGIFTCARDSALES),
    takeEvery(actions.CALLGIFTCARDREDEEM, CALLGIFTCARDREDEEM),
    takeEvery(actions.CALLNEWLEADS, CALLNEWLEADS),
    takeEvery(actions.GET_NEW_LEADS, GET_NEW_LEADS),
    takeEvery(actions.GET_REVENUE, GET_REVENUE),
    takeEvery(actions.CALLREVENUE, CALLREVENUE),
    takeEvery(actions.GET_AGENT_NEW_LEADS, GET_AGENT_NEW_LEADS),
    takeEvery(actions.CALLAGENTNEWLEADS, CALLAGENTNEWLEADS),
    takeEvery(actions.GET_SIGNUP, GET_SIGNUP),
    takeEvery(actions.CALL_SIGNUP, CALL_SIGNUP),
    takeEvery(actions.GET_AGENT_SIGNUP, GET_AGENT_SIGNUP),
    takeEvery(actions.CALL_AGENT_SIGNUP, CALL_AGENT_SIGNUP),
    takeEvery(actions.CHANGE_NEXT_BILLING_DATE, CHANGE_NEXT_BILLING_DATE),
    takeEvery(actions.ADD_GIFT_CARD_COUNT, ADD_GIFT_CARD_COUNT),
    takeEvery(actions.LOAD_GIFTCARD_LIST, LOAD_GIFTCARD_LIST),
    takeEvery(actions.LOAD_TRANSACTIONS, LOAD_TRANSACTIONS),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.CREATE_BUSINESS, CREATE_BUSINESS),
  ])
}
