import React, { useState, Suspense, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { Form, Input, Table, Tag, Spin, Drawer } from 'antd'
import StoreinfoAction from 'redux/storeinfo/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectInvoicedata,
  selectStoreInfoReducer,
  selectUserInfo,
} from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import { getFormattedDate } from 'utils/parser'
import ResendInvoiceModal from 'components/app/common/ResendInvoiceModal'
import InvoiceActivity from 'components/app/common/InvoiceActivity'
import RetryPaymentModal from 'components/app/common/RetryPaymentModal'
import RefundInvoiceModal from 'components/app/common/RefundInvoiceModal'
import Invoice from './Invoice'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    filterinvoicedata: selectInvoicedata,
    settings: selectSettings,
    storeInfo: selectStoreInfoReducer,
    userInfo: selectUserInfo,
  })

const PaymentHistory = ({ dispatch, filterinvoicedata, storeInfo, userInfo }) => {
  const { formatMessage, formatNumber } = useIntl()
  const [page, setPage] = useState(1)
  const settings = useSelector(selectSettings)
  const invoice = useSelector(selectStoreInfoReducer)

  const path = window.location.pathname

  const userId = path.replace('/manage/store-info/', '')

  const userloginedPermission = userInfo?.data?.supportuser?.permission

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreinfoAction.LOAD_INVOICES,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.invoiceNo' }),
      dataIndex: 'formatted_invoice_number',
      key: 'invoice_number',
      render: (text, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
          role="button"
          className="text-primary font-weight-bold"
          onClick={() => showInvoice(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: formatMessage({ id: 'text.invoiceAmount' }),
      dataIndex: 'total_amount',
      key: 'invoice_amount',
      render: text => (
        <strong>
          {formatNumber(text / 100, {
            style: 'currency',
            currency: 'CAD',
          })}
        </strong>
      ),
    },
    {
      title: formatMessage({ id: 'text.invoiceDate' }),
      dataIndex: 'issued_at',
      key: 'issued_at',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.paymentStatus' }),
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: text => (
        <strong>
          {text === 'paid' && <Tag color="green">Paid</Tag>}
          {text === 'refund' && <Tag color="blue">Refund</Tag>}
          {text === 'not-paid' && <Tag color="red">Not Paid</Tag>}
        </strong>
      ),
    },
    {
      title: formatMessage({ id: 'text.paymentMethod' }),
      dataIndex: 'payment_details',
      key: 'payment_details',
      render: text => (
        <strong>
          {text?.payment_method === 'converge' ? (
            <div>
              {text.card_details.type} xxxx-{text.card_details.last4}
            </div>
          ) : (
            ''
          )}
          {text?.payment_method === 'bambora' ? <div>Bambora Direct Debit</div> : ''}
          {!text?.payment_method && (
            <>
              <span>-</span>
            </>
          )}
        </strong>
      ),
    },
    {
      title: formatMessage({ id: 'text.paymentDate' }),
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: text => (
        <>
          {text && <>{getFormattedDate(text)}</>}
          {!text && (
            <>
              <span>-</span>
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <img
            className="img-fluid mr-2"
            src="resources/images/invoice/resend.png"
            alt={formatMessage({ id: 'text.resendInvoice' })}
            title={formatMessage({ id: 'text.resendInvoice' })}
            style={{ maxHeight: 24 }}
            onClick={() => showResendInvoiceModal(record)}
            hidden={
              userloginedPermission?.read_payment_history === false ||
              userloginedPermission?.write_payment_history === false
            }
            aria-hidden="true"
          />

          <img
            className="img-fluid mr-2"
            src="resources/images/invoice/retry.png"
            alt={formatMessage({ id: 'text.retryPayment' })}
            title={formatMessage({ id: 'text.retryPayment' })}
            style={{ maxHeight: 24 }}
            onClick={() => showRetryPaymentModal(record)}
            hidden={
              record?.payment_status === 'paid' ||
              record?.payment_status === 'refund' ||
              userloginedPermission?.read_payment_history === false ||
              userloginedPermission?.write_payment_history === false
            }
            aria-hidden="true"
          />
          <img
            className="img-fluid mr-2"
            src="resources/images/invoice/refund.png"
            alt={formatMessage({ id: 'text.refundInvoice' })}
            title={formatMessage({ id: 'text.refundInvoice' })}
            style={{ maxHeight: 24 }}
            hidden={
              record?.payment_status !== 'paid' ||
              record?.is_refund === true ||
              userloginedPermission?.read_payment_history === false ||
              userloginedPermission?.write_payment_history === false
            }
            onClick={() => showRefundInvoiceModal(record)}
            aria-hidden="true"
          />
          <img
            className="img-fluid"
            src="resources/images/invoice/log.png"
            alt={formatMessage({ id: 'text.activityLog' })}
            title={formatMessage({ id: 'text.activityLog' })}
            style={{ maxHeight: 24 }}
            onClick={() => ShowInvoiceActivity(record)}
            hidden={
              userloginedPermission?.read_payment_history === false ||
              userloginedPermission?.write_payment_history === false
            }
            aria-hidden="true"
          />
        </>
      ),
    },
  ]

  const onSearch = value => {
    dispatch({
      type: StoreinfoAction.SET_STATE,
      payload: { search: value },
    })
  }

  const showInvoice = data => {
    dispatch({
      type: StoreinfoAction.VIEW_INVOICES,
      payload: {
        invoice_id: data?._id,
      },
    })
  }

  const hideInvoice = () => {
    dispatch({
      type: StoreinfoAction.SET_STATE,
      payload: {
        displayInvoiceDrawer: false,
      },
    })
  }

  const resendinvoice = () => {
    const invoiceidinfo = storeInfo?.selectedData?.invoiceId
    dispatch({
      type: StoreinfoAction.RESEND_INVOICE,
      payload: {
        invoiceId: invoiceidinfo,
      },
    })
  }

  const retryPayment = () => {
    const invoiceidinfo = storeInfo?.selectedData?.invoiceId
    dispatch({
      type: StoreinfoAction.RETRY_PAYMENT,
      payload: {
        invoiceId: invoiceidinfo,
      },
    })
  }

  const hideResendInvoiceModal = () => {
    dispatch({
      type: StoreinfoAction.HIDE_RESEND_INVOICE_MODAL,
    })
  }

  const showRetryPaymentModal = data => {
    dispatch({
      type: StoreinfoAction.SHOW_RETRY_PAYMENT_MODAL,
      payload: {
        invoiceNumber: data?.formatted_invoice_number,
        invoiceId: data?._id,
      },
    })
  }

  const hideRetryPaymentModal = () => {
    dispatch({
      type: StoreinfoAction.HIDE_RETRY_PAYMENT_MODAL,
    })
  }

  const showResendInvoiceModal = data => {
    dispatch({
      type: StoreinfoAction.SHOW_RESEND_INVOICE_MODAL,
      payload: {
        invoiceNumber: data?.formatted_invoice_number,
        invoiceId: data?._id,
      },
    })
  }

  const showRefundInvoiceModal = data => {
    dispatch({
      type: StoreinfoAction.SHOW_REFUND_INVOICE_MODAL,
      payload: {
        invoiceNumber: data?.formatted_invoice_number,
        InvoiceAmount: data?.total_amount,
        invoiceId: data?._id,
      },
    })
  }

  const ShowInvoiceActivity = data => {
    dispatch({
      type: StoreinfoAction.LOAD_INVOICE_ACTIVITY,
      payload: {
        invoice_id: data?._id,
        invoiceNumber: data?.formatted_invoice_number,
      },
    })
  }

  const hideInvoiceActivity = () => {
    dispatch({
      type: StoreinfoAction.HIDE_INVOICE_ACTIVITY_MODAL,
    })
  }

  const hideRefundInvoiceModal = () => {
    dispatch({
      type: StoreinfoAction.HIDE_REFUND_INVOICE_MODAL,
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.invoicelist' }),
  }

  const InvoiceActivityTitle = formatMessage({ id: 'text.InvoiceActivity' })
  const invoiceActivityDrawertitle = `${InvoiceActivityTitle} -
    ${storeInfo?.selectedinvoiceNumber}`

  return (
    <div style={{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.placeholder.search.invoice' })}
              size="large"
              onSearch={onSearch}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {filterinvoicedata?.length >= 0 ? (
            <Table
              dataSource={filterinvoicedata}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      <Drawer
        title={formatMessage({ id: 'text.invoice' })}
        placement="right"
        destroyOnClose
        visible={invoice.displayInvoiceDrawer}
        onClose={hideInvoice}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <Invoice />
      </Drawer>
      <ResendInvoiceModal
        visible={storeInfo.ResendInvoiceModal}
        title={storeInfo?.selectedData?.invoiceNumber}
        description={formatMessage({ id: 'text.resendInvoice.description' })}
        onCancel={hideResendInvoiceModal}
        onSubmit={resendinvoice}
        loading={storeInfo.loading}
      />
      <Drawer
        title={invoiceActivityDrawertitle}
        placement="right"
        destroyOnClose
        visible={storeInfo.InvoiceActivity}
        onClose={hideInvoiceActivity}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <InvoiceActivity
          visible={storeInfo.InvoiceActivity}
          title={storeInfo?.selectedData?.invoiceNumber}
          activitylogarray={storeInfo?.viewinvoiceactivity}
        />
      </Drawer>
      <RetryPaymentModal
        visible={storeInfo.RetryPaymentModal}
        title={storeInfo?.selectedData?.invoiceNumber}
        description={formatMessage({ id: 'text.RetryPayment.description' })}
        onCancel={hideRetryPaymentModal}
        onSubmit={retryPayment}
        loading={storeInfo.loading}
      />
      <RefundInvoiceModal
        visible={storeInfo.RefundInvoiceModal}
        title={storeInfo?.selectedData?.invoiceNumber}
        onCancel={hideRefundInvoiceModal}
        loading={storeInfo.loading}
      />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(PaymentHistory))
