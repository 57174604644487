import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Table } from 'antd'
import { Badge } from 'reactstrap'
import Loading from 'components/app/common/Loading'
import { getFormattedDate } from 'utils/parser'

const mapStateToProps = ({ storeinfo, user }) => ({
  storeinfo,
  user,
})

const Invoice = ({ storeinfo }) => {
  const { formatMessage, formatNumber } = useIntl()

  const invoice = storeinfo?.viewinvoiceinfo

  if (!invoice) return <Loading />

  const columns = [
    {
      title: formatMessage({ id: 'text.description' }),
      key: 'name',
      render: text => (
        <div>
          <div className="font-weight-bold">{text.name}</div>
          <div className="font-size-14">{text.description}</div>
        </div>
      ),
    },
    {
      title: formatMessage({ id: 'text.quantity' }),
      dataIndex: 'quantity',
      key: 'quantity',
      render: text => <strong>{text}</strong>,
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      key: 'amount',
      render: text => (
        <strong>
          {formatNumber((text.cost * text.quantity) / 100, {
            style: 'currency',
            currency: 'CAD',
          })}
        </strong>
      ),
    },
  ]

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="mb-2">
            <img src="resources/images/logo.svg" alt="Gift Easy" height="48" />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="font-size-14">
                {invoice.from.address}, <br />
                {invoice.from.city}, {invoice.from.state} {invoice.from.postal_code} <br />
                {invoice.from.country}
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex justify-content-end align-items-center font-weight-bold">
                <span className="font-size-36">{formatMessage({ id: 'text.invoice' })}</span>
                <span className="font-size-28 ml-2">
                  {invoice.payment_status === 'paid' && (
                    <Badge color="success">{formatMessage({ id: 'text.paid' })}</Badge>
                  )}
                  {invoice.payment_status === 'not-paid' && (
                    <Badge color="danger">{formatMessage({ id: 'text.notpaid' })}</Badge>
                  )}
                  {invoice.payment_status === 'refund' && (
                    <Badge color="warning">{formatMessage({ id: 'text.refund' })}</Badge>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="font-size-16 font-weight-bold mt-3">
                {formatMessage({ id: 'text.billTo' })}:
              </div>
              <div className="font-size-14">
                {invoice.to.name},<br />
                {invoice.to.address},<br />
                {invoice.to.city}, {invoice.to.state} {invoice.to.postal_code} <br />
                {invoice.to.country}
              </div>
            </div>
            <div className="col-6">
              <div className="row mt-3">
                <div className="col-5">
                  <div className="font-size-16 font-weight-bold">
                    {formatMessage({ id: 'text.invoice' })} #
                  </div>
                </div>
                <div className="col-7">
                  <div className="font-size-16">{invoice.formatted_invoice_number}</div>
                </div>
                <div className="col-5">
                  <div className="font-size-16 font-weight-bold">
                    {formatMessage({ id: 'text.invoiceDate' })}
                  </div>
                </div>
                <div className="col-7">
                  <div className="font-size-16">{getFormattedDate(invoice.issued_at)}</div>
                </div>
                {invoice.payment_status !== 'paid' && (
                  <>
                    <div className="col-5">
                      <div className="font-size-16 font-weight-bold">
                        {formatMessage({ id: 'text.dueDate' })}
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="font-size-16">{getFormattedDate(invoice.payment_due_at)}</div>
                    </div>
                  </>
                )}
                {invoice.payment_status === 'paid' && (
                  <>
                    <div className="col-5">
                      <div className="font-size-16 font-weight-bold">
                        {formatMessage({ id: 'text.paidDate' })}
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="font-size-16">{getFormattedDate(invoice.paid_at)}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="kit__utils__table mt-3">
            <Table columns={columns} dataSource={invoice.items} rowKey="_id" pagination={false} />
          </div>
          <div className="font-size-16 p-3 text-right">
            <div>
              {formatMessage({ id: 'text.subtotal' })}:
              <span className="ml-2">
                {formatNumber(invoice.subtotal / 100, {
                  style: 'currency',
                  currency: 'CAD',
                })}
              </span>
            </div>
            <div className="m-2" />
            {invoice.taxes.map(tax => (
              <>
                {tax.name} ({tax.percent}%):
                <span className="ml-2">
                  {formatNumber(tax.total / 100, {
                    style: 'currency',
                    currency: 'CAD',
                  })}
                </span>
                <div className="m-2" />
              </>
            ))}
            <div className="font-size-21">
              {formatMessage({ id: 'text.totalDue' })}:
              <span className="font-weight-bold ml-2">
                {formatNumber(invoice.total_amount / 100, {
                  style: 'currency',
                  currency: 'CAD',
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(Invoice)
