import React from 'react'
import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'

export const parseType = (type, types) => {
  return types[type]
}

export const phoneNumberSignificant = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('significant')
  }
  return null
}

export const phoneNumberNational = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('national')
  }
  return null
}

export const phoneNumberE164 = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('e164')
  }
  return null
}

export const getFormattedDate = dateTime => {
  return moment(dateTime).format('MMM D, YYYY')
}

export const getFormattedDateTime = (dateTime, shouldFormat) => {
  if (shouldFormat) {
    return moment(dateTime).format('MMM D, YYYY hh:mm a')
  }
  return moment(dateTime).fromNow()
}

export const positiveNegative = data => {
  if (data > 0) {
    const res = `${data}`
    return (
      <>
        <span style={{ color: 'green', fontWeight: '700' }}>{`+${res}`}</span>
      </>
    )
  }
  return (
    <>
      <span style={{ color: 'red', fontWeight: '700' }}>{data}</span>
    </>
  )
}
