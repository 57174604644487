import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import { login, reset, recover } from 'api/user'
import store from 'store'
import actions from './actions'
import menuactions from '../menu/actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOGIN_USER, response)
  }
}

function* LOGIN_USER(response) {
  const { access_token: accessToken, user } = response.data.data
  store.set(`user.accessToken`, accessToken)
  store.set(`user.data`, user)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      data: user,
    },
  })
  yield put({
    type: menuactions.SET_DATA,
  })
  yield history.push('/')
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, email, name, avatar, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: null,
      user: null,
      businesses: [],
      business: null,
      businessLocation: null,
      loading: false,
      submitting: false,
      errors: null,
    },
  })
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recover, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    yield history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(reset, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

function* HANDLE_USER_TOKEN(access_token) {
  store.set(`user.accessToken`, access_token)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: access_token,
      email: null,
      canResendEmail: false,
      resendEmail: false,
    },
  })
  yield history.push('/login')
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
  ])
}
