import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Upload } from 'antd'

const mapStateToProps = ({ user }) => ({
  user,
})

const ImagePicker = ({ value, onChange, user, intl: { formatMessage } }) => {
  const userloginedPermission = user?.data?.supportuser?.permission

  const props = {
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const reader = new FileReader()
      reader.onload = () => {
        onChange({
          image_url: value?.image_url || value,
          image_data: reader.result,
          image_file: file,
        })
      }
      reader.readAsDataURL(file)
      return false
    },
  }

  return (
    <>
      <div className="mb-2">
        <img
          className="img-fluid"
          src={value?.image_data || value?.image_url || value || 'resources/images/placeholder.png'}
          alt={formatMessage({ id: 'desc.backgroundImage' })}
          style={{ maxHeight: 200 }}
        />
      </div>
      <Upload {...props}>
        <button
          type="button"
          className="btn btn-light mb-2"
          hidden={userloginedPermission?.write_store_info === false}
        >
          <i className={`fe ${value ? 'fe-edit' : 'fe-image'} mr-1`} aria-hidden="true" />
          {formatMessage({ id: value ? 'action.change' : 'action.chooseImage' })}
        </button>
      </Upload>
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(ImagePicker))
