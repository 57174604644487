import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  appName: settings.appName,
})

const Footer = ({ intl: { formatMessage }, appName }) => {
  const copyrighttext = `${new Date().getFullYear()} ${appName}`

  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          {formatMessage({ id: 'text.copyrightInnerpage' }, { copyrighttext })}
          {/* Copyright © 2021 Gift Easy */}
        </p>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(injectIntl(Footer)))
