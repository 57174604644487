import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  create,
  loadAll,
  update,
  remove,
  changestatus,
  changeowner,
  filter,
  addnotes,
  getnotes,
  filterlist,
} from 'api/leads'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { leads, lead_filter, lead_owner_list } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data1: leads,
        data2: lead_filter,
        data3: lead_owner_list,
      },
    })
  }
}

export function* LOAD_FILTER_LIST() {
  const { response } = yield call(filterlist)
  if (response) {
    const { lead_view } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        filterdata: lead_view,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { ...values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, values)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        newlyAdded: response.data.staff,
      },
    })
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE({ payload }) {
  const { lead_id, updatedData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(update, lead_id, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* FILTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(filter, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* CHANGE_STATUS({ payload }) {
  const userId = payload.lead_id
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changestatus, userId, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* CHANGE_OWNER({ payload }) {
  const userId = payload.lead_id

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeowner, userId, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.REMOVE_DATA,
      payload: {
        _id,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* GETNOTES(payload) {
  const userId = payload.payload
  const { response } = yield call(getnotes, userId)
  if (response) {
    const { notes } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        notesinfo: notes,
      },
    })
  }
}

export function* SAVENOTE({ payload }) {
  const { userId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(addnotes, userId, payload)

  if (response) {
    const { note } = response.data.data
    yield put({
      type: actions.ADDNOTES,
      payload: {
        notesinfo: note,
      },
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.CHANGE_STATUS, CHANGE_STATUS),
    takeEvery(actions.CHANGE_OWNER, CHANGE_OWNER),
    takeEvery(actions.FILTER, FILTER),
    takeEvery(actions.GETNOTES, GETNOTES),
    takeEvery(actions.SAVENOTE, SAVENOTE),
    takeEvery(actions.LOAD_FILTER_LIST, LOAD_FILTER_LIST),
  ])
}
