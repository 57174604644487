import React, { useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { SketchPicker } from 'react-color'
import style from './style.module.scss'

const mapStateToProps = ({ user }) => ({
  user,
})

const ColorPicker = ({ value, onChange,user, intl: { formatMessage } }) => {

  const userloginedPermission = user?.data?.supportuser?.permission


  const [showColorPicker, setShowColorPicker] = useState(false)

  return (
    <div>
      <div className="d-flex flex-row">
        <div
          className={`${style.colorBox} rounded-left border`}
          style={{ backgroundColor: value }}
        />
        <button
          type="button"
          className="btn btn-light btn-block ml-1"
          onClick={() => setShowColorPicker(true)}
          hidden={userloginedPermission?.write_store_info === false}
        >
          {formatMessage({ id: 'action.changeColor' })}
        </button>
        <div className={`${style.colorPickerPopup}`} hidden={!showColorPicker}>
          <div
            role="button"
            tabIndex="0"
            className={`${style.colorPickerCover}`}
            onClick={() => setShowColorPicker(false)}
            onKeyDown={() => setShowColorPicker(false)}
          />
          <SketchPicker color={value} onChange={color => onChange(color.hex)} />
        </div>
      </div>
    </div>
  )
}


export default connect(mapStateToProps)(injectIntl(ColorPicker))