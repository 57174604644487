import apiClient from 'services/axios'

const BASE_URL = 'apilog'

export default function loadApilogs(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
