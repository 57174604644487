import React from 'react'
import { connect } from 'react-redux'
import StoreInfoAction from 'redux/storeinfo/actions'
import { injectIntl } from 'react-intl'
import { Button, Form, Input, Checkbox } from 'antd'

const mapStateToProps = ({ dispatch }) => ({
  dispatch,
})

const BamboraPayment = ({ dispatch, payment_method, intl: { formatMessage } }) => {
  const [form] = Form.useForm()

  const onFinish = values => {
    const formValues = { ...values }
    dispatch({
      type: StoreInfoAction.ADD_CARD,
      payload: formValues,
    })
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            initialValues={{ payment_method: 'bambora', is_default: false }}
            onFinish={onFinish}
          >
            <h5 className="mb-4">
              <strong>{formatMessage({ id: 'text.paymentAccountInfo' })}</strong>
            </h5>
            <Form.Item name="payment_method" hidden>
              <Input maxLength={100} />
            </Form.Item>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name={[payment_method, 'three_digit']}
                  label={formatMessage({ id: 'form.label.threeDigitFin' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input size="large" maxLength={3} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name={[payment_method, 'five_digit']}
                  label={formatMessage({ id: 'form.label.fiveDigitFin' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input size="large" maxLength={5} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name={[payment_method, 'five_twelve_digit']}
                  label={formatMessage({ id: 'form.label.tweleDigitFin' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input size="large" maxLength={12} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name={[payment_method, 'recipient_name']}
                  label={formatMessage({ id: 'form.label.recipientName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input size="large" maxLength={100} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name={[payment_method, 'customer_code']}
                  label={formatMessage({ id: 'form.label.customerCode' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input size="large" maxLength={100} />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Item name="is_default" valuePropName="checked" hidden>
                <Checkbox>{formatMessage({ id: 'form.check.makeDefaultPaymentMethod' })}</Checkbox>
              </Form.Item>
            </div>

            {/* <h5 className="mt-2 mb-2">
              <strong>{formatMessage({ id: 'text.status' })}</strong>
            </h5>
            <Form.Item
              name="is_active"
              label={formatMessage({ id: 'form.label.active' })}
              valuePropName="checked"
              requiredMark={false}
            >
              <Switch
                checkedChildren={formatMessage({ id: 'action.yes' })}
                unCheckedChildren={formatMessage({ id: 'action.no' })}
              />
            </Form.Item> */}

            <Form.Item className="text-right mt-3">
              <Button type="primary" htmlType="submit" size="large">
                {formatMessage({ id: 'action.save' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(BamboraPayment))
