import apiClient from 'services/axios'

const BASE_URL1 = 'hardwareorder'
const BASE_URL2 = 'business/name'
const BASE_URL3 = 'business'
const BASE_URL4 = 'subscription'
const BASE_URL5 = 'country'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL1}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function load_taxes() {
  return apiClient
    .get(`${BASE_URL5}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changestatus(orderIdValue, orderStatusValue) {
  return apiClient
    .put(`${BASE_URL1}/${orderIdValue}`, { order_status: orderStatusValue })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changestatustracking(orderIdValue, orderStatusValue, trackingNumberValue) {
  return apiClient
    .put(`${BASE_URL1}/${orderIdValue}`, {
      order_status: orderStatusValue,
      tracking_number: trackingNumberValue,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadbusinesslist() {
  return apiClient
    .get(`${BASE_URL2}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function viewInvoice(payload) {
  return apiClient
    .get(`${BASE_URL1}/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadbusinessinfo(payload) {
  return apiClient
    .get(`${BASE_URL3}/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getcardlist(payload) {
  return apiClient
    .get(`${BASE_URL4}/${payload}/cardlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function createorder(payload) {
  return apiClient
    .post(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
