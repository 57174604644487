const actions = {
  SET_STATE: 'staff/SET_STATE',
  ADD_DATA: 'staff/ADD_DATA',
  EDIT_DATA: 'staff/EDIT_DATA',
  REMOVE_DATA: 'staff/REMOVE_DATA',
  SHOW_CREATE: 'staff/SHOW_CREATE',
  HIDE_CREATE: 'staff/HIDE_CREATE',
  SHOW_UPDATE: 'staff/SHOW_UPDATE',
  HIDE_UPDATE: 'staff/HIDE_UPDATE',
  SHOW_DELETE: 'staff/SHOW_DELETE',
  HIDE_DELETE: 'staff/HIDE_DELETE',
  LOAD_ALL: 'staff/LOAD_ALL',
  GET_ROLE_LIST: 'staff/GET_ROLE_LIST',
  CREATE: 'staff/CREATE',
  UPDATE: 'staff/UPDATE',
  REMOVE: 'staff/REMOVE',
  LOAD_RESELLER: 'staff/LOAD_RESELLER',
}

export default actions
