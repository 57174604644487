const actions = {
  SET_STATE: 'hardware/SET_STATE',
  GET_BUSINESS_iNFO: 'hardware/GET_BUSINESS_iNFO',
  VIEW_PURCHASE_ORDER: 'hardware/VIEW_PURCHASE_ORDER',
  HIDE_PURCHASE_ORDER: 'hardware/HIDE_PURCHASE_ORDER',
  SHOW_CREATE: 'hardware/SHOW_CREATE',
  HIDE_CREATE: 'hardware/HIDE_CREATE',
  CHANGE_STATUS: 'hardware/CHANGE_STATUS',
  CHANGE_STATUS_TRACKING: 'hardware/CHANGE_STATUS_TRACKING',
  CREATE_ORDER: 'hardware/CREATE_ORDER',
  LOAD_ALL: 'hardware/LOAD_ALL',
  LOAD_BUSINESS_LIST: 'hardware/LOAD_BUSINESS_LIST',
  ADD_CARD: 'hardware/ADD_CARD',
  GET_CARDS: 'hardware/GET_CARDS',
  SHOW_MODAL: 'hardware/SHOW_MODAL',
  HIDE_MODAL: 'hardware/HIDE_MODAL',
  SHOW_TRACKING_MODAL: 'hardware/SHOW_TRACKING_MODAL',
  HIDE_TRACKING_MODAL: 'hardware/HIDE_TRACKING_MODAL',
  LOAD_TAXES: 'hardware/LOAD_TAXES',
}

export default actions
