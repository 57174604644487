const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_ERROR_STATE: 'user/SET_ERROR_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  RECOVER_PASSWORD: 'user/RECOVER_PASSWORD',
  LOGOUT: 'user/LOGOUT',
  CLEAR_USER: 'user/CLEAR_USER',
  REGISTER: 'user/REGISTER',
  REGISTER_BUSINESS: 'user/REGISTER_BUSINESS',
  SET_LOCATION: 'user/SET_LOCATION',
  UPDATE_BUSINESS_STATE: 'user/UPDATE_BUSINESS_STATE',
}

export default actions
