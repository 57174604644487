import apiClient from 'services/axios'

const BASE_URL1 = 'business'
const BASE_URL2 = 'dashboard'
const BASE_URL3 = 'supportuser'

export async function getBusinessName() {
  return apiClient
    .get(`${BASE_URL1}/name`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function getResellerList() {
  return apiClient
    .get(`${BASE_URL3}/reselleragents`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetgiftcardsales() {
  const business_id = ''
  const period = 'month'
  const cardtype = ''
  return apiClient
    .get(
      `${BASE_URL2}/gift-card-sales?period=${period}&business_id=${business_id}&card_type=${cardtype}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetgiftcardredeem() {
  const business_id = ''
  const period = 'month'
  const cardtype = ''
  return apiClient
    .get(
      `${BASE_URL2}/gift-card-redeems?period=${period}&business_id=${business_id}&card_type=${cardtype}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetnewlead() {
  const period = 'month'
  const lead_source = ''
  return apiClient
    .get(`${BASE_URL2}/new-leads?period=${period}&lead_source=${lead_source}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetagentnewlead() {
  const period = 'month'
  return apiClient
    .get(`${BASE_URL2}/new-leads?period=${period}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetrevenue() {
  const period = 'month'
  const source = ''
  return apiClient
    .get(`${BASE_URL2}/revenue?period=${period}&source=${source}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetsignup() {
  const period = 'month'
  const source = ''
  const sales_channel = ''
  return apiClient
    .get(`${BASE_URL2}/sign-ups?period=${period}&source=${source}&sales_channel=${sales_channel}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function defaultgetagentsignup() {
  const period = 'month'
  const source = ''
  const reseller_agent = ''
  return apiClient
    .get(`${BASE_URL2}/sign-ups?period=${period}&source=${source}&reseller_agent=${reseller_agent}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function giftcardsales(payload) {
  const business_id = payload?.payload?.businessId
  const period = payload?.payload?.periodvalue
  const cardtype = payload?.payload?.cardType
  return apiClient
    .get(
      `${BASE_URL2}/gift-card-sales?period=${period}&business_id=${business_id}&card_type=${cardtype}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function giftcardredeem(payload) {
  const business_id = payload?.payload?.businessId
  const period = payload?.payload?.periodvalue
  const cardtype = payload?.payload?.cardType
  return apiClient
    .get(
      `${BASE_URL2}/gift-card-redeems?period=${period}&business_id=${business_id}&card_type=${cardtype}`,
    )
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function newleads(payload) {
  const period = payload?.payload?.periodvalue
  const lead_source = payload?.payload?.leadsourcevalue
  return apiClient
    .get(`${BASE_URL2}/new-leads?period=${period}&lead_source=${lead_source}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function agentnewlead(payload) {
  const period = payload?.payload?.periodvalue
  return apiClient
    .get(`${BASE_URL2}/new-leads?period=${period}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function revenue(payload) {
  const period = payload?.payload?.periodvalue
  const source = payload?.payload?.sourcevalue
  return apiClient
    .get(`${BASE_URL2}/revenue?period=${period}&source=${source}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function signup(payload) {
  const period = payload?.payload?.PeriodValue
  const source = payload?.payload?.sourceValue
  const sales_channel = payload?.payload?.channelValue
  return apiClient
    .get(`${BASE_URL2}/sign-ups?period=${period}&source=${source}&sales_channel=${sales_channel}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function agentsignup(payload) {
  const period = payload?.payload?.PeriodValue
  const source = payload?.payload?.sourceValue
  const reseller_agent = payload?.payload?.reselleragentValue
  return apiClient
    .get(`${BASE_URL2}/sign-ups?period=${period}&source=${source}&reseller_agent=${reseller_agent}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
