const actions = {
  SET_STATE: 'migration/SET_STATE',
  LOAD: 'migration/LOAD',
  GET_IMPORT_BUSINESS: 'migration/GET_IMPORT_BUSINESS',
  SHOW_MIGRATION: 'migration/SHOW_CREATE',
  HIDE_MIGRATION: 'migration/HIDE_MIGRATION',

  LOAD_MIGRATION_RES_LOG: 'migration/LOAD_MIGRATION_RES_DRAWER',
  SHOW_MIGRATION_RES_DRAWER: 'migration/SHOW_MIGRATION_RES_DRAWER',
  HIDE_MIGRATION_RES_DRAWER: 'migration/HIDE_MIGRATION_RES_DRAWER',

  REMOVE: 'migration/REMOVE',
  REMOVE_MIGRATED_BUSINESS: 'migration/REMOVE_MIGRATED_BUSINESS',
  SHOW_MIGRATED_BUSINESS_REMOVE: 'migration/SHOW_MIGRATED_BUSINESS_REMOVE',
  HIDE_MIGRATED_BUSINESS_REMOVE: 'migration/HIDE_MIGRATED_BUSINESS_REMOVE',
}
export default actions
