import React from 'react'
import { connect } from 'react-redux'
import { useIntl, injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { Button, Checkbox, Form } from 'antd'
import CreditCardContainer from 'components/app/common/CreditCardContainer'
import encrypt from '../../../../../../services/crypt.service'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const AddCardModal = ({ dispatch, storeinfo }) => {
  const { formatMessage } = useIntl()

  const onAddCardSubmit = values => {
    const formValues = { ...values }
    const { converge } = formValues
    console.log('credit card values', converge)
    const { expiry } = converge
    const { card_number } = converge
    const { cvv } = converge
    converge.card_number = encrypt(card_number)
    converge.cvv = encrypt(cvv)
    if (expiry) {
      const splitExpiry = expiry.split('/')
      converge.expiry_month = encrypt(splitExpiry[0].trim())
      converge.expiry_year = encrypt(splitExpiry[1].trim())
      delete converge.expiry
    }
    dispatch({
      type: StoreInfoAction.ADD_CARD,
      payload: formValues,
    })
  }

  return (
    <div className="card">
      <div className="card-body">
        <Form layout="vertical" initialValues={{ is_default: true }} onFinish={onAddCardSubmit}>
          <div className="row">
            <div className="col-md-12">
              <Form.Item name="converge" label={formatMessage({ id: 'form.label.cardDetails' })}>
                <CreditCardContainer paymentMode="converge" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item name="is_default" valuePropName="checked">
                <Checkbox>{formatMessage({ id: 'form.check.makeDefaultPaymentMethod' })}</Checkbox>
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item className="text-right">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={storeinfo?.addingCard}
                >
                  {formatMessage({ id: 'action.addCard' })}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(AddCardModal))
