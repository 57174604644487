import actions from './actions'

const initialState = {
  data: null,

  showErrorLogDrawer: false,

  pageLoader: false,
  selectedErrorLog: null,
  updating: false,
}

export default function paymentLogReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_ERROR_RESPONSE:
      return {
        ...state,
        selectedErrorLog: action.payload.paymentlogs,
        updating: true,
        showErrorLogDrawer: true,
      }
    case actions.HIDE_ERROR_RESPONSE:
      return { ...state, updating: false, showErrorLogDrawer: false }

    default:
      return state
  }
}
