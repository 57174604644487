import React from 'react'
import { injectIntl, useIntl } from 'react-intl'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Loading from 'components/app/common/Loading'
import style from './style.module.scss'

const InvoiceActivity = ({ activitylogarray }) => {
  const { formatMessage } = useIntl()
  const length = activitylogarray?.length || 0

  if (!activitylogarray) return <Loading />

  return (
    <>
      <div>
        <div className="card" hidden={length === 0}>
          <div className="card-body">
            <div className="notes-section height-600 d-flex flex-column justify-content-center">
              <PerfectScrollbar>
                <div className={style.contentWrapper}>
                  {activitylogarray?.map(item => (
                    <div key={item._id}>
                      <div className="text-gray-4 font-size-14 text-center text-capitalize">
                        {moment.tz(item?.date_time, 'America/Toronto').format(' MMM D, YYYY')}
                      </div>

                      <div
                        className={`${style.message} ${style.answer} ${style.answermarginbottom}`}
                      >
                        <div className={style.messageContent}>
                          <div className="text-gray-4 text-capitalize" style={{ fontSize: '13px' }}>
                            <Tooltip
                              title={moment
                                .tz(item?.date_time, 'America/Toronto')
                                .format('MMM D [@] hh:mm A')}
                              placement="topLeft"
                            >
                              &nbsp;{' '}
                              <span title="">
                                {moment
                                  .tz(item?.date_time, 'America/Toronto')
                                  .format('MMM D [@] hh:mm A')}
                              </span>
                            </Tooltip>
                          </div>
                          <div>{item?.content}</div>
                        </div>

                        <div className={`${style.messageAvatar} kit__utils__avatar`}>
                          <img src="resources/images/avatars/avatar-2.png" alt="You" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
      <div className="card" hidden={length !== 0}>
        <div className="card-body">
          <div className="height-600 d-flex flex-column justify-content-end">
            <div className="row" style={{ marginBottom: '20px' }}>
              <div className="col-md-12">
                <div className="d-flex flex-wrap m-1">
                  <div className="flex-grow-1">
                    <div
                      className="text-center font-weight-normal font-size-15 text-gray-5"
                      style={{ padding: '0 0 200px 0' }}
                    >
                      {formatMessage({ id: 'text.noactivitytoshow' })}
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(InvoiceActivity)
