import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu } from 'antd'
import { phoneNumberNational } from 'utils/parser'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count] = useState(0)

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const userRole = user?.data?.supportuser?.role

  const GiftEasy = 'Gift Easy'

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.data?.first_name}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {userRole === 'support admin' && <span>Support Admin</span>}
          {userRole === 'support agent' && <span>Support Agent</span>}
          {userRole === 'reseller admin' && <span>Reseller Admin</span>}
          {userRole === 'reseller agent' && <span>Reseller Agent</span>}
          {userRole === 'sales admin' && <span>Sales Admin</span>}
          {userRole === 'sales agent' && <span>Sales agent</span>}
        </div>

        {userRole === 'support admin' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {GiftEasy}
          </div>
        )}

        {userRole === 'support agent' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {GiftEasy}
          </div>
        )}

        {userRole === 'sales admin' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {GiftEasy}
          </div>
        )}

        {userRole === 'sales agent' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {GiftEasy}
          </div>
        )}

        {userRole === 'reseller admin' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {user?.data?.supportuser?.company || '—'}
          </div>
        )}

        {userRole === 'reseller agent' && (
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.business" />:{' '}
            </strong>
            {user?.data?.supportuser?.company || '—'}
          </div>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.data?.email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {phoneNumberNational(user.data?.phone_number) || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
