const actions = {
  SET_STATE: 'leads/SET_STATE',
  ADD_DATA: 'leads/ADD_DATA',
  EDIT_DATA: 'leads/EDIT_DATA',
  REMOVE_DATA: 'leads/REMOVE_DATA',
  SHOW_CREATE: 'leads/SHOW_CREATE',
  HIDE_CREATE: 'leads/HIDE_CREATE',
  SHOW_UPDATE: 'leads/SHOW_UPDATE',
  HIDE_UPDATE: 'leads/HIDE_UPDATE',
  SHOW_DELETE: 'leads/SHOW_DELETE',
  HIDE_DELETE: 'leads/HIDE_DELETE',
  SHOW_DEMO: 'leads/SHOW_DEMO',
  HIDE_DEMO: 'leads/HIDE_DEMO',
  SHOW_NOTES: 'leads/SHOW_NOTES',
  HIDE_NOTES: 'leads/HIDE_NOTES',
  ADDNOTES: 'leads/ADDNOTES',
  LOAD_ALL: 'leads/LOAD_ALL',
  LOAD_FILTER_LIST: 'leads/LOAD_FILTER_LIST',
  CREATE: 'leads/CREATE',
  UPDATE: 'leads/UPDATE',
  REMOVE: 'leads/REMOVE',
  CHANGE_STATUS: 'leads/CHANGE_STATUS',
  CHANGE_OWNER: 'leads/CHANGE_OWNER',
  FILTER: 'leads/FILTER',
  SAVENOTE: 'leads/SAVENOTE',
  GETNOTES: 'leads/GETNOTES',
}

export default actions
