/* eslint-disable react/style-prop-object,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import CardTitle from '../../../common/CardTitle'
import styles from './style.module.scss'

const mapStateToProps = ({ storeinfo }) => ({
  storeinfo,
})

const GiftCardPreview = ({
  storeinfo,
  textColor,
  backgroundColor,
  icon,
  stripImage,
  intl: { formatMessage },
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <CardTitle title={formatMessage({ id: 'text.preview.digitalCard' })} />
        <div
          className={`${styles.giftCard} shadow mt-4 mb-4`}
          style={{ backgroundColor, color: textColor }}
        >
          <div className={`${styles.header}`}>
            <img
              className={`${styles.headerIcon} img-fluid`}
              src={icon}
              alt={formatMessage({ id: 'desc.icon' })}
            />
            <span className={`${styles.headerText}`}>
              {storeinfo?.data1 || process.env.REACT_APP_NAME}
            </span>
          </div>
          <div className={`${styles.strip}`} style={{ backgroundImage: `url(${stripImage})` }} />
          <div className={`${styles.auxiliaryField}`}>
            <div className={`${styles.secondaryField}`}>
              <label className={`${styles.secondaryFieldTitle}`}>Name</label>
              <span className={`${styles.secondaryFieldContent}`}>Joe Doe</span>
            </div>
            <div className={`${styles.secondaryField}`}>
              <div className={`${styles.secondaryField}`}>
                <label className={`${styles.secondaryFieldTitle}`}>Balance</label>
                <span className={`${styles.secondaryFieldContent}`}>$100.00</span>
              </div>
            </div>
          </div>
          <div className={`${styles.barcodeContainer}`}>
            <div className={`${styles.barcode}`}>
              <img
                className={`${styles.barcodeImage} img-fluid`}
                src="resources/images/giftcard/barcode.png"
                alt={formatMessage({ id: 'desc.barcode' })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(GiftCardPreview))
