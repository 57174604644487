import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  appName: settings.appName,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
})

const AuthLayout = ({
  children,
  appName,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  intl: { formatMessage },
}) => {
  const copyrighttext = `${new Date().getFullYear()} ${appName}`

  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
          })}
          style={{ backgroundImage: `url('resources/images/background.jpg')` }}
        >
          <div className={style.containerInner}>
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img className={style.logo} src="resources/images/logo2.svg" alt={appName} />
                </div>
                {children}
              </div>
            </div>
          </div>
          <div className="mt-auto pb-2 pt-2">
            <div className="text-center p-2 text-white">
              {formatMessage({ id: 'text.copyright' }, { copyrighttext })}
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(injectIntl(AuthLayout)))
