import { createSelector } from 'reselect'

export const selectUser = state => state.user

export const selectUserData = createSelector([selectUser], user => {
  const userInfo = user?.data?.supportuser?.role

  if (!userInfo) return false
  return userInfo === 'support admin' || userInfo === 'sales admin' || userInfo === 'reseller admin'
})
