import crypto from 'crypto'

const publicKey =
  '-----BEGIN PUBLIC KEY-----\n' +
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArcqiHqVR7+45Ols7GixA\n' +
  'jlkZ9En4nyA17ocjQL1J/rtO8cFCM7GOc4xjzhzGU1PS0CG487xB/hfu+qR51F/x\n' +
  'fwII9FOaKmkaeMSww1E++fN9GoI+V2gPHSDaqZLxG+BqrnKl+S0UZzotkpRji5p2\n' +
  'YCWj2N60UFGYQRvjX4oYtYAMn0kKEz5+DanvxLkT2WcV/WuGZrEfes3D7zsGNH69\n' +
  'tAkzp+3f4ZkslonQ1A0W0Z8maWnMidhyhtqipFIvZTW3CVwtXZWW4lvpTy6VuOAc\n' +
  '0CKYcVXP3QuzaFTcfJjWryAS2hSoR4Bg5XzS+hZOLV7M6zC8MoY9IzCU6fc1wfGC\n' +
  'eQIDAQAB\n' +
  '-----END PUBLIC KEY-----'

function encrypt(text) {
  const encryptedData = crypto.publicEncrypt(publicKey, Buffer.from(text))
  return encryptedData.toString('base64')
}

export default encrypt
