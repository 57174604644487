import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Button, Modal, Form } from 'antd'
import NumberInput from 'components/app/common/NumberInput'
// import { selectCurrency } from 'redux/user/selectors'
import StoreinfoAction from 'redux/storeinfo/actions'

const mapStateToProps = ({ dispatch, storeinfo }) => ({
  dispatch,
  storeinfo,
})

const RemoveModal = ({ visible, title, onCancel, loading, dispatch, storeinfo }) => {
  const { formatMessage } = useIntl()
  const currency = 'CAD'

  const formatedtitle = formatMessage({ id: 'text.refundInvoice' })
  const titleData = `${formatedtitle} -
    ${title}`

  const onFinish = values => {
    const invoiceidinfo = storeinfo?.selectedData?.invoiceId
    dispatch({
      type: StoreinfoAction.REFUND_INVOICE,
      payload: {
        invoiceId: invoiceidinfo,
        amount: values,
      },
    })
  }

  const invAmount = storeinfo?.selectedData?.InvoiceAmount
  // const invoiceformat = (invAmount / 100).toFixed(2)
  const invoiceformat = invAmount / 100

  return (
    <Modal title={titleData} visible={visible} onCancel={onCancel} footer={null}>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          amount: invoiceformat,
        }}
      >
        <Form.Item
          name="amount"
          label={formatMessage({ id: 'form.label.amount' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.required' }) },
            () => ({
              validator(rule, value) {
                if (!value || parseFloat(value) > 0) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(formatMessage({ id: 'form.error.amount.min' })))
              },
            }),
          ]}
        >
          <NumberInput
            placeholder={formatMessage({ id: 'form.placeholder.amount' })}
            size="large"
            addonBefore={getSymbolFromCurrency(currency)}
            // readonly
            // disabled
          />
        </Form.Item>

        <div className="text-right">
          <Button size="medium" type="secondary" className="mr-3" secondary onClick={onCancel}>
            {formatMessage({ id: 'action.no' })}
          </Button>
          <Button size="medium" type="primary" htmlType="submit" success loading={loading}>
            {formatMessage({ id: 'action.yes' })}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(RemoveModal))
