import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadAll,
  additem,
  getsingleinventory,
  updateitem,
  updatestock,
  activeList,
} from 'api/inventory'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { inventories } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        inventorylist: inventories,
      },
    })
  }
}

export function* LOAD_ACTIVE_INVENTORY() {
  const { response } = yield call(activeList)
  if (response) {
    const { inventories } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        activeList: inventories,
      },
    })
  }
}

export function* GET_SINGLE_INVENTORY({ payload }) {
  const { response } = yield call(getsingleinventory, payload)
  if (response) {
    const { inventories } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        inventoryData: inventories,
      },
    })
  }
}

export function* ADD_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(additem, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        adding: false,
        displayAddInventory: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_iTEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { inventoryId, updatedData } = payload
  const { response } = yield call(updateitem, inventoryId, updatedData)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        displayEditnventory: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_STOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { inventoryId, updatedData } = payload
  const { response } = yield call(updatestock, inventoryId, updatedData)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        displayquantitydrawer: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.ADD_ITEM, ADD_ITEM),
    takeLatest(actions.GET_SINGLE_INVENTORY, GET_SINGLE_INVENTORY),
    takeLatest(actions.UPDATE_iTEM, UPDATE_iTEM),
    takeLatest(actions.UPDATE_STOCK, UPDATE_STOCK),
    takeLatest(actions.LOAD_ACTIVE_INVENTORY, LOAD_ACTIVE_INVENTORY),
  ])
}
