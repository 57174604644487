import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { selectUserInfo } from 'redux/storeinfo/storelistselectors'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { Tabs } from 'antd'
import PlanInventory from './PlanInventory'
import PaymentHistory from './PaymentHistory'

const { TabPane } = Tabs

const mapStateToProps = () =>
  createStructuredSelector({
    userInfo: selectUserInfo,
  })

const StoreInfo = ({ userInfo }) => {
  const { formatMessage } = useIntl()
  const userloginedPermission = userInfo?.data?.supportuser?.permission

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'billing') {
    tabUrlVal = '1'
  } else if (tabname === 'invoice') {
    tabUrlVal = '2'
  }

  return (
    <Tabs defaultActiveKey={tabUrlVal}>
      {userloginedPermission?.read_subscription === true && (
        <TabPane
          tab={
            <Link to="?tab=billing">
              <span>{formatMessage({ id: 'text.billing.tab.planinventory' })}</span>
            </Link>
          }
          key={1}
        >
          <PlanInventory />
        </TabPane>
      )}
      {userloginedPermission?.read_payment_history === true && (
        <TabPane
          tab={
            <Link to="?tab=invoice">
              <span>{formatMessage({ id: 'text.billing.tab.paymenthistory' })}</span>
            </Link>
          }
          key={2}
        >
          <PaymentHistory />
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
