import React from 'react'
import { injectIntl } from 'react-intl'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

const CreditCardInput = ({ value, onChange }) => {
  const { wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs()

  const handleChange = changedValues => {
    onChange({ ...value, ...changedValues })
  }

  return (
    <div>
      <div style={{ fontSize: '1.1rem' }}>
        <PaymentInputsWrapper {...wrapperProps}>
          <svg {...getCardImageProps({ images })} />
          <input {...getCardNumberProps({ onChange: (e => handleChange({ card_number: e.target.value })) })} />
          <input {...getExpiryDateProps({ onChange: (e => handleChange({ expiry: e.target.value })) })} />
          <input {...getCVCProps({ onChange: (e => handleChange({ cvv: e.target.value })) })} />
        </PaymentInputsWrapper>
      </div>
    </div>
  )
}

export default injectIntl(CreditCardInput)
