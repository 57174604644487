import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs, Tag } from 'antd'
import { Link } from 'react-router-dom'
import StoreInfoAction from 'redux/storeinfo/actions'
import StoreDetail from '../StoreDetail'
import GiftCardSettings from '../GiftCardSettings'
import DeviceInfo from '../DeviceInfo'
import BillingInfo from '../BillingInfo'
import NotesInfo from '../NotesInfo'
import GiftCardList from '../GiftCardList'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, storeinfo, user }) => ({
  dispatch,
  storeinfo,
  user,
})

const StoreInfo = ({ dispatch, storeinfo, user, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const userId = path.replace('/manage/store-info/', '')

  const userloginedPermission = user?.data?.supportuser?.permission

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_STORE,
        payload: userId,
      })
    return () => {
      dispatch({
        type: StoreInfoAction.SET_STATE,
        payload: {
          search: null,
          searchactive: null,
          searchinactive: null,
          searchdemo: null,
        },
      })
    } // eslint-disable-next-line
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)

  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'store-info') {
    tabUrlVal = '1'
  } else if (tabname === 'giftcard-settings') {
    tabUrlVal = '2'
  } else if (tabname === 'giftcard-list') {
    tabUrlVal = '3'
  } else if (tabname === 'device-info') {
    tabUrlVal = '4'
  }
  // else if (tabname === 'locations') {
  //   tabUrlVal = '4'
  // }
  else if (tabname === 'billing' || tabname === 'invoice') {
    tabUrlVal = '5'
  } else if (tabname === 'notes') {
    tabUrlVal = '6'
  }

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.store',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{storeinfo?.data1}</strong>
            &nbsp;
            {storeinfo?.data15 === 'Active' && <Tag color="green">{storeinfo?.data15}</Tag>}
            {storeinfo?.data15 === 'Inactive' && <Tag color="red">{storeinfo?.data15}</Tag>}
            {storeinfo?.data15 === 'Demo' && <Tag color="blue">{storeinfo?.data15}</Tag>}
            {storeinfo?.data15 === 'Onboarding' && <Tag color="orange">{storeinfo?.data15}</Tag>}
          </h5>
        </div>
        {(storeinfo?.data16 || storeinfo?.data17) && (
          <div className="col-md-6">
            <h5 className="mb-4" style={{ float: 'right' }}>
              <strong>
                {storeinfo?.data16}
                {' - '}
                {storeinfo?.data17}
                {'      '}
              </strong>
              {storeinfo?.data16 === 'Gift Easy' && (
                <Tag color="magenta" style={{ textTransform: 'capitalize' }}>
                  {formatMessage({ id: 'text.salesagent' })}
                </Tag>
              )}
              {storeinfo?.data16 !== 'Gift Easy' && (
                <Tag color="blue" style={{ textTransform: 'capitalize' }}>
                  {formatMessage({ id: 'text.reselleragent' })}
                </Tag>
              )}
            </h5>
          </div>
        )}
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=store-info">
              <span>{formatMessage({ id: 'text.storeino.tab.storeinfo' })}</span>
            </Link>
          }
          key={1}
        >
          <StoreDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=giftcard-settings">
              <span>{formatMessage({ id: 'text.storeino.tab.giftcardsettings' })}</span>
            </Link>
          }
          key={2}
        >
          <GiftCardSettings />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=giftcard-list">
              <span>{formatMessage({ id: 'text.storeino.tab.giftcardList' })}</span>
            </Link>
          }
          key={3}
        >
          <GiftCardList />
        </TabPane>
        {userloginedPermission?.read_device_info === true && (
          <TabPane
            tab={
              <Link to="?tab=device-info">
                <span>{formatMessage({ id: 'text.storeino.tab.deviceinfo' })}</span>
              </Link>
            }
            key={4}
          >
            <DeviceInfo />
          </TabPane>
        )}
        {/* <TabPane
          tab={
            <Link to="?tab=locations">
              <span>{formatMessage({ id: 'text.storeino.tab.locations' })}</span>
            </Link>
          }
          key={4}
        >
          <Locations />
        </TabPane> */}
        {userloginedPermission?.read_subscription === true && (
          <TabPane
            tab={
              <Link to="?tab=billing">
                <span>{formatMessage({ id: 'text.storeino.tab.billing' })}</span>
              </Link>
            }
            key={5}
          >
            <BillingInfo />
          </TabPane>
        )}
        {userloginedPermission?.read_subscription === true && (
          <TabPane
            tab={
              <Link to="?tab=notes">
                <span>{formatMessage({ id: 'text.storeino.tab.notes' })}</span>
              </Link>
            }
            key={6}
          >
            <NotesInfo />
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
