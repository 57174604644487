import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
// import StoreInfoAction from 'redux/storeinfo/actions'
import BamboraPayment from '../../../../common/BamboraPayment'
import AddCardModal from '../AddCardModal'

const AddBamboraModal = ({ visible, onCancel }) => {
  const storeinfo = useSelector(state => state.storeinfo)

  return (
    <>
      {storeinfo?.subscriptiondata?.payment_method === 'bambora' ? (
        <Modal width={640} visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
          <AddCardModal />
        </Modal>
      ) : (
        <Modal width={640} visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
          <BamboraPayment payment_method="bambora" />
        </Modal>
      )}
    </>
  )
}

export default AddBamboraModal
