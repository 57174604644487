import React from 'react'
import { injectIntl } from 'react-intl'
import ImageBox from '../ImageBox'

const ImageGallery = ({ images, selectedImage, onSelect, intl: { formatMessage } }) => {
  if (images.length === 0)
    return <div style={{ height: 420 }}>{formatMessage({ id: 'empty.image' })}</div>

  return (
    <div className="text-center">
      <div className="d-flex flex-wrap" style={{ height: 420, overflow: 'auto' }}>
        {images.map(image => (
          <ImageBox
            image={image}
            key={image.image}
            isSelected={selectedImage?.image === image.image}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  )
}

export default injectIntl(ImageGallery)
